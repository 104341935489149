import React, { useContext, useEffect, useMemo, useState } from 'react';
import { MODALS } from '../constants';
import { PlanningContext } from '../context';
import {
    Body1,
    Body2,
    DangerButton,
    GridItemRow,
    ModalActions,
    ModalContent,
    ModalTitle,
    PrimaryButton,
    SecondaryButton,
} from '../blocks';
import NavResponsiveModal from '@/components/Navigation/NavResponsiveModal';
import { toNational } from '@/utilities/formatPhoneNumber';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import statesInverted from '@/constants/statesInverted.json';
import { Grid, Tooltip } from '@material-ui/core';
import { colors } from '@/styles';
import { format } from 'date-fns';
import { hourMinuteFormatter, poundFormatter, integerFormatter } from '@/constants/formats';
import { normalizeRange } from '@/utilities/normalizeRange';
import { firstLetterUppercase } from '@/utilities/firstLetterUppercase';
import { calcAnyItemTypeCount } from '@/utilities/calculateItemMetrics';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useClientUser, useOrderNotes, useTotalRevenue } from '@/hooks';
import { startCase } from 'lodash';
import { LOCATION_TYPES } from '@/constants/locationTypes';
import { useNavigate } from 'react-router-dom';
import routeStatusOptions from '@/constants/routeStatusOptions';
import ExceptionModal from '@/components/OrderDetailsPage/modals/Exceptions';
import { EXCEPTION_TYPES } from '@onward-delivery/core';
import FTLStopHelpers from '@/utilities/FTLStopHelpers';
import {
    INSERT_EXCEPTION,
    INSERT_EXCEPTION_EVENT,
    UPDATE_EXCEPTION,
} from '@/components/OrderDetailsPage/graphql/mutations';
import { captureException } from '@sentry/react';
import { useMutation } from '@apollo/client';
import { useExceptionCallbacks } from '@/components/OrderDetailsPage/hooks';
import { UPDATE_ORDER_BY_ID } from '@/graphql/mutations/orders';
import { UPDATE_ROUTE_STOPS } from '../graphql/mutations';
import { QUERY_PLANNING_DATA } from '../graphql/queries';

const SectionTitle = styled.span`
    font-size: 16px;
    font-weight: 900;
    color: ${colors.greys[2]};
`;

const OrderTitle = styled.span`
    font-size: 28px;
    font-weight: 800;
`;

const Item = ({ item }) => {
    const dimensions = [
        ...[item.total_weight ? [poundFormatter.format(normalizeRange(item.total_weight))] : []],
        ...[item.total_cubes ? [`${integerFormatter.format(normalizeRange(item.total_cubes))} cu. ft.`] : []],
    ];

    return (
        <Grid container>
            <Grid container direction="column" xs={item.pic ? 9 : 12}>
                <Grid item>
                    <Body1>{item.item_type_details}&nbsp;</Body1>
                    <Body2>{dimensions.join(' ')}</Body2>
                </Grid>
                {!!item.description && (
                    <Grid item>
                        <Body2>Description: {item.description}</Body2>
                    </Grid>
                )}
                <Grid item>
                    <Body2>Quantity: {item.quantity}</Body2>
                </Grid>
                {item.sku && (
                    <Grid item>
                        <Body2>SKU: {item.sku}</Body2>
                    </Grid>
                )}
            </Grid>

            {item.pic && (
                <Grid item xs="3">
                    <a href={item.pic} target="_blank" rel="noreferrer noopener">
                        <img src={item.pic} style={{ height: '100%', width: '100%' }} />
                    </a>
                </Grid>
            )}
        </Grid>
    );
};

const genericInfo = [
    {
        label: 'PO #',
        value: (order) => (order.po_number ? order.po_number : '--'),
    },
    {
        label: 'Created',
        value: (order) => (order?.created_at ? format(new Date(order.created_at), 'MM/dd/yy') : '--'),
    },
    {
        label: 'Shipper',
        value: (order) => (order?.order_shipper?.business_name ? order?.order_shipper?.business_name : '--'),
    },
    {
        label: 'Service Level',
        value: (order) => (order?.dropoff_location_type ? LOCATION_TYPES[order.dropoff_location_type] : '--'),
    },
    {
        label: 'Est. Delivery Range',
        value: (order) =>
            order?.estimated_delivery_range_start_formatted + '- ' + order?.estimated_delivery_range_end_formatted ||
            '--',
    },
    {
        label: 'Preferred Delivery Date',
        value: (order) => order?.preferred_delivery_date_formatted || '--',
    },
    {
        label: 'Alternative Delivery Dates',
        value: (order) => order?.alternative_delivery_dates_formatted?.join(', ') || '--',
    },
    {
        label: 'Scheduled Delivery Date',
        value: (order) => order.delivery_date_formatted || '--',
    },
    {
        label: 'Estimated Distance',
        value: (order) =>
            order.distance ? order.distance : order.miles ? parseFloat(order.miles).toFixed(1) + ' mi' : '--',
    },
    {
        label: 'Estimated Trip Time',
        value: (order) => (order?.duration_seconds && hourMinuteFormatter.format(order?.duration_seconds / 60)) || '--',
    },
];

const pickupContactFields = [
    (order) => order.pickup_name,
    (order) => toNational(order.pickup_phone),
    (order) => order.pickup_email || '--',
];

const pickupFields = [
    (order) => order.pickup_full_address || order.pickup_address || '--',
    (order) =>
        `First available: ${
            order.first_available_date ? format(new Date(order.first_available_date), 'MM/dd/yyyy') : '--'
        }`,
    (order) => {
        return `Comments: ${order.pickup_notes}`;
    },
];

const dropoffContactFields = [
    (order) => order.dropoff_name,
    (order) => toNational(order.dropoff_phone),
    (order) => order.dropoff_email || '--',
];

const dropoffFields = [
    (order) => order.dropoff_full_address || order.dropoff_address || '--',
    (order) => {
        let displayString = order.dropoff_location + ', ' + order.dropoff_location_info;
        if (order.dropoff_location === 'Residence') {
            displayString += ', ' + firstLetterUppercase(order.dropoff_location_type);
        }

        return displayString;
    },
    (order) => {
        return `Comments: ${order.dropoff_notes}`;
    },
];

const OrderViewModal = () => {
    const { user_id, accountType, userType } = useClientUser();
    const {
        modalOpen,
        setModalOpen,
        actionableOrder: order,
        setEditModalOpen,
        selectedRoute,
        setError,
        state: { routes },
    } = useContext(PlanningContext);
    const totalRevenue = useTotalRevenue(order);
    const navigate = useNavigate();
    const [activeException, setActiveException] = useState(null);
    const orderNotes = useOrderNotes([order], userType);

    const [updateStops, { loading }] = useMutation(UPDATE_ROUTE_STOPS, {
        onError: (err) => {
            console.log(err);
            captureException(err);
        },
    });

    const [insertExceptionEvent, { loading: insertExceptionEventLoading }] = useMutation(INSERT_EXCEPTION_EVENT, {
        refetchQueries: [QUERY_PLANNING_DATA],
        awaitRefetchQueries: true,
        onError: (err) => {
            captureException(err);
            console.error(err);
            setError('Failed to report exception');
        },
    });

    const [insertException, { loading: insertExceptionLoading }] = useMutation(INSERT_EXCEPTION, {
        onError: (err) => {
            captureException(err);
            console.error(err);
            setError('Failed to report exception');
        },
    });

    const [updateException, { loading: updateExceptionLoading }] = useMutation(UPDATE_EXCEPTION, {
        onError: (err) => {
            captureException(err);
            console.error(err);
            setError('Failed to update exception');
        },
    });

    const route = useMemo(() => {
        return routes?.find((route) => route.route_id === selectedRoute);
    }, [routes, selectedRoute]);

    const routeIsLocked = route && route.status !== routeStatusOptions.PLANNING.value;

    const orderTypeText = useMemo(() => {
        if (accountType === 'carrier') {
            const isClaimed = !order?.oms && order?.carrier_id === user_id;
            if (isClaimed) {
                return <>Claimed Order / Delivery</>;
            } else if (!isClaimed && order?.oms) {
                return <>Internal Order / Delivery</>;
            }
        } else return null;
    }, [order]);

    const revenueLeg = useMemo(() => {
        if (order?.routes[0]?.type !== 'FULL') {
            return order?.show_revenue_on;
        }
        return '';
    }, [order]);

    const orderWithModifiedFields = useMemo(() => {
        return {
            ...(order || {}),
            pickup_notes: (orderNotes?.pickup || []).map((n) => n.note).join(' / '),
            dropoff_notes: (orderNotes?.delivery || []).map((n) => n.note).join(' / '),
        };
    }, [order, orderNotes]);

    const baseCancelException = useMemo(() => {
        if (!order) return null;
        return {
            type: 'DROPOFF',
            order: order,
            action: `${order.event_state}:${
                order?.routes?.find((routeMapping) => routeMapping.route_id === route?.route_id)?.type === 'DROPOFF'
                    ? 'DO_ATTEMPT'
                    : 'ATTEMPT'
            }`,
            orderUpdates: {
                delivery_date: null,
            },
            reported_at: 'DROPOFF',
        };
    }, [order, route]);

    const { createException } = useExceptionCallbacks(
        {
            order: order || {},
            exceptionParams: baseCancelException || {},
            route_id: route?.route_id,
            user_id: user_id,
        },
        { insertExceptionEvent, insertException, updateException }
    );

    const handleModalClose = () => setModalOpen(null);
    const handleEditOrder = () => {
        setModalOpen(MODALS.ORDER_EDIT);
    };
    const handleAddNotes = () => {
        setModalOpen(MODALS.NOTES);
    };

    const handleViewEntireOrder = () => {
        if (order) {
            navigate(`/order/${order.order_id}`);
        }
    };

    if (!order) return null;

    const allPickupFields =
        order.order_type === 'return'
            ? [...dropoffContactFields, ...pickupFields]
            : [...pickupContactFields, ...pickupFields];
    const allDropoffFields =
        order.order_type === 'return'
            ? [...pickupContactFields, ...dropoffFields]
            : [...dropoffContactFields, ...dropoffFields];

    return (
        <NavResponsiveModal
            maxWidth="lg"
            open={modalOpen === MODALS.ORDER_VIEW}
            onClose={handleModalClose}
            css={css`
                max-height: calc(100% - 64px);
            `}
        >
            <ModalTitle
                css={css`
                    padding: 1rem 2rem;
                `}
            >
                <OrderTitle>{`${order.order_number}`}</OrderTitle>
            </ModalTitle>
            <ModalContent
                css={css`
                    padding: 2rem;
                `}
            >
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <GridItemRow>
                            <SectionTitle>
                                {`${order.pickup_city}, ${statesInverted[order.pickup_state]} - ${
                                    order.dropoff_city
                                }, ${statesInverted[order.dropoff_state]}`}
                            </SectionTitle>
                            <SectionTitle>{orderTypeText}</SectionTitle>
                        </GridItemRow>
                        {totalRevenue > 0 && order.shipper_id === user_id && (
                            <GridItemRow>
                                <Body2>
                                    Dispatcher Revenue{' '}
                                    <Tooltip
                                        title={`This price is entered manually during order creation. This is not an Onward estimated price. ${
                                            revenueLeg
                                                ? `*This order has been cross-docked, and revenue will be applied to the ${
                                                      revenueLeg === 'DROPOFF' ? 'Delivery' : 'Pickup'
                                                  } leg`
                                                : ''
                                        }`}
                                    >
                                        <InfoOutlinedIcon fontSize="small" color="success" />
                                    </Tooltip>
                                </Body2>
                                <Body1>${totalRevenue}</Body1>
                            </GridItemRow>
                        )}
                        {genericInfo.map((info) => (
                            <GridItemRow>
                                <Body2>{info.label}</Body2>
                                <Body1>{info.value(order)}</Body1>
                            </GridItemRow>
                        ))}
                        {['cancelled', 'rejected'].includes(order.order_status) && (
                            <GridItemRow>
                                <Body2>Status</Body2>
                                <Body1
                                    css={css`
                                        color: red;
                                    `}
                                >
                                    Cancelled
                                </Body1>
                            </GridItemRow>
                        )}
                    </Grid>
                    <Grid item xs={6}>
                        <SectionTitle>{`${order.order_type === 'return' ? 'Return ' : ''}Pickup`}</SectionTitle>
                        {allPickupFields.map((field, index) => (
                            <GridItemRow key={`pickupfield-${index}`}>
                                <Body2>{field(orderWithModifiedFields)}</Body2>
                            </GridItemRow>
                        ))}
                    </Grid>
                    <Grid item xs={6}>
                        <SectionTitle>{`${order.order_type === 'return' ? 'Return ' : ''}Delivery`}</SectionTitle>
                        {allDropoffFields.map((field, index) => (
                            <GridItemRow key={`dropofffield-${index}`}>
                                <Body2>{field(orderWithModifiedFields)}</Body2>
                            </GridItemRow>
                        ))}
                    </Grid>
                    {order.items?.length > 0 && (
                        <Grid item xs={6}>
                            <SectionTitle>{`Items (${calcAnyItemTypeCount(order.items)})`}</SectionTitle>
                            {order.items?.map((item, index) => (
                                <Grid item key={'item-' + index}>
                                    <Item item={item} />
                                </Grid>
                            ))}
                        </Grid>
                    )}
                    {order.returns?.length > 0 && (
                        <Grid item xs={6}>
                            <SectionTitle>{`Return Items (${calcAnyItemTypeCount(order.returns)})`}</SectionTitle>
                            {order.returns?.map((item, index) => (
                                <Grid item key={'return-' + index}>
                                    <Item item={item} />
                                </Grid>
                            ))}
                        </Grid>
                    )}
                    {order.haulaway_items?.length > 0 && (
                        <Grid item xs={12}>
                            <SectionTitle>Haul-away</SectionTitle>
                            {order.haulaway_items?.map((item, index) => (
                                <Grid item key={'haulaway-' + index}>
                                    <Item item={item} />
                                </Grid>
                            ))}
                        </Grid>
                    )}
                </Grid>
            </ModalContent>
            <ModalActions
                css={css`
                    padding: 1rem 2rem;
                `}
            >
                <Body2>Remove order from route to edit address information</Body2>
                <Grid
                    container
                    spacing={2}
                    justifyContent="flex-end"
                    css={css`
                        margin-top: 16px;
                    `}
                >
                    <Grid item>
                        <PrimaryButton onClick={handleViewEntireOrder}>View entire order</PrimaryButton>
                    </Grid>
                    <Grid item>
                        <SecondaryButton onClick={handleAddNotes}>Add Notes</SecondaryButton>
                    </Grid>
                    {routeIsLocked && !['cancelled', 'rejected'].includes(order.order_status) && (
                        <Grid item>
                            <DangerButton onClick={() => setActiveException(baseCancelException)}>
                                Cancel Order
                            </DangerButton>
                        </Grid>
                    )}
                    <Grid item>
                        <SecondaryButton disabled={order.oms ? false : true} onClick={handleEditOrder}>
                            Edit
                        </SecondaryButton>
                    </Grid>
                </Grid>
            </ModalActions>
            <ExceptionModal
                {...(activeException || {})}
                prev={{ type: EXCEPTION_TYPES.CARRIER_CANCELLATION }}
                types={[EXCEPTION_TYPES.CARRIER_CANCELLATION]}
                order={order}
                options={{
                    disableAddAnother: true,
                }}
                loading={insertExceptionEventLoading || insertExceptionLoading || updateExceptionLoading}
                callbacks={{
                    onError: (e) => {
                        setError('Error reporting exception');
                    },
                    onSubmit: ({ exception }) => {
                        return createException(exception).then(() => {
                            const newStops = FTLStopHelpers.addStop(order, route, {
                                addExceptionReturn: true,
                            });
                            updateStops({
                                variables: {
                                    route_id: route?.route_id,
                                    route_update: {},
                                    order_updates:
                                        exception.type === EXCEPTION_TYPES.CARRIER_CANCELLATION
                                            ? [
                                                  {
                                                      where: {
                                                          order_id: {
                                                              _eq: order.order_id,
                                                          },
                                                      },
                                                      _set: {
                                                          order_status:
                                                              user_id === order.carrier_id ? 'rejected' : 'cancelled',
                                                      },
                                                  },
                                              ]
                                            : [],
                                    ...FTLStopHelpers.gqlStopUpdates(newStops, route),
                                },
                            });
                            setActiveException(null);
                        });
                    },
                    onClose: () => {
                        setActiveException(null);
                        handleModalClose();
                    },
                }}
            />
        </NavResponsiveModal>
    );
};

export default OrderViewModal;
