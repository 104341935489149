import React, { useCallback, useMemo } from 'react';
import { camelCase, capitalize, isFinite, startCase } from 'lodash';
import STATES_INVERTED from '@/constants/statesInverted';
import { asBrowserDate, asUTCDate } from '@/utilities/convertToISO';
import { format, differenceInDays, addDays } from 'date-fns';
import { customerTimeframe, delWindowToFormattedDateRange } from '@/utilities/delWindowToFormattedDate';
import { calcMilestone, EXCEPTION_DISPLAY } from '@onward-delivery/core';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@material-ui/icons/Close';
import { LOCATION_TYPES } from '@/constants/locationTypes';
import {
    PRE_TRANSIT_ORDER_STATUSES,
    IN_TRANSIT_ORDER_STATUSES,
    COMPLETE_ORDER_STATUSES,
    CONFIRMED_ORDER_STATUSES,
    UNCONFIRMED_ORDER_STATUSES,
} from '@onward-delivery/core';
import { css } from '@emotion/react';
import { aggregateEvents } from '@onward-delivery/core';
import { colors } from '@/styles';
import { RECEIVED_STATUSES } from '@/constants/manifestStatuses';
import { BodyCellText } from '@/components/MyOrders/blocks';

const date = (timestamp) => {
    return timestamp ? format(asBrowserDate(timestamp), 'MM/dd/yyyy') : '-';
};

const dwell = (pickup, completion) => {
    return pickup && completion ? `${differenceInDays(new Date(completion), new Date(pickup))} days` : '-';
};

const dateShort = new Intl.DateTimeFormat('en-US', {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
});

const timestamp = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
});

const rateFormat = (rate) => {
    if (isFinite(rate)) {
        return `$${rate.toFixed(2)}`;
    }
    return '-';
};

const marginFormat = (margin) => {
    if (isFinite(margin)) {
        return `${margin.toFixed(2)} %`;
    }
    return '-';
};

export const useTabs = ({ page = 'admin' }) => {
    return useMemo(() => {
        const TODAY = new Date(new Date().setHours(0, 0, 0, 0));

        return [
            {
                value: 'INCOMPLETE',
                label: 'Incomplete',
                filters: [
                    {
                        _or: [
                            { _and: [{ source_form: { _neq: 'APIv2' } }, { order_status: { _in: ['duplicate'] } }] },
                            { order_status: { _in: ['incomplete', 'pending_confirmation', 'processing'] } },
                        ],
                    },
                    { job_id: { _is_null: false } },
                ],
            },
            {
                value: 'ALL',
                label: 'All',
                filters: [{ order_status: { _nin: [...UNCONFIRMED_ORDER_STATUSES, 'deleted', 'incomplete'] } }],
            },
            {
                value: 'ALL_ACTIVE',
                label: 'All - Active',
                filters: [{ order_status: { _in: ['pending', 'open', 'claimed', 'inProgress', 'routedInProgress'] } }],
            },
            {
                value: 'PENDING_PAYMENT',
                label: 'Pending Payment',
                filters: [{ order_status: { _in: ['on_hold_awaiting_payment'] } }],
                restrictedView: 'admin',
            },
            {
                value: 'PENDING',
                label: 'Pending',
                filters: [{ order_status: { _in: ['pending'] } }],
            },
            {
                value: 'OPEN',
                label: 'Open',
                filters: [{ order_status: { _in: ['open'] } }],
                restrictedView: 'admin',
            },
            {
                value: 'CLAIMED',
                label: 'Claimed',
                filters: [
                    { order_status: { _eq: 'claimed' } },
                    { carrier_id: { _is_null: false } },
                    {
                        _or: [
                            { _not: { wh_events: {} } },
                            {
                                wh_events: {
                                    _and: [
                                        { action: { _eq: 'START:RECEIVING' } },
                                        { _or: [{ status: { _eq: 'NOT_DELIVERED' } }, { status: { _is_null: true } }] },
                                    ],
                                },
                            },
                        ],
                    },
                ],
            },
            {
                value: 'PRE_SCHEDULED',
                label: 'Pre Scheduled',
                filters: [
                    { order_status: { _in: ['pending', 'claimed'] } },
                    { _not: { routes: {} } },
                    { carrier_id: { _is_null: false } },
                    { delivery_date: { _is_null: false } },
                    {
                        _or: [
                            { _not: { wh_events: {} } },
                            {
                                wh_events: {
                                    _and: [
                                        { action: { _eq: 'START:RECEIVING' } },
                                        { _or: [{ status: { _eq: 'NOT_DELIVERED' } }, { status: { _is_null: true } }] },
                                    ],
                                },
                            },
                        ],
                    },
                ],
            },
            {
                value: 'PICK_UP_SCHEDULED',
                label: 'Pick Up Scheduled',
                filters: [
                    { order_status: { _in: ['pending', 'claimed'] } },
                    { carrier_id: { _is_null: false } },
                    { delivery_date: { _is_null: true } },
                    { pickup_date: { _is_null: false } },
                    {
                        wh_events: {
                            _and: [{ action: { _eq: 'START:RECEIVING' } }, { status: { _eq: 'RECEIVED' } }],
                        },
                    },
                ],
            },
            {
                value: 'RECEIVED',
                label: 'Received',
                filters: [
                    { order_status: { _in: ['pending', 'claimed'] } },
                    { carrier_id: { _is_null: false } },
                    { delivery_date: { _is_null: true } },
                    { pickup_date: { _is_null: true } },
                    {
                        wh_events: {
                            _and: [{ action: { _eq: 'START:RECEIVING' } }, { status: { _eq: 'RECEIVED' } }],
                        },
                    },
                ],
            },
            {
                value: 'SCHEDULED',
                label: 'Scheduled',
                filters: [
                    { order_status: { _in: ['pending', 'claimed'] } },
                    { carrier_id: { _is_null: false } },
                    { delivery_date: { _is_null: false } },
                    { pickup_date: { _is_null: false } },
                    { _or: [{ del_window_start: { _is_null: true } }, { del_window_end: { _is_null: true } }] },
                    {
                        wh_events: {
                            _and: [{ action: { _eq: 'START:RECEIVING' } }, { status: { _eq: 'RECEIVED' } }],
                        },
                    },
                ],
            },
            {
                value: 'READY_FOR_DELIVERY',
                label: 'Ready for Delivery',
                filters: [
                    { order_status: { _in: ['pending', 'claimed'] } },
                    { carrier_id: { _is_null: false } },
                    { delivery_date: { _is_null: false } },
                    { pickup_date: { _is_null: false } },
                    { del_window_start: { _is_null: false } },
                    { del_window_end: { _is_null: false } },
                    {
                        wh_events: {
                            _and: [{ action: { _eq: 'START:RECEIVING' } }, { status: { _eq: 'RECEIVED' } }],
                        },
                    },
                ],
            },
            {
                value: 'CROSSDOCK',
                label: 'Crossdock',
                filters: [
                    { order_status: { _in: PRE_TRANSIT_ORDER_STATUSES } },
                    {
                        wh_events: {
                            action: { _in: ['START:ADD_CD', 'RECEIVING:ADD_CD'] },
                        },
                    },
                    {
                        wh_events: {
                            _and: [{ action: { _eq: 'START:RECEIVING' } }, { status: { _eq: 'RECEIVED' } }],
                        },
                    },
                ],
            },
            {
                value: 'OUT_FOR_DELIVERY',
                label: 'Out for Delivery',
                filters: [
                    {
                        order_status: {
                            _nin: [
                                ...COMPLETE_ORDER_STATUSES,
                                ...UNCONFIRMED_ORDER_STATUSES,
                                'deleted',
                                'incomplete',
                                'cancelled',
                                'rescheduled',
                            ],
                        },
                    },
                    {
                        _or: [
                            { order_status: { _in: IN_TRANSIT_ORDER_STATUSES } },
                            {
                                _and: [
                                    { delivery_date: { _is_null: false } },
                                    { delivery_date: { _gte: TODAY.toISOString() } },
                                    { delivery_date: { _lt: addDays(TODAY, 1).toISOString() } },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                value: 'DNF',
                label: 'DNF',
                filters: [
                    {
                        order_status: {
                            _in: IN_TRANSIT_ORDER_STATUSES,
                        },
                    },
                    { delivery_date: { _is_null: false } },
                    { delivery_date: { _lt: TODAY.toISOString() } },
                ],
                red: true,
            },
            {
                value: 'DELIVERED',
                label: 'Delivered',
                filters: [
                    { order_status: { _in: COMPLETE_ORDER_STATUSES } },
                    { customer_signature: { _is_null: true } },
                    { _or: [{ pod: { _is_null: true } }, { pod: { _eq: '' } }] },
                ],
                red: true,
            },
            {
                value: 'COMPLETED',
                label: 'Completed',
                filters: [
                    { order_status: { _in: COMPLETE_ORDER_STATUSES } },
                    {
                        _or: [
                            { customer_signature: { _is_null: false } },
                            { pod: { _is_null: false } },
                            { pod: { _neq: '' } },
                        ],
                    },
                ],
            },
            {
                value: 'EXCEPTION_REPORTED',
                label: 'Exception Reported',
                filters: [
                    { order_status: { _nin: [...UNCONFIRMED_ORDER_STATUSES, 'deleted', 'incomplete'] } },
                    {
                        _or: [{ exceptions: {} }, { itemsByOrderId: { exceptions: {} } }],
                    },
                ],
                red: true,
            },
            {
                value: 'FINANCIALS',
                label: 'LTL Marketplace Financials',
                filters: [{ order_status: { _eq: 'complete' } }, { oms: { _eq: false } }, { _not: { routes: {} } }],
                restrictedView: 'admin',
            },
        ].filter((tab) => !tab?.restrictedView || tab?.restrictedView === page);
    }, []);
};

export const useRowHighlight = (tab) => {
    return useCallback(
        (row) => {
            const order = row.original;
            const NOW = new Date();

            switch (tab.value) {
                case 'CLAIMED':
                    if (addDays(new Date(order.claimed_date), 7).getTime() < NOW.getTime()) {
                        row.red = true;
                    }
                    break;
                case 'RECEIVED': {
                    const receive = order.wh_events.find((e) => e.action === 'START:RECEIVING');
                    if (addDays(new Date(receive.created_at), 1).getTime() < NOW.getTime()) {
                        row.red = true;
                    }
                    break;
                }
                case 'DNF':
                case 'DELIVERED':
                    if (addDays(new Date(order.delivery_date), 2).getTime() < NOW.getTime()) {
                        row.red = true;
                    }
                    break;
                case 'EXCEPTION_REPORTED':
                    if (
                        [
                            ...(order?.exceptions || []).filter((mapping) => mapping.exception.status !== 'RESOLVED'),
                            ...(order?.itemsByOrderId || []).reduce((acc, item) => {
                                acc = [
                                    ...acc,
                                    ...(item?.exceptions || []).filter(
                                        (mapping) => mapping.exception.status !== 'RESOLVED'
                                    ),
                                ];
                                return acc;
                            }, []),
                        ].some((mapping) => {
                            return addDays(new Date(mapping.created_at), 1).getTime() < NOW.getTime();
                        })
                    ) {
                        row.red = true;
                    }
                    break;
            }
        },
        [tab]
    );
};

export const useColumns = (tab, callbacks) => {
    return useMemo(() => {
        return [
            {
                Header: '',
                id: 'order-details-group',
                columns: [
                    {
                        id: 'order_number',
                        Header: 'Order',
                        accessor: (order) => order.order_number,
                        disableSortBy: true,
                        width: 100,
                    },
                    {
                        id: 'po_number',
                        Header: 'PO #',
                        accessor: (order) => order.po_number || '-',
                        disableSortBy: true,
                        width: 150,
                    },
                    {
                        id: 'route',
                        Header: 'Route',
                        accessor: (order) => {
                            const pickup = order.routes.find((mapping) => mapping.type === 'PICKUP')?.route;
                            const dropoff = order.routes.find((mapping) => mapping.type !== 'PICKUP')?.route;
                            return [...(pickup ? [pickup?.route_number] : []), dropoff?.route_number || '-'].join(
                                ' / '
                            );
                        },
                        disableSortBy: true,
                        width: 75,
                    },
                    {
                        id: 'shipper',
                        Header: 'Shipper',
                        accessor: (order) => order.order_shipper?.business_name || '-',
                        disableSortBy: true,
                        width: 250,
                    },
                    {
                        id: 'carrier',
                        Header: 'Carrier',
                        accessor: (order) => order.order_carrier?.business_name || '-',
                        disableSortBy: true,
                        width: 250,
                    },
                    {
                        id: 'customer',
                        Header: 'Customer',
                        accessor: (order) => order.dropoff_name || '-',
                        disableSortBy: true,
                        width: 150,
                    },
                    {
                        id: 'order_type',
                        Header: 'Type',
                        accessor: (order) => capitalize(order.order_type),
                        disableSortBy: true,
                        width: 100,
                    },
                    ...(tab === 'OPEN'
                        ? [
                              {
                                  id: 'bids',
                                  Header: '# Bids',
                                  accessor: (order) => order?.listing?.bids?.length,
                                  disableSortBy: true,
                                  width: 100,
                              },
                          ]
                        : []),
                    {
                        id: 'origin',
                        Header: 'Origin',
                        accessor: (order) =>
                            `${capitalize(order.pickup_city)}, ${
                                STATES_INVERTED[startCase(camelCase(order.pickup_state))] ||
                                order.pickup_state?.toUpperCase()
                            }`,
                        disableSortBy: true,
                        width: 150,
                    },
                    {
                        id: 'destination',
                        Header: 'Destination',
                        accessor: (order) =>
                            `${capitalize(order.dropoff_city)}, ${
                                STATES_INVERTED[startCase(camelCase(order.dropoff_state))] ||
                                order.dropoff_state?.toUpperCase()
                            }`,
                        disableSortBy: true,
                        width: 150,
                    },
                    {
                        id: 'posted_date',
                        Header: 'Posted',
                        accessor: (order) => date(order.posted_date || order.created_at),
                        disableSortBy: true,
                        width: 100,
                    },
                    {
                        id: 'claimed_date',
                        Header: 'Claimed',
                        accessor: (order) => date(order.claimed_date),
                        disableSortBy: true,
                        width: 100,
                    },
                    {
                        id: 'first_available_date',
                        Header: 'Available',
                        accessor: (order) => date(order.first_available_date),
                        disableSortBy: true,
                        width: 100,
                    },
                    {
                        id: 'pickup_date',
                        Header: 'Pickup',
                        accessor: (order) => date(order.pickup_date),
                        disableSortBy: true,
                        width: 100,
                    },
                    {
                        id: 'delivery_date',
                        Header: 'Delivery',
                        accessor: (order) => date(order.delivery_date),
                        width: 100,
                    },
                    {
                        id: 'timeframe',
                        Header: 'Timeframe',
                        accessor: (order) =>
                            customerTimeframe(
                                order.del_window_start,
                                order.del_window_end,
                                order.order_type === 'return' ? order.pickup_zip : order.dropoff_zip
                            ) || '-',
                        disableSortBy: true,
                        width: 150,
                    },
                    {
                        id: 'delivery_type',
                        Header: 'Delivery Type',
                        accessor: (order) =>
                            LOCATION_TYPES[order.dropoff_location_type] || startCase(order.dropoff_location_type),
                        width: 150,
                    },
                    {
                        id: 'cubes',
                        Header: 'Cubes',
                        accessor: (order) =>
                            (order.itemsByOrderId || [])
                                .reduce((acc, item) => {
                                    return acc + item.total_cubes || 0;
                                }, 0)
                                .toFixed(0),
                        disableSortBy: true,
                        width: 75,
                    },
                    {
                        id: 'weight',
                        Header: 'Weight',
                        accessor: (order) =>
                            (order.itemsByOrderId || [])
                                .reduce((acc, item) => {
                                    return acc + item.total_weight || 0;
                                }, 0)
                                .toFixed(0),
                        disableSortBy: true,
                        width: 75,
                    },
                    {
                        id: 'shipper_rate',
                        Header: 'Shipper Rate',
                        accessor: (order) => order.shipper_rate,
                        Cell: ({ value }) => rateFormat(value),
                        disableSortBy: true,
                        width: 125,
                    },
                    {
                        id: 'carrier_rate',
                        Header: 'Carrier Rate',
                        accessor: (order) => order.carrier_rate,
                        Cell: ({ value }) => rateFormat(value),
                        disableSortBy: true,
                        width: 125,
                    },
                    {
                        id: 'margin',
                        Header: 'Margin',
                        accessor: (order) => (100 * (order.shipper_rate - order.carrier_rate)) / order.shipper_rate,
                        Cell: ({ value }) => marginFormat(value),
                        disableSortBy: true,
                        width: 100,
                    },
                    {
                        id: 'manufacturer',
                        Header: 'Manufacturer',
                        accessor: (order) => order?.manufacturer || '-',
                        disableSortBy: true,
                    },
                    {
                        id: 'middle_mile_integration_status',
                        Header: 'MM Status',
                        width: 200,
                        accessor: (order) => {
                            return order.middle_mile_integration_status;
                        },
                        Cell: ({ row: order, value }) => {
                            return value ? startCase(value.toLowerCase()).replace('_', '-') : '-';
                        },
                        disableSortBy: true,
                    },
                    {
                        id: 'middle_mile_integration_meta.tracking_number',
                        Header: 'MM Tracking #',
                        width: 200,
                        accessor: (order) => {
                            return order.middle_mile_integration_meta?.tracking_number;
                        },
                        Cell: ({ row: order, value }) => {
                            return value || '-';
                        },
                        disableSortBy: true,
                    },
                    {
                        id: 'middle_mile_integration_type',
                        Header: 'MM Integration',
                        width: 200,
                        accessor: (order) => order.middle_mile_integration_type,
                        Cell: ({ value }) => value || '-',
                        disableSortBy: true,
                    },
                    {
                        id: 'middle_mile_integration_meta.carrier_name',
                        Header: 'MM Carrier',
                        width: 200,
                        accessor: (order) => order.middle_mile_integration_meta?.carrier_name,
                        Cell: ({ value }) => value || '-',
                        disableSortBy: true,
                    },
                    {
                        id: 'middle_mile_rate',
                        Header: 'MM Carrier Rate',
                        accessor: (order) => order.middle_mile_rate,
                        Cell: ({ value }) => rateFormat(value),
                        disableSortBy: true,
                        width: 150,
                    },
                    {
                        id: 'total_carrier_rate',
                        Header: 'Total Carrier Rate',
                        accessor: (order) => (order.middle_mile_rate || 0) + (order.carrier_rate || 0),
                        Cell: ({ value }) => rateFormat(value),
                        disableSortBy: true,
                        width: 150,
                    },
                    {
                        id: 'warehousing',
                        Header: 'Warehousing Status',
                        accessor: (order) => {
                            const receive = order.wh_events.find((event) => event.action === 'START:RECEIVING') || {};
                            return receive?.status || 'NOT_DELIVERED';
                        },
                        Cell: ({ row, value }) => {
                            let fragment;
                            let label;
                            let icon = null;

                            const order = row.original;
                            switch (value) {
                                case 'RECEIVED': {
                                    label = 'Received';
                                    fragment = css`
                                        color: ${colors.greens.primary};
                                    `;
                                    break;
                                }
                                case 'NOT_DELIVERED':
                                default: {
                                    const [received, total] = (order.itemsByOrderId || []).reduce(
                                        ([recAcc, totAcc], item) => {
                                            return [
                                                recAcc +
                                                    (RECEIVED_STATUSES.includes(item.pallet?.warehouse_status)
                                                        ? item.quantity || 1
                                                        : 0),
                                                totAcc + (item.quantity || 1),
                                            ];
                                        },
                                        [0, 0]
                                    );

                                    if (received > 0) {
                                        label = `${received}/${total} Received`;
                                        fragment = css`
                                            color: ${colors.blues[0]};
                                        `;
                                    } else {
                                        label = 'Not Received';
                                        fragment = css`
                                            color: ${colors.greys[3]};
                                        `;
                                    }
                                    break;
                                }
                            }

                            return (
                                <div
                                    css={css`
                                        display: flex;
                                        align-items: center;
                                        white-space: nowrap;
                                    `}
                                >
                                    <BodyCellText
                                        css={css`
                                            font-weight: 700;
                                            ${fragment};
                                        `}
                                    >
                                        {label}
                                    </BodyCellText>
                                    {icon}
                                </div>
                            );
                        },
                        width: 200,
                    },
                    {
                        id: 'rereceivingstatus',
                        Header: 'Rereceiving Status',
                        accessor: (order) => {
                            const warehouse = aggregateEvents(order.wh_events);
                            return warehouse.status;
                        },
                        Cell: ({ row, value }) => {
                            let fragment;
                            let label;
                            let icon = null;
                            switch (value) {
                                case 'RECEIVED': {
                                    label = 'Received';
                                    fragment = css`
                                        color: ${colors.greens.primary};
                                    `;
                                    break;
                                }
                                case 'NOT_DELIVERED':
                                default: {
                                    label = 'Not Received';
                                    fragment = css`
                                        color: ${colors.greys[3]};
                                    `;

                                    break;
                                }
                            }

                            return (
                                <div
                                    css={css`
                                        display: flex;
                                        align-items: center;
                                        white-space: nowrap;
                                    `}
                                >
                                    <BodyCellText
                                        css={css`
                                            font-weight: 700;
                                            ${fragment};
                                        `}
                                    >
                                        {label}
                                    </BodyCellText>
                                    {icon}
                                </div>
                            );
                        },
                        width: 200,
                    },
                    {
                        id: 'est_ship_date',
                        Header: 'Estimated Ship Date',
                        accessor: (order) => {
                            const receive = order.wh_events.find((event) => event.action === 'START:RECEIVING') || {};
                            return new Date(receive.est_ship_date);
                        },
                        Cell: ({ value }) => {
                            if (isNaN(value) || !value?.getTime()) {
                                return '-';
                            }

                            return dateShort.format(value);
                        },
                        width: 200,
                    },
                    {
                        id: 'est_received_date',
                        Header: 'Estimated Received Date',
                        accessor: (order) => {
                            const receive = order.wh_events.find((event) => event.action === 'START:RECEIVING') || {};
                            return new Date(receive.est_received_date);
                        },
                        Cell: ({ value }) => {
                            if (isNaN(value) || !value?.getTime()) {
                                return '-';
                            }

                            return dateShort.format(value);
                        },
                        width: 200,
                    },
                    {
                        id: 'received_date',
                        Header: 'Actual Received Date',
                        accessor: (order) => {
                            const receive = order.wh_events.find((event) => event.action === 'START:RECEIVING') || {};
                            return new Date(receive.received_date);
                        },
                        Cell: ({ value }) => {
                            if (isNaN(value) || !value?.getTime()) {
                                return '-';
                            }

                            return dateShort.format(value);
                        },
                        width: 200,
                    },
                    ...(['DNF', 'DELIVERED', 'COMPLETED'].includes(tab)
                        ? [
                              {
                                  Header: 'POD',
                                  id: 'pod',
                                  width: 110,
                                  disableSortBy: false,
                                  Cell: ({ row }) => {
                                      const order = row.original;
                                      const hasPhoto = order.delivery_photo?.length >= 1 || order.pod?.length >= 1;
                                      const hasSignature = order.customer_signature?.length >= 1;

                                      return hasSignature || hasPhoto ? (
                                          <>
                                              <DoneIcon
                                                  css={css`
                                                      color: ${colors.greens.primary};
                                                  `}
                                              />
                                              <button
                                                  onClick={(e) => {
                                                      e.stopPropagation();
                                                      callbacks.viewOrderPod(order);
                                                  }}
                                                  css={css`
                                                      color: ${colors.greens.primary};
                                                      text-decoration: underline;
                                                      margin: 5px;
                                                      font-weight: 500;
                                                      background: none;
                                                      border: none;
                                                      cursor: pointer;
                                                  `}
                                              >
                                                  View
                                              </button>
                                          </>
                                      ) : (
                                          <CloseIcon
                                              css={css`
                                                  color: ${colors.reds.primary};
                                                  background: none;
                                              `}
                                          />
                                      );
                                  },
                              },
                          ]
                        : []),
                    ...(tab === 'EXCEPTION_REPORTED'
                        ? [
                              {
                                  id: 'exception_types',
                                  Header: 'Exception Types',
                                  disableSortBy: true,
                                  accessor: (order) => {
                                      const exceptions = [
                                          ...(order?.order_exception
                                              ? [EXCEPTION_DISPLAY[order?.order_exception.type]]
                                              : []),
                                          ...order.itemsByOrderId
                                              .filter((item) => !!item.item_exception)
                                              .map((item) => EXCEPTION_DISPLAY[item?.item_exception?.type]),
                                      ];

                                      return Array.from(new Set(exceptions)).join(' / ');
                                  },
                              },
                              {
                                  id: 'exception_reported_at',
                                  Header: 'Reported At',
                                  disableSortBy: true,
                                  accessor: (order) => {
                                      const exceptions = [
                                          ...(order?.order_exception ? [order?.order_exception.reported_at] : []),
                                          ...order.itemsByOrderId
                                              .filter((item) => !!item.item_exception)
                                              .map((item) => item?.item_exception?.reported_at),
                                      ];

                                      return Array.from(new Set(exceptions)).join(' / ');
                                  },
                              },
                              {
                                  id: 'exception_created_at',
                                  Header: 'Exception Time',
                                  disableSortBy: true,
                                  accessor: (order) => {
                                      const exceptions = [
                                          ...(order?.order_exception
                                              ? [new Date(order?.order_exception.created_at).getTime()]
                                              : []),
                                          ...order.itemsByOrderId
                                              .filter((item) => !!item.item_exception)
                                              .map((item) => new Date(item?.item_exception?.created_at).getTime()),
                                      ];

                                      return Math.max(...exceptions);
                                  },
                                  Cell: ({ value }) => {
                                      return timestamp.format(new Date(value));
                                  },
                              },
                          ]
                        : []),
                ],
            },
            {
                sticky: 'right',
                Header: '',
                id: 'sticky-right',
                columns: [
                    {
                        id: 'milestone',
                        Header: 'Status',
                        accessor: (order) => calcMilestone(order) || '-',
                        width: 200,
                        disableSortBy: true,
                    },
                ],
            },
        ];
    }, [tab, callbacks]);
};

export const ACCOUNTING_COLUMNS = [
    {
        id: 'order_number',
        Header: 'Order',
        accessor: (order) => order.order_number,
        width: 100,
    },
    {
        id: 'po_number',
        Header: 'PO #',
        accessor: (order) => order.po_number || '-',
        disableSortBy: true,
        width: 150,
    },
    {
        id: 'order_type',
        Header: 'Type',
        accessor: (order) => capitalize(order.order_type),
        disableSortBy: true,
        width: 100,
    },
    {
        id: 'shipper',
        Header: 'Shipper',
        accessor: (order) => order.order_shipper?.business_name || '-',
        disableSortBy: true,
        width: 250,
    },
    {
        id: 'carrier',
        Header: 'Carrier',
        accessor: (order) => order.order_carrier?.business_name || '-',
        disableSortBy: true,
        width: 250,
    },
    {
        id: 'customer',
        Header: 'Customer',
        accessor: (order) => order.dropoff_name || '-',
        disableSortBy: true,
        width: 150,
    },
    {
        id: 'shipper_rate',
        Header: 'Shipper Rate',
        accessor: (order) => order.shipper_rate,
        Cell: ({ value }) => rateFormat(value),
        disableSortBy: true,
        width: 125,
    },
    {
        id: 'carrier_rate',
        Header: 'Carrier Rate',
        accessor: (order) => order.carrier_rate,
        Cell: ({ value }) => rateFormat(value),
        disableSortBy: true,
        width: 125,
    },
    {
        id: 'margin',
        Header: 'Margin',
        accessor: (order) => (100 * (order.shipper_rate - order.carrier_rate)) / order.shipper_rate,
        Cell: ({ value }) => marginFormat(value),
        disableSortBy: true,
        width: 100,
    },
    {
        id: 'payment_type',
        Header: 'Payment Type',
        accessor: (order) => `${order.listing?.payment_intent_id ? 'Credit Card' : 'Invoice'}`,
        disableSortBy: true,
        width: 150,
    },
    {
        id: 'delivery_type',
        Header: 'Delivery Type',
        accessor: (order) => LOCATION_TYPES[order.dropoff_location_type] || startCase(order.dropoff_location_type),
        width: 150,
    },
    {
        id: 'origin',
        Header: 'Origin',
        accessor: (order) =>
            `${capitalize(order.pickup_city)}, ${
                STATES_INVERTED[startCase(camelCase(order.pickup_state))] || order.pickup_state?.toUpperCase()
            }`,
        disableSortBy: true,
        width: 150,
    },
    {
        id: 'destination',
        Header: 'Destination',
        accessor: (order) =>
            `${capitalize(order.dropoff_city)}, ${
                STATES_INVERTED[startCase(camelCase(order.dropoff_state))] || order.dropoff_state?.toUpperCase()
            }`,
        disableSortBy: true,
        width: 150,
    },
    {
        id: 'pickup_date',
        Header: 'Pickup',
        accessor: (order) => date(order.pickup_date),
        disableSortBy: true,
        width: 100,
    },
    {
        id: 'delivery_date',
        Header: 'Scheduled Delivery',
        accessor: (order) => date(order.delivery_date),
        disableSortBy: true,
        width: 150,
    },
    {
        id: 'completed_date',
        Header: 'Actual Completed',
        accessor: (order) => date(order.completion_time),
        disableSortBy: true,
        width: 150,
    },
    {
        id: 'dwell_time',
        Header: 'Dwell Time',
        accessor: (order) => dwell(order.pickup_date, order.completion_time || order.delivery_date),
        disableSortBy: true,
        width: 150,
    },
];

export const SEARCHABLE = ['order_number', 'po_number', 'dropoff_phone'];
