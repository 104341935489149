import { LOCATION_TYPES } from '@/constants/locationTypes';
import { useItemQuantity, useTotalCubes, useTotalWeight } from '@/hooks';
import { toNational } from '@/utilities/formatPhoneNumber';
import useQuery from '@/utilities/useQuery';
import { genAttributes } from '@onward-delivery/core';
import { Document, Font, Image, Page, Text, View } from '@react-pdf/renderer';
import { formatInTimeZone } from 'date-fns-tz';
import { startCase } from 'lodash';
import React, { useMemo, useState } from 'react';
import zipcode_to_timezone from 'zipcode-to-timezone';

const fragments = {
    col: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
    },
    line: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginBottom: '0.03125in',
    },
    item: {
        display: 'flex',
    },
    center: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    text: {
        fontFamily: 'Montserrat',
    },
    body1: {
        fontFamily: 'Montserrat',
        fontSize: '8.5px',
        fontWeight: '700',
    },
    body2: {
        fontFamily: 'Montserrat',
        fontSize: '8.5px',
        fontWeight: '500',
    },
};

const TableHeader = ({ isReturn, isHaulaway }) => {
    return (
        <View
            style={{
                ...fragments.row,
                borderBottom: '1px solid black',
                paddingBottom: '0.0625in',
                paddingTop: '0.0625in',
                justifyContent: 'flex-end',
            }}
        >
            <View style={{ ...fragments.item, alignSelf: 'flex-start', width: '3in' }}>
                <Text style={fragments.body1}>
                    {isHaulaway ? 'Haulaway' : isReturn ? 'Return Item' : 'Item'} Description
                </Text>
            </View>
            <View style={{ ...fragments.item, ...fragments.center, width: '0.5in' }}>
                <Text style={fragments.body1}>Qty</Text>
            </View>
            <View style={{ ...fragments.item, ...fragments.center, width: '0.75in' }}>
                <Text style={fragments.body1}>Length</Text>
            </View>
            <View style={{ ...fragments.item, ...fragments.center, width: '0.75in' }}>
                <Text style={fragments.body1}>Width</Text>
            </View>
            <View style={{ ...fragments.item, ...fragments.center, width: '0.75in' }}>
                <Text style={fragments.body1}>Height</Text>
            </View>
            <View style={{ ...fragments.item, ...fragments.center, width: '0.75in' }}>
                <Text style={fragments.body1}>Cu Ft</Text>
            </View>
            <View style={{ ...fragments.item, ...fragments.center, width: '0.75in' }}>
                <Text style={fragments.body1}>Weight</Text>
            </View>
            <View style={{ ...fragments.item, ...fragments.center, width: '0.75in' }}>
                <Text style={fragments.body1}>Class</Text>
            </View>
            <View style={{ ...fragments.item, ...fragments.center, width: '0.75in' }}>
                <Text style={fragments.body1}>NFMC No.</Text>
            </View>
        </View>
    );
};

const TableRow = ({ item, isReturn, isHaulaway }) => {
    return (
        <View
            style={{
                ...fragments.row,
                borderBottom: '1px solid black',
                paddingBottom: '0.0625in',
                paddingTop: '0.0625in',
            }}
        >
            <View style={{ ...fragments.item, alignSelf: 'flex-start', width: '3in' }}>
                <Text style={{ ...fragments.body2 }}>
                    {item.sku ? `${item.sku} - ` : ''}
                    {item.description || item.item_type_details}
                    {isHaulaway ? ' - DISPOSAL' : ''}
                </Text>
            </View>
            <View style={{ ...fragments.item, ...fragments.center, width: '0.5in' }}>
                <Text style={fragments.body2}>{parseInt(item.quantity || 1)}</Text>
            </View>
            <View style={{ ...fragments.item, ...fragments.center, width: '0.75in' }}>
                <Text style={fragments.body2}>{parseInt(item.length || 12)}</Text>
            </View>
            <View style={{ ...fragments.item, ...fragments.center, width: '0.75in' }}>
                <Text style={fragments.body2}>{parseInt(item.width || 12)}</Text>
            </View>
            <View style={{ ...fragments.item, ...fragments.center, width: '0.75in' }}>
                <Text style={fragments.body2}>{parseInt(item.height || 12)}</Text>
            </View>
            <View style={{ ...fragments.item, ...fragments.center, width: '0.75in' }}>
                <Text style={fragments.body2}>{parseInt(item.total_cubes || 1)}</Text>
            </View>
            <View style={{ ...fragments.item, ...fragments.center, width: '0.75in' }}>
                <Text style={fragments.body2}>{parseInt(item.total_weight || 1)}</Text>
            </View>
            <View style={{ ...fragments.item, ...fragments.center, width: '0.75in' }}>
                <Text style={fragments.body2}>{item.class}</Text>
            </View>
            <View style={{ ...fragments.item, ...fragments.center, width: '0.75in' }}>
                <Text style={fragments.body2}>{item.nfmc_no}</Text>
            </View>
        </View>
    );
};

const Table = ({ items, isHaulaway, isReturn }) => {
    const itemQty = useItemQuantity([{ items }]);
    const itemCubes = useTotalCubes([{ items }]);
    const itemWeight = useTotalWeight([{ items }]);

    return (
        <>
            <View
                style={{
                    ...fragments.row,
                    marginBottom: '0.0625in',
                }}
            >
                <View
                    style={{
                        ...fragments.col,
                        borderTop: '1px solid black',
                    }}
                >
                    <TableHeader isReturn={isReturn} isHaulaway={isHaulaway} />
                    {(items || []).map((item) => (
                        <TableRow key={item.item_id} item={item} isReturn={isReturn} isHaulaway={isHaulaway} />
                    ))}
                </View>
            </View>
            <View
                style={{
                    ...fragments.row,
                    justifyContent: 'flex-end',
                    marginBottom: '0.125in',
                }}
            >
                <Text style={{ ...fragments.body1 }}>Total</Text>
                <Text style={{ ...fragments.body2, width: '2.5in', textAlign: 'right' }}>
                    {parseInt(itemQty)} items / {parseInt(itemCubes)} cu ft / {parseInt(itemWeight)} lbs
                </Text>
            </View>
        </>
    );
};

const ClientSignature = ({ name, margin }) => {
    return (
        <View style={{ ...fragments.col, width: '50%', [margin]: '0.125in' }}>
            <View style={{ ...fragments.row, marginBottom: '0.0625in', alignItems: 'center' }}>
                <Text style={fragments.body2}>{name} name (print): </Text>
                <View style={{ height: '100%', flex: '1', borderBottom: '1px solid black' }}></View>
            </View>
            <View style={{ ...fragments.row, alignItems: 'center' }}>
                <Text style={fragments.body2}>Sign: </Text>
                <View style={{ height: '100%', flex: '3', borderBottom: '1px solid black' }}></View>
                <Text style={fragments.body2}>Date: </Text>
                <View style={{ height: '100%', flex: '1', borderBottom: '1px solid black' }}></View>
            </View>
        </View>
    );
};

const Signature = ({ name }) => {
    return (
        <>
            <View
                style={{
                    ...fragments.row,
                    marginBottom: '0.03125in',
                    justifyContent: 'space-between',
                }}
            >
                <View style={{ ...fragments.item, width: '5in' }}>
                    <Text style={fragments.body2}>{name} Signature</Text>
                </View>
                <View style={{ ...fragments.item, width: '2in' }}>
                    <Text style={fragments.body2}>Date</Text>
                </View>
            </View>
            <View
                style={{
                    ...fragments.row,
                    marginBottom: '0.125in',
                    justifyContent: 'space-between',
                }}
            >
                <View style={{ ...fragments.item, height: '0.25in', width: '5in', border: '1px solid black' }} />
                <View style={{ ...fragments.item, height: '0.25in', width: '2in', border: '1px solid black' }} />
            </View>
        </>
    );
};

const DocumentBOL = ({ order, logo, logoBackground, finePrint, orderNotes, isMM = false }) => {
    Font.register({
        family: 'Montserrat',
        fonts: [
            {
                src: '/fonts/Montserrat-Regular.ttf',
                fontWeight: 500,
            },
            {
                src: '/fonts/Montserrat-Bold.ttf',
                fontWeight: 700,
            },
            {
                src: '/fonts/Montserrat-Black.ttf',
                fontWeight: 900,
            },
        ],
    });
    Font.registerHyphenationCallback((word) => [word]);

    const { street, unit, city, state, zip, location, location_info, location_type } = genAttributes(order);
    const {
        street: pu_street,
        unit: pu_unit,
        city: pu_city,
        state: pu_state,
        zip: pu_zip,
        location: pu_location,
        location_type: pu_location_type,
    } = genAttributes(order, true);

    const tz = useMemo(() => {
        return zipcode_to_timezone.lookup(order[zip]) || 'America/New_York';
    }, [order]);

    const { items, returns } = useMemo(() => {
        return (order.itemsByOrderId || []).reduce(
            (acc, item) => {
                return {
                    items: [...acc.items, ...(item.is_return ? [] : [item])],
                    returns: [...acc.returns, ...(item.is_return ? [item] : [])],
                };
            },
            { items: [], returns: [] }
        );
    }, [order]);

    const [mmStore, store] = useMemo(() => {
        if (!order?.order_shipper?.locations) return [null, null];

        return [
            order.order_shipper.locations.find((l) => l.business_address === order.pickup_street_address),
            order.order_shipper.locations.find((l) => l.business_address === order.middle_mile_origin_address),
        ];
    }, [order]);

    const estReceivedDateEvent = useMemo(() => {
        return (order?.wh_events || []).sort((a, b) => new Date(a) - new Date(b)).find(i => !!i.est_received_date);
    }, []);
    const estPickupDateEvent = useMemo(() => {
        return (order?.wh_events || []).sort((a, b) => new Date(a) - new Date(b)).find(i => !!i.est_pickup_date);
    }, []);

    return (
        <Document>
            <Page>
                <View
                    style={{
                        ...fragments.col,
                        margin: '0.5in',
                        height: 'auto',
                    }}
                >
                    <View style={{ ...fragments.row, marginBottom: '0.25in' }}>
                        <View style={{ ...fragments.col, width: '50%' }}>
                            <View style={{ ...fragments.row, marginBottom: '0.125in' }}>
                                <Text
                                    style={{
                                        ...fragments.text,
                                        fontSize: '12px',
                                        fontWeight: '700',
                                    }}
                                >
                                    Bill of Lading
                                </Text>
                            </View>
                            <View style={fragments.line}>
                                <Text style={fragments.body1}>Order: </Text>
                                <Text style={fragments.body2}>{order.order_number}</Text>
                            </View>
                            <View style={fragments.line}>
                                <Text style={fragments.body1}>PO #: </Text>
                                <Text style={fragments.body2}>{order.po_number}</Text>
                            </View>
                            <View style={fragments.line}>
                                <Text style={fragments.body1}>Ref #: </Text>
                                <Text style={fragments.body2}>{order.reference_id}</Text>
                            </View>
                            <View style={fragments.line}>
                                <Text style={fragments.body1}>Manufacturer: </Text>
                                <Text style={fragments.body2}>{order.manufacturer || 'N/A'}</Text>
                            </View>
                            <View style={fragments.line}>
                                <Text style={fragments.body1}>Tags: </Text>
                                <Text style={fragments.body2}>
                                    {(order.tags || []).map((mapping) => mapping.tag.tag).join(', ')}
                                </Text>
                            </View>
                            {estReceivedDateEvent?.est_received_date && (
                                <View style={fragments.line}>
                                    <Text style={fragments.body1}>Estimated Received Date: </Text>
                                    <Text style={fragments.body2}>
                                        {estReceivedDateEvent?.est_received_date}
                                    </Text>
                                </View>
                            )}
                            {estPickupDateEvent?.est_pickup_date && (
                                <View style={fragments.line}>
                                    <Text style={fragments.body1}>Estimated Pickup Date: </Text>
                                    <Text style={fragments.body2}>
                                        {estPickupDateEvent?.est_pickup_date}
                                    </Text>
                                </View>
                            )}
                        </View>
                        <View style={{ ...fragments.col, width: '50%', alignItems: 'flex-end' }}>
                            <Image
                                src={logo ? logo : '/files/onward-logo.png'}
                                style={{
                                    height: '20px',
                                    width: 'auto',
                                    objectFit: 'contain',
                                    backgroundColor: logoBackground,
                                    marginBottom: '0.0125in',
                                }}
                            />
                            <Text style={fragments.body2}>
                                Hotline:{' '}
                                {order.order_shipper?.support_phone
                                    ? toNational(order.order_shipper.support_phone)
                                    : '-'}
                            </Text>
                            <Text style={fragments.body2}>
                                {order.order_shipper?.business_name}:{' '}
                                {order.order_shipper?.phone ? toNational(order.order_shipper.phone) : '-'}
                            </Text>
                        </View>
                    </View>

                    {isMM ? (
                        <View style={{ ...fragments.row, marginBottom: '0.1in' }}>
                            <View style={{ ...fragments.col, width: '50%' }}>
                                {/* billing + origin */}
                                <Text style={{ ...fragments.body1, marginBottom: '0.03125in' }}>
                                    Send Freight Bill To
                                </Text>
                                <Text style={fragments.body2}>{order.order_shipper?.billing_name}</Text>
                                <Text style={fragments.body2}>
                                    {['billing_address', 'billing_address_unit']
                                        .map((attr) => order.order_shipper?.[attr])
                                        .filter((x) => !!x)
                                        .join(', ')}
                                </Text>
                                <Text style={fragments.body2}>
                                    {['billing_city', 'billing_state', 'billing_zip']
                                        .map((attr) => order.order_shipper?.[attr])
                                        .filter((x) => !!x)
                                        .join(', ')}
                                </Text>
                                <Text style={fragments.body2}>{order.order_shipper?.billing_email}</Text>
                                <Text style={{ ...fragments.body1, marginTop: '0.1in', marginBottom: '0.03125in' }}>
                                    {order.order_type === 'return' ? 'Destination' : 'Origin'}
                                </Text>
                                <Text style={fragments.body2}>Shipper: {order.order_shipper?.business_name}</Text>
                                {mmStore ? (
                                    <Text style={fragments.body2}>Store Name: {mmStore.location_name}</Text>
                                ) : null}
                                <Text style={fragments.body2}>
                                    {['middle_mile_origin_address', 'middle_mile_origin_unit']
                                        .map((attr) => order[attr])
                                        .filter((x) => !!x)
                                        .join(', ')}
                                </Text>
                                <Text style={fragments.body2}>
                                    {['middle_mile_origin_city', 'middle_mile_origin_state', 'middle_mile_origin_zip']
                                        .map((attr) => order[attr])
                                        .filter((x) => !!x)
                                        .join(', ')}
                                </Text>
                                <Text style={fragments.body2}>{order.middle_mile_origin_name}</Text>
                                <Text style={fragments.body2}>{toNational(order.middle_mile_origin_phone)}</Text>
                                <Text style={fragments.body2}>{order.middle_mile_origin_email}</Text>
                                {order?.middle_mile_integration?.carrier_name || order?.middle_mile_integration_type && (
                                    <>
                                        <Text style={{ ...fragments.body1, marginTop: '0.1in', marginBottom: '0.03125in' }}>
                                            Middle Mile Carrier:
                                        </Text>
                                        <Text style={fragments.body2}>{order?.middle_mile_integration?.carrier_name || order?.middle_mile_integration_type}</Text>
                                    </>
                                )}
                            </View>
                            <View style={{ ...fragments.col, width: '50%' }}>
                                {/* pickup + dropoff */}
                                <Text style={{ ...fragments.body1, marginBottom: '0.03125in' }}>Warehouse</Text>
                                <Text style={fragments.body2}>Carrier: {order.order_carrier?.business_name}</Text>
                                <Text style={fragments.body2}>{order.pickup_name}</Text>
                                <Text style={fragments.body2}>
                                    {[
                                        order[order.order_type === 'return' ? street : pu_street],
                                        order[order.order_type === 'return' ? unit : pu_unit],
                                    ]
                                        .filter((x) => !!x)
                                        .join(', ')}
                                </Text>
                                <Text style={fragments.body2}>
                                    {[
                                        order[order.order_type === 'return' ? city : pu_city],
                                        order[order.order_type === 'return' ? state : pu_state],
                                        order[order.order_type === 'return' ? zip : pu_zip],
                                    ]
                                        .filter((x) => !!x)
                                        .join(', ')}
                                </Text>
                                <Text style={fragments.body2}>{toNational(order.pickup_phone)}</Text>
                                <Text style={fragments.body2}>{order.pickup_email}</Text>
                                <Text style={fragments.body2}>
                                    Location Type:{' '}
                                    {startCase(order[order.order_type === 'return' ? location : pu_location])} with{' '}
                                    {
                                        LOCATION_TYPES[
                                            order[order.order_type === 'return' ? location_type : pu_location_type]
                                        ]
                                    }
                                </Text>
                                <Text style={{ ...fragments.body1, marginTop: '0.125in', marginBottom: '0.03125in' }}>
                                    {order.order_type === 'return' ? 'Pickup' : 'Delivery'} Instructions/Comments
                                </Text>
                                <Text style={fragments.body2}>
                                    {!!orderNotes?.warehouse?.length
                                        ? orderNotes?.warehouse.map((n) => n.note.trim()).join(' / ')
                                        : 'None'}
                                </Text>

                                <Text style={{ ...fragments.body1, marginTop: '0.1in', marginBottom: '0.03125in' }}>
                                    Drop off
                                </Text>
                                <Text style={fragments.body2}>{order.dropoff_name}</Text>
                            </View>
                        </View>
                    ) : (
                        <View style={{ ...fragments.row, marginBottom: '0.25in' }}>
                            <View style={{ ...fragments.col, width: '50%' }}>
                                {/* pickup */}
                                <Text style={{ ...fragments.body1, marginBottom: '0.03125in' }}>Warehouse</Text>
                                <Text style={fragments.body2}>Carrier: {order.order_carrier?.business_name}</Text>
                                <Text style={fragments.body2}>{order.pickup_name}</Text>
                                <Text style={fragments.body2}>
                                    {[order[pu_street], order[pu_unit]].filter((x) => !!x).join(', ')}
                                </Text>
                                <Text style={fragments.body2}>
                                    {[order[pu_city], order[pu_state], order[pu_zip]].filter((x) => !!x).join(', ')}
                                </Text>
                                <Text style={fragments.body2}>{toNational(order.pickup_phone)}</Text>
                                <Text style={fragments.body2}>{order.pickup_email}</Text>
                                <Text style={fragments.body2}>
                                    Location Type: {startCase(order[pu_location])} with{' '}
                                    {LOCATION_TYPES[order[pu_location_type]]}
                                </Text>
                                <Text style={{ ...fragments.body1, marginTop: '0.125in', marginBottom: '0.03125in' }}>
                                    {order.order_type === 'return' ? 'Return' : 'Pickup'} Instructions/Comments
                                </Text>
                                <Text style={fragments.body2}>
                                    {!!orderNotes?.pickup?.length
                                        ? orderNotes?.pickup.map((n) => n.note.trim()).join(' / ')
                                        : 'None'}
                                </Text>
                            </View>
                            <View style={{ ...fragments.col, width: '50%' }}>
                                {/* dropoff */}
                                <Text style={{ ...fragments.body1, marginBottom: '0.03125in' }}>
                                    {order.order_type === 'return' ? 'Pickup' : 'Delivery'}
                                </Text>
                                <Text style={fragments.body2}>{order.dropoff_name}</Text>
                                <Text style={fragments.body2}>
                                    {[order[street], order[unit]].filter((x) => !!x).join(', ')}
                                </Text>
                                <Text style={fragments.body2}>
                                    {[order[city], order[state], order[zip]].filter((x) => !!x).join(', ')}
                                </Text>
                                <Text style={fragments.body2}>{toNational(order.dropoff_phone)}</Text>
                                <Text style={fragments.body2}>{order.dropoff_email}</Text>
                                <Text style={fragments.body2}>Location Type: {startCase(order[location])}</Text>
                                <Text style={fragments.body2}>
                                    {order.order_type === 'return' ? 'Pickup' : 'Delivery'} Type:{' '}
                                    {LOCATION_TYPES[order[location_type]]}
                                </Text>

                                <Text style={{ ...fragments.body1, marginTop: '0.125in', marginBottom: '0.03125in' }}>
                                    {order.order_type === 'return' ? 'Pickup' : 'Delivery'} Instructions/Comments
                                </Text>
                                <Text style={fragments.body2}>
                                    {!!orderNotes?.delivery?.length
                                        ? orderNotes?.delivery.map((n) => n.note.trim()).join(' / ')
                                        : 'None'}
                                </Text>
                            </View>
                        </View>
                    )}

                    {items.length > 0 && <Table items={items} />}
                    {returns.length > 0 && <Table items={returns} isReturn={true} />}
                    {order.haulaway_items?.length > 0 && <Table items={order.haulaway_items} isHaulaway={true} />}
                    <View
                        style={{
                            ...fragments.row,
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            marginBottom: '0.125in',
                        }}
                    >
                        <View
                            style={{
                                width: '0.25in',
                                height: '0.25in',
                                border: '1px solid black',
                                marginRight: '0.125in',
                            }}
                        ></View>
                        <Text style={{ ...fragments.body2, marginRight: '0.0625in' }}>Insurance required?</Text>
                        <View style={{ width: '2in', height: '0.25in', borderBottom: '1px solid black' }}></View>
                    </View>
                    <Signature name={'Driver'} />
                    {order.customer_signature ? (
                        <>
                            <View
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                    width: '5in',
                                }}
                            >
                                <Text style={fragments.body2}>Consignee Signature</Text>
                                <Image
                                    style={{ height: '0.25in', maxWidth: '5in', border: '1px solid black' }}
                                    src={order.customer_signature}
                                />
                            </View>
                            <View
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                    width: '2in',
                                }}
                            >
                                <Text style={fragments.body2}>Date</Text>
                                <Text
                                    style={{
                                        ...fragments.body2,
                                        height: '0.25in',
                                        width: '2in',
                                        border: '1px solid black',
                                    }}
                                >
                                    {order.customer_signature_meta?.date
                                        ? formatInTimeZone(
                                              new Date(order.customer_signature_meta.date),
                                              tz,
                                              'MM/dd/yyyy'
                                          )
                                        : 'Not Dated'}
                                </Text>
                            </View>
                        </>
                    ) : (
                        <Signature name={'Consignee'} />
                    )}

                    <View style={{ ...fragments.row, marginBottom: '0.0625in', marginTop: '0.125in' }}>
                        <Text style={{ ...fragments.body2, width: '3in' }}>
                            Consignee acknowledges that produce was received in good condition unless noted below:
                        </Text>
                        {['Item Short', 'Item Damaged', 'Property Damaged'].map((type) => (
                            <View style={{ ...fragments.row, width: '1.5in', justifyContent: 'flex-start' }}>
                                <View
                                    style={{
                                        ...fragments.item,
                                        width: '0.25in',
                                        height: '0.25in',
                                        border: '1px solid black',
                                        marginRight: '0.125in',
                                    }}
                                />
                                <Text style={fragments.body2}>{type}</Text>
                            </View>
                        ))}
                    </View>

                    <View
                        style={{
                            ...fragments.row,
                            marginBottom: '0.125in',
                            alignItems: 'center',
                        }}
                    >
                        <Text style={{ ...fragments.body2, marginRight: '0.125in' }}>
                            Property Damaged Description:
                        </Text>
                        <View style={{ flex: '1', height: '0.25in', borderBottom: '1px solid black' }}></View>
                    </View>

                    <View style={{ ...fragments.row, marginBottom: '0.125in' }}>
                        <Text style={{ ...fragments.text, fontSize: '5px', fontWeight: '500' }}>{finePrint}</Text>
                    </View>

                    <View style={{ ...fragments.row, marginBottom: '0.125in' }}>
                        <ClientSignature name="Shipper" margin="marginRight" />
                        <ClientSignature name="Carrier" margin="marginLeft" />
                    </View>

                    <View
                        style={{
                            ...fragments.row,
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Text
                            style={{
                                ...fragments.text,
                                fontSize: '10px',
                                fontWeight: '500',
                            }}
                        >
                            Powered By{' '}
                        </Text>
                        <Image
                            src="/files/onward-logo.png"
                            style={{
                                height: '10px',
                                width: 'auto',
                                objectFit: 'contain',
                            }}
                        />
                    </View>
                </View>
            </Page>
        </Document>
    );
};

export default DocumentBOL;
