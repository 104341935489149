import React, { useRef, useState, useEffect, useMemo } from 'react';
import { Grid, Button } from '@material-ui/core';
import { useMutation } from '@apollo/client';
import { UPDATE_PROFILE_IMAGE, UPDATE_WHITE_LABEL_IMAGE } from './mutations';
import * as Sentry from '@sentry/react';
import { css } from '@emotion/react';
import { Card } from 'react-bootstrap';
import { LocationPermissionsTitle, LocationPermissionsTitleSpan, LocationPermissionsText, CardSubtext } from './blocks';
import uploadPhotoAsync from '@/utilities/uploadPhotoAsync';
import { useClientUser } from '@/hooks';
import { capitalize } from 'lodash';
import { onwardNavy } from '@/styles/colors';
import { PrimaryButton, WarningButton } from '@/styles/blocks';
import Cropper from 'react-easy-crop';
import { getCroppedImg, readFile } from './profileImageUtils';
import { ModalTitle, ResponsiveSidebarDialog, StickyModalActions, ModalContent } from '@/styles/blocks';
import Snackbar from '../Snackbar';

const ProfileImageUpload = ({
    client,
    partnership,
    partnershipProfileImageVariables,
    setPartnershipProfileImageVariables,
    whiteLabel,
}) => {
    const [uploading, setUploading] = useState(false);
    const [previewOpened, setPreviewOpened] = useState(false);
    const [color, setColor] = useState(client?.profile_img_color || null);
    const [whiteLabelColor, setWhiteLabelColor] = useState(client?.white_label_color || null);
    let profileImageRef = useRef(null);
    let colorPickerRef = useRef(null);
    const { accountType } = useClientUser();
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [uncroppedImg, setUncroppedImg] = useState(null);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');

    const onCropComplete = (croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    };

    useEffect(() => {
        if (client?.profile_img_color || partnership?.profile_img_color) {
            setColor(client?.profile_img_color || partnership?.profile_img_color);
        }
        if (client?.white_label_color) {
            setWhiteLabelColor(client?.white_label_color);
        }
    }, [client, partnership]);

    const shownPartnershipLogoInfo = useMemo(() => {
        if (partnershipProfileImageVariables) {
            return partnershipProfileImageVariables;
        } else
            return {
                profile_img: partnership?.profile_img,
                profile_img_color: partnership?.profile_img_color,
            };
    }, [partnershipProfileImageVariables, partnership]);

    const [updateProfileImage, { loading: updateImageLoading }] = useMutation(UPDATE_PROFILE_IMAGE, {
        onError: (error) => {
            console.log(error);
            Sentry.captureException(error);
        },
    });
    const [updateWhiteLabelImage, { loading: updateWhiteLabelLoading }] = useMutation(UPDATE_WHITE_LABEL_IMAGE, {
        onError: (error) => {
            console.log(error);
            Sentry.captureException(error);
        },
    });

    const setBackgroundColor = (color) => {
        if (partnership?.partnership_id) {
            setPartnershipProfileImageVariables((prev) => ({
                ...prev,
                profile_img_color: color,
            }));
        } else {
            setColor(color);
        }
    };

    const setWhiteLabelBackgroundColor = (color) => {
        if (partnership?.partnership_id) {
            setPartnershipProfileImageVariables((prev) => ({
                ...prev,
                profile_img_color: color,
            }));
        } else {
            setWhiteLabelColor(color);
        }
    };

    const uploadFile = async (e) => {
        setUploading(true);

        const file = e.target.files[0];
        const fileType = file.type.split('/')[1];

        let fileObject = await URL.createObjectURL(file);
        let fbFile = await uploadPhotoAsync(fileObject, accountType, fileType);

        const variables = {
            profile_img: fbFile,
            profile_img_color: null,
        };

        if (partnership?.partnership_id) {
            setPartnershipProfileImageVariables(variables);
        } else {
            await updateProfileImage({
                variables: {
                    client_id: client.client_id,
                    _set: variables,
                },
            });
        }

        e.target.value = '';
        setUploading(false);
        setPreviewOpened(false);
        setColor(null);
    };

    const deleteImage = async (e) => {
        setPreviewOpened(false);

        const variables = {
            profile_img: null,
            profile_img_color: null,
        };

        if (partnership?.partnership_id) {
            setPartnershipProfileImageVariables(variables);
        } else {
            await updateProfileImage({
                variables: {
                    client_id: client.client_id,
                    _set: variables,
                },
            });
        }
    };

    const deleteWhiteLabel = async (e) => {
        const variables = {
            white_label_img: null,
            white_label_color: null,
        };

        await updateWhiteLabelImage({
            variables: {
                client_id: client.client_id,
                _set: variables,
            },
        });
    }

    const saveBackgroundColor = async (e) => {
        const variables = {
            profile_img_color: shownPartnershipLogoInfo.profile_img_color || color,
        };

        if (partnership?.partnership_id) {
            setPartnershipProfileImageVariables((prev) => ({
                ...prev,
                ...variables,
            }));
        } else {
            await updateProfileImage({
                variables: {
                    client_id: client.client_id,
                    _set: variables,
                },
            });
        }

        setSuccessMessage('Updated successfully');
    };

    const saveWhiteLabelColor = async (e) => {
        const variables = {
            white_label_color: whiteLabelColor,
        };

        await updateWhiteLabelImage({
            variables: {
                client_id: client.client_id,
                _set: variables,
            },
        });

        setSuccessMessage('Updated successfully');
    };

    const editWhiteLabelImg = async (e) => {
        const file = e.target.files[0];
        let imageDataUrl = await readFile(file);

        setUncroppedImg(imageDataUrl);
    };

    const saveCrop = async () => {
        try {
            const croppedImage = await getCroppedImg(uncroppedImg, croppedAreaPixels, 0);
            let fbFile = await uploadPhotoAsync(croppedImage, accountType, 'png');

            const variables = {
                white_label_img: fbFile,
                white_label_color: null,
            };

            await updateWhiteLabelImage({
                variables: {
                    client_id: client.client_id,
                    _set: variables,
                },
            });

            setUploading(false);
            setWhiteLabelBackgroundColor(null);
            setUncroppedImg(null);
            setSuccessMessage('Updated successfully');
        } catch (e) {
            console.error(e);
            Sentry.captureException(e);
        }
    };

    let uploadText;
    if (partnership?.partnership_id) {
        uploadText = 'Upload Logo';
    } else if (whiteLabel) {
        uploadText = 'Upload Internal Logo';
    } else {
        uploadText = `Upload ${capitalize(accountType)} Logo`;
    }

    return (
        <>
            <Card
                css={css`
                    margin-top: 30px;
                `}
            >
                <Card.Body
                    css={css`
                        padding: 28px 26px 28px 26px;
                    `}
                >
                    <LocationPermissionsTitle>
                        <LocationPermissionsTitleSpan>
                            {whiteLabel ? 'White Label Logo (Internally Facing)' : 'Company Logo (Customer Facing)'}
                        </LocationPermissionsTitleSpan>
                    </LocationPermissionsTitle>
                    <Grid container direction="column">
                        <Grid
                            item
                            css={css`
                                margin-bottom: 15px;
                            `}
                        >
                            <LocationPermissionsText>{uploadText}</LocationPermissionsText>
                            <CardSubtext>
                                {whiteLabel
                                    ? `
                                This image will display sitewide on the primary sidebar in place of the Onward logo for you and your teammate accounts.
                                `
                                    : `
                                    This image will display sitewide on customer-facing pages, such as the tracking link
                                they are sent to track their delivery progress. The image will be resized to a height of
                                50 pixels.
                                `}
                            </CardSubtext>
                        </Grid>

                        {whiteLabel
                            ? client?.white_label_img && (
                                  <>
                                      {/* <LocationPermissionsText
                                        css={css`
                                            margin-top: 15px;
                                            margin-bottom: 15px;
                                        `}
                                    >
                                        Label Preview
                                    </LocationPermissionsText> */}
                                      <Grid>
                                          <div
                                              css={css`
                                                  margin-bottom: 15px;
                                                  display: flex;
                                                  justify-content: center;
                                                  background-color: ${whiteLabelColor || onwardNavy};
                                                  width: 221px;
                                              `}
                                          >
                                              <img
                                                  css={css`
                                                      width: 151px;
                                                      height: 50px;
                                                      object-fit: cover;
                                                  `}
                                                  src={client?.white_label_img}
                                              />
                                          </div>
                                      </Grid>
                                  </>
                              )
                            : (client?.profile_img || shownPartnershipLogoInfo?.profile_img) && (
                                  <>
                                      {/* <LocationPermissionsText
                                        css={css`
                                            margin-top: 15px;
                                            margin-bottom: 15px;
                                        `}
                                    >
                                        Banner Preview
                                    </LocationPermissionsText> */}
                                      <Grid>
                                          <div
                                              css={css`
                                                  margin-bottom: 15px;
                                                  display: flex;
                                                  justify-content: center;
                                                  background-color: ${shownPartnershipLogoInfo?.profile_img_color ||
                                                  color ||
                                                  onwardNavy};
                                              `}
                                          >
                                              <img
                                                  css={css`
                                                      height: 50px;
                                                      object-fit: cover;
                                                  `}
                                                  src={client?.profile_img || shownPartnershipLogoInfo?.profile_img}
                                              />
                                          </div>
                                      </Grid>
                                  </>
                              )}

                        <Grid item container direction="row" justifyContent="flex-start">
                            <Grid item>
                                <PrimaryButton onClick={() => profileImageRef.current.click()} disabled={uploading}>
                                    {whiteLabel
                                        ? client?.white_label_img
                                            ? 'Replace'
                                            : 'Upload'
                                        : client?.profile_img || shownPartnershipLogoInfo?.profile_img
                                        ? 'Replace'
                                        : 'Upload'}
                                </PrimaryButton>
                                <input
                                    type="file"
                                    accept=".png,.jpg,.jpeg"
                                    ref={profileImageRef}
                                    name="profileImage"
                                    onChange={whiteLabel ? editWhiteLabelImg : uploadFile}
                                    css={css`
                                        display: none;
                                    `}
                                />
                            </Grid>

                            {whiteLabel
                                ? client?.white_label_img && (
                                      <Grid
                                          item
                                          css={css`
                                              margin-left: 1rem;
                                          `}
                                      >
                                          <WarningButton onClick={deleteWhiteLabel}>Remove Logo</WarningButton>
                                      </Grid>
                                  )
                                : (client?.profile_img || shownPartnershipLogoInfo?.profile_img) && (
                                      <Grid
                                          item
                                          css={css`
                                              margin-left: 1rem;
                                          `}
                                      >
                                          <WarningButton onClick={deleteImage}>Remove Image</WarningButton>
                                      </Grid>
                                  )}
                        </Grid>

                        {whiteLabel
                            ? client?.white_label_img && (
                                  <>
                                      <LocationPermissionsText
                                          css={css`
                                              margin-top: 30px;
                                          `}
                                      >
                                          Select Primary Sidebar Background Color
                                      </LocationPermissionsText>

                                      <Grid item container direction="row" alignItems="center">
                                          <Grid
                                              item
                                              css={css`
                                                  display: flex;
                                              `}
                                          >
                                              <input
                                                  type="color"
                                                  value={whiteLabelColor || '#0c0b1d'}
                                                  ref={colorPickerRef}
                                                  css={css`
                                                      height: 52px;
                                                      width: 52px;
                                                  `}
                                                  onChange={(e) => setWhiteLabelBackgroundColor(e.target.value)}
                                              />
                                          </Grid>
                                          <Grid
                                              item
                                              css={css`
                                                  margin-left: 25px;
                                              `}
                                          >
                                              <PrimaryButton 
                                              onClick={saveWhiteLabelColor}
                                              disabled={updateWhiteLabelLoading || updateImageLoading}
                                              >Save</PrimaryButton>
                                          </Grid>
                                      </Grid>
                                  </>
                              )
                            : (client?.profile_img || shownPartnershipLogoInfo?.profile_img) && (
                                  <>
                                      <LocationPermissionsText
                                          css={css`
                                              margin-top: 30px;
                                          `}
                                      >
                                          Select Banner Background Color
                                      </LocationPermissionsText>

                                      <Grid item container direction="row" alignItems="center">
                                          <Grid
                                              item
                                              css={css`
                                                  display: flex;
                                              `}
                                          >
                                              <input
                                                  type="color"
                                                  value={color || '#0c0b1d'}
                                                  ref={colorPickerRef}
                                                  css={css`
                                                      height: 52px;
                                                      width: 52px;
                                                  `}
                                                  onChange={(e) => setBackgroundColor(e.target.value)}
                                              />
                                          </Grid>
                                          <Grid
                                              item
                                              css={css`
                                                  margin-left: 25px;
                                              `}
                                          >
                                              <PrimaryButton onClick={saveBackgroundColor} disabled={updateWhiteLabelLoading || updateImageLoading}>Save</PrimaryButton>
                                          </Grid>
                                      </Grid>
                                  </>
                              )}
                    </Grid>
                </Card.Body>
            </Card>

            <ResponsiveSidebarDialog open={uncroppedImg} onClose={() => setUncroppedImg(null)}>
                <ModalTitle title={'Edit Logo'} onClose={() => setUncroppedImg(null)}></ModalTitle>
                <ModalContent width={500}>
                    <div
                        css={css`
                            height: 500px;
                            position: relative;
                        `}
                    >
                        <Cropper
                            image={uncroppedImg}
                            crop={crop}
                            zoom={zoom}
                            aspect={151 / 50}
                            onCropChange={setCrop}
                            onCropComplete={onCropComplete}
                            onZoomChange={setZoom}
                        />
                    </div>

                    <div
                        css={css`
                            width: 100%;
                            display: flex;
                            justify-content: center;
                            margin-top: 10px;
                        `}
                    >
                        <input
                            type="range"
                            value={zoom}
                            min={1}
                            max={3}
                            step={0.1}
                            aria-labelledby="Zoom"
                            onChange={(e) => {
                                setZoom(e.target.value);
                            }}
                        />
                    </div>
                </ModalContent>
                <StickyModalActions>
                    <Grid
                        container
                        direction="column"
                        css={css`
                            flex: 0;
                        `}
                    ></Grid>
                    <Grid
                        container
                        direction="column"
                        css={css`
                            flex: 0;
                        `}
                        onClick={() => saveCrop()}
                    >
                        <PrimaryButton>Save</PrimaryButton>
                    </Grid>
                </StickyModalActions>
            </ResponsiveSidebarDialog>
            <Snackbar
                open={!!successMessage}
                handleClose={() => setSuccessMessage('')}
                severity="success"
                message={successMessage}
            />
        </>
    );
};

export default ProfileImageUpload;
