import React from 'react';
import { Grid } from '@material-ui/core';
import { css } from '@emotion/react';
import { PrimaryButton } from '@/styles/blocks';
import { TabRow, TabSection } from '../../../blocks';
import { OnwardTextField } from '../../InputFields';

const HaulAwayItem = ({ order, item, index, callbacks }) => {
    return (
        <>
            <TabSection>Haulaway Item {index + 1}</TabSection>
            <TabRow
                css={css`
                    margin-bottom: 12px;
                    align-items: center;
                    justify-content: flex-end;
                `}
            >
                <Grid item>
                    <PrimaryButton red onClick={() => callbacks.deleteItem(index, { type: 'haulaway' })}>
                        Delete Haulaway
                    </PrimaryButton>
                </Grid>
            </TabRow>
            <TabRow>
                <Grid item xs={2}>
                    <OnwardTextField
                        label="Quantity"
                        variant="outlined"
                        name="qty"
                        fullWidth
                        type="number"
                        value={item.quantity}
                        onChange={(e) =>
                            callbacks.editItem(index, { quantity: parseInt(e.target.value) }, { type: 'haulaway' })
                        }
                    />
                </Grid>
                <Grid item xs={2}>
                    <OnwardTextField
                        label="Length (in)"
                        variant="outlined"
                        type="number"
                        fullWidth
                        value={item.length}
                        onChange={(e) =>
                            callbacks.editItem(index, { length: parseInt(e.target.value, 10) }, { type: 'haulaway' })
                        }
                        onWheel={ event => event.target.blur() }
                    />
                </Grid>
                <Grid item xs={2}>
                    <OnwardTextField
                        label="Width (in)"
                        variant="outlined"
                        type="number"
                        fullWidth
                        value={item.width}
                        onChange={(e) =>
                            callbacks.editItem(index, { width: parseInt(e.target.value, 10) }, { type: 'haulaway' })
                        }
                        onWheel={ event => event.target.blur() }
                    />
                </Grid>
                <Grid item xs={2}>
                    <OnwardTextField
                        label="Height (in)"
                        variant="outlined"
                        type="number"
                        fullWidth
                        value={item.height}
                        onChange={(e) =>
                            callbacks.editItem(index, { height: parseInt(e.target.value, 10) }, { type: 'haulaway' })
                        }
                        onWheel={ event => event.target.blur() }
                    />
                </Grid>
                <Grid item xs={2}>
                    <OnwardTextField
                        label="Cubic Feet"
                        variant="outlined"
                        type="number"
                        fullWidth
                        value={item.cubes_per_unit}
                        onChange={(e) => {
                            const cubes = parseInt(e.target.value) || 0;
                            const dims = Math.cbrt(cubes * 1728);
                            callbacks.editItem(
                                index,
                                { cubes_per_unit: cubes, length: dims, width: dims, height: dims },
                                { type: 'haulaway' }
                            );
                        }}
                        onWheel={ event => event.target.blur() }
                    />
                </Grid>
                <Grid item xs={2}>
                    <OnwardTextField
                        label="Weight"
                        variant="outlined"
                        type="number"
                        fullWidth
                        value={item.weight}
                        onChange={(e) =>
                            callbacks.editItem(index, { weight: parseInt(e.target.value, 10) }, { type: 'haulaway' })
                        }
                        onWheel={ event => event.target.blur() }
                    />
                </Grid>
            </TabRow>

            <TabRow>
                <Grid item xs={2}>
                    <OnwardTextField
                        label="SKU"
                        variant="outlined"
                        name="sku"
                        fullWidth
                        type="number"
                        value={item.sku}
                        onChange={(e) => callbacks.editItem(index, { sku: e.target.value }, { type: 'haulaway' })}
                    />
                </Grid>
                <Grid item xs={10}>
                    <OnwardTextField
                        label="Description"
                        variant="outlined"
                        name="desc"
                        fullWidth
                        value={item.description}
                        onChange={(e) =>
                            callbacks.editItem(index, { description: e.target.value }, { type: 'haulaway' })
                        }
                    />
                </Grid>
            </TabRow>
        </>
    );
};
export default HaulAwayItem;
