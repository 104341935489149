import { OnwardTooltip, PrimaryButton, SecondaryButton } from '@/styles/blocks';
import { Grid, IconButton, MenuItem, TextField, Tooltip, Button } from '@material-ui/core';
import { CheckCircleOutline, InfoOutlined } from '@material-ui/icons';
import { indexOf } from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import NavResponsiveModal, { ModalActions, ModalContent, ModalHeader } from '../../Navigation/NavResponsiveModal';
import { Context } from '../store';
import { css } from '@emotion/react';
import { formatInTimeZone } from 'date-fns-tz';
import { asDateInTZ } from '@/utilities/convertToISO';
import SearchInput from '../../misc/SearchInput';
import { colors } from '@/styles';
import { Alert, AlertTitle } from '@material-ui/lab';
import { MANIFEST_TYPE_LABELS, MANIFEST_TYPE_TOOLTIPS } from '@/constants/manifestStatuses';
import Table from './Table';
import { useNavigate } from 'react-router-dom';

const STAGES = {
    DETAILS: 'DETAILS',
    ITEMS: 'ITEMS',
};

const PROGRESSION = [STAGES.DETAILS, STAGES.ITEMS];

const CreateManifest = () => {
    const { state, loading, callbacks } = useContext(Context);
    const [stage, setStage] = useState(PROGRESSION[0]);
    const [dirty, setDirty] = useState({});
    const navigate = useNavigate();

    const makeDirty = (patch) => setDirty((prev) => ({ ...prev, ...patch }));

    useEffect(() => {
        if (state?.newManifest?.isDraft) {
            setStage(PROGRESSION[1]);
        }
    }, [state.newManifest]);

    const isValid = useMemo(() => {
        switch (stage) {
            case STAGES.DETAILS: {
                return ['receiving_date', 'warehouse_id', 'type'].every((attr) => state.newManifest?.[attr]);
            }
            case STAGES.ITEMS: {
                const hasSelectedItems = Object.values(state.newManifest?.items || {}).some((x) => x);

                if (state.newManifest?.isDraft && state.newManifest?.draft_items?.length > 0) {
                    const allDraftItemsMatched = Object.values(state.matchedDraftItems).every((matched) => matched);
                    return hasSelectedItems && allDraftItemsMatched;
                }

                return hasSelectedItems;
            }
            default: {
                return false;
            }
        }
    }, [stage, state.newManifest, state.matchedDraftItems]);

    let content;
    switch (stage) {
        case STAGES.DETAILS: {
            content = (
                <Grid
                    container
                    css={css`
                        flex-direction: column;
                        flex-wrap: nowrap;
                        height: 100%;
                    `}
                >
                    {/* <TextField
                        variant="outlined"
                        label="Origin"
                        name="origin"
                        fullWidth
                        value={state.newManifest?.origin || ''}
                        onChange={(e) => callbacks.setNewManifest((prev) => ({ ...prev, origin: e.target.value }))}
                        onBlur={() => makeDirty({ origin: true })}
                    />
                    <TextField
                        variant="outlined"
                        label="Destination"
                        name="origin"
                        fullWidth
                        value={state.newManifest?.destination || ''}
                        onChange={(e) => callbacks.setNewManifest((prev) => ({ ...prev, destination: e.target.value }))}
                        onBlur={() => makeDirty({ destination: true })}
                        css={css`
                            margin-top: 1rem;
                        `}
                    /> */}
                    <TextField
                        type="date"
                        variant="outlined"
                        required
                        label={state.tab.value === 'INBOUND' ? 'Receiving Date' : 'Staging Date'}
                        name="receiving_date"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        value={
                            state.newManifest?.receiving_date
                                ? formatInTimeZone(new Date(state.newManifest.receiving_date), 'UTC', 'yyyy-MM-dd')
                                : '--'
                        }
                        onChange={(e) =>
                            callbacks.setNewManifest((prev) => ({
                                ...prev,
                                receiving_date: asDateInTZ(e.target.value, 'UTC').toISOString(),
                            }))
                        }
                        onBlur={() => makeDirty({ receiving_date: true })}
                        css={css`
                            margin-top: 1rem;
                        `}
                        error={dirty.receiving_date && !state.newManifest?.receiving_date}
                    />
                    {state.warehouses.length > 0 ? (
                        <TextField
                            variant="outlined"
                            required
                            select
                            label="Warehouse"
                            name="warehouse_id"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            value={state.newManifest?.warehouse_id || ''}
                            onChange={(e) =>
                                callbacks.setNewManifest((prev) => ({ ...prev, warehouse_id: e.target.value }))
                            }
                            onClick={() => makeDirty({ warehouse_id: true })}
                            error={dirty.warehouse_id && !state.newManifest?.warehouse_id}
                            css={css`
                                margin-top: 1rem;
                            `}
                        >
                            {state.warehouses.map((warehouse) => (
                                <MenuItem key={warehouse.location_id} value={warehouse.location_id}>
                                    {warehouse.location_name}
                                </MenuItem>
                            ))}
                        </TextField>
                    ) : (
                        <Alert
                            severity="warning"
                            css={css`
                                margin-top: 1rem;
                            `}
                        >
                            <AlertTitle>No Warehouse(s)</AlertTitle>Please add a new warehouse to get started
                        </Alert>
                    )}
                    {state.tab.typeOptions.length > 1 ? (
                        <TextField
                            variant="outlined"
                            required
                            select
                            label="Type"
                            name="type"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            value={state.newManifest?.type || ''}
                            onChange={(e) => {
                                callbacks.setNewManifest((prev) => ({ ...prev, type: e.target.value }));
                                callbacks.setNewManifestType(e.target.value);
                            }}
                            onClick={() => makeDirty({ type: true })}
                            error={dirty.type && !state.newManifest?.type}
                            css={css`
                                margin-top: 1rem;
                            `}
                        >
                            {state.tab.typeOptions.map((type) => (
                                <MenuItem key={type} value={type}>
                                    {[MANIFEST_TYPE_LABELS[type], MANIFEST_TYPE_TOOLTIPS[type]]
                                        .filter((x) => !!x)
                                        .join(' ')}
                                </MenuItem>
                            ))}
                        </TextField>
                    ) : null}
                    <>
                        <Grid container alignItems="center" spacing={1}>
                            <Grid item xs={11}>
                                <TextField
                                    variant="outlined"
                                    select
                                    label="Share Manifest"
                                    name="share"
                                    defaultValue={false}
                                    value={state.newManifest?.share_manifest}
                                    onChange={(e) =>
                                        callbacks.setNewManifest((prev) => ({
                                            ...prev,
                                            share_manifest: e.target.value,
                                        }))
                                    }
                                    InputLabelProps={{ shrink: true }}
                                    css={css`
                                        margin-top: 1rem;
                                        width: 100%;
                                    `}
                                >
                                    <MenuItem value={true}>Yes</MenuItem>
                                    <MenuItem value={false}>No</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid
                                item
                                xs={1}
                                css={css`
                                    margin-top: 1rem;
                                `}
                            >
                                <Tooltip
                                    style={{ color: '#59B863' }}
                                    title={
                                        'Optionally choose to share the status of this manifest with the shippers of the included orders. They will only be able to see details for their own orders/items.'
                                    }
                                    placement="top"
                                >
                                    <IconButton size="small">
                                        <InfoOutlined />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </>
                </Grid>
            );
            break;
        }
        case STAGES.ITEMS: {
            content = (
                <div
                    css={css`
                        width: 100%;
                        height: ${state?.newManifest?.isDraft ? '1000px' : '600px'};
                        overflow-y: hidden;
                    `}
                >
                    <Grid
                        container
                        direction="column"
                        css={css`
                            height: 100%;
                            flex-wrap: nowrap;
                        `}
                    >
                        {state?.newManifest?.isDraft && (
                            <Grid
                                container
                                alignItems="center"
                                css={css`
                                    gap: 1rem;
                                    padding: 0.25rem 0;
                                    font-size: 0.875rem;
                                    color: inherit;
                                `}
                            >
                                <Grid item>Reference Id: {state?.newManifest?.reference_id || 'N/A'}</Grid>
                            </Grid>
                        )}
                        {state?.newManifest?.draft_items?.map((item, index) => (
                            <Grid
                                key={index}
                                container
                                spacing={2}
                                alignItems="center"
                                css={css`
                                    padding: 0.25rem 0;
                                    font-size: 0.875rem;
                                    color: ${state.matchedDraftItems[index] ? colors.greens.primary : 'inherit'};
                                `}
                            >
                                <Grid item xs={5}>
                                    <div>SKU: {item.sku}</div>
                                </Grid>
                                <Grid item xs={5}>
                                    <div>Requested Quantity: {item.quantity}</div>
                                </Grid>
                                {state.matchedDraftItems[index] && (
                                    <Grid
                                        item
                                        css={css`
                                            color: ${colors.greens.primary};
                                            display: flex;
                                            align-items: center;
                                        `}
                                    >
                                        <CheckCircleOutline fontSize="small" />
                                    </Grid>
                                )}
                            </Grid>
                        ))}
                        <Grid
                            item
                            css={css`
                                padding-bottom: 0.5rem;
                                padding-top: 0.5rem;
                            `}
                        >
                            <SearchInput
                                placeholder="Search Orders"
                                onChange={(search) => callbacks.setSearchOrders(search)}
                                fullWidth
                                css={css`
                                    .MuiInputBase-root {
                                        padding-top: 3px;
                                        padding-bottom: 3px;
                                    }
                                `}
                            />
                        </Grid>

                        <Grid
                            css={css`
                                overflow: hidden;
                                flex-grow: 1;
                                min-height: 300px;
                                max-height: 500px;
                            `}
                        >
                            <Table
                                state={state}
                                loading={loading}
                                callbacks={callbacks}
                                matchedDraftItems={state.matchedDraftItems}
                            />
                        </Grid>
                    </Grid>
                </div>
            );
            break;
        }
    }

    const isLast = indexOf(PROGRESSION, stage) === PROGRESSION.length - 1;
    const isFirst = indexOf(PROGRESSION, stage) === 0;

    const onClose = () => {
        setStage(PROGRESSION[0]);
        setDirty({});
        callbacks.setNewManifest(null);
        callbacks.setShowCreateManifestModal(false);
    };

    const advance = () => {
        if (isLast) {
            callbacks.createManifest().then((result) => {
                const manifestId = result?.data?.created?.returning[0]?.manifest_id;
                setStage(PROGRESSION[0]);
                setDirty({});
                callbacks.setNewManifest(null);
                if (manifestId) {
                    navigate(`/manifests/${manifestId}`);
                }
            });
        } else {
            setStage((prev) => PROGRESSION[indexOf(PROGRESSION, prev) + 1]);
        }
    };

    const revert = () => {
        if (isFirst) {
            callbacks.setNewManifest(null);
        } else {
            setStage((prev) => PROGRESSION[indexOf(PROGRESSION, prev) - 1]);
        }
    };

    return (
        <NavResponsiveModal maxWidth="lg" open={!!state.showCreateManifestModal} onClose={onClose}>
            <ModalHeader
                css={css`
                    width: 700px;
                `}
                title={'Create Manifest'}
                onClose={onClose}
            />
            <ModalContent
                css={css`
                    width: 700px;
                    padding-top: 8px;
                `}
            >
                {content}
            </ModalContent>
            <ModalActions>
                <SecondaryButton disabled={loading.createLoading} onClick={revert}>
                    {isFirst ? 'Cancel' : 'Prev'}
                </SecondaryButton>
                <PrimaryButton disabled={loading.createLoading || !isValid} onClick={advance}>
                    {isLast ? 'Create' : 'Next'}
                </PrimaryButton>
            </ModalActions>
        </NavResponsiveModal>
    );
};

export default CreateManifest;
