import { cloneDeep } from 'lodash';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import hash from 'object-hash';
import * as Sentry from '@sentry/react';
import { toArrayLiteral } from './toArrayLiteral';
import { get, post } from './onwardClient';
import { OPTIMIZATION_RESULT, START_OPTIMIZATION } from '@/constants/apiRoutes';
import handleGeocode from '@/utilities/handleGeocode';

const isSameLocation = (a, b, options = {}) => {
    // Don't merge with already completed stops
    if (a.stop_completion_time || b.stop_completion_time) {
        return false;
    }

    // Container loads never merge with other stops
    if (options.isContainer) {
        return false;
    }

    // Merge warehouse stops on coordinates in case of address formatting differences
    if (options.isWarehouse) {
        // good enough, don't need to do haversine
        const euclidean = Math.sqrt(Math.pow(Math.abs(a.lat - b.lat), 2) + Math.pow(Math.abs(a.lng - b.lng), 2));
        return euclidean <= 0.0001;
    }

    // Merge customer stops on address in case of address line 2 differences
    if (a.address && b.address) {
        return a.address === b.address;
    } else {
        return a.lat === b.lat && a.lng === b.lng;
    }
};

const _formatDuration = (seconds) => {
    const hours = seconds / 3600;

    const flooredHours = Math.floor(hours);
    const flooredSeconds = flooredHours * 3600;

    const minutes = (seconds - flooredSeconds) / 60;
    const ceiledMinutes = Math.ceil(minutes);

    const hourLabel = flooredHours > 1 ? 'hours' : 'hour';
    const minsLabel = ceiledMinutes > 1 ? 'mins' : 'min';

    if (flooredHours === 0) {
        return `${ceiledMinutes} ${minsLabel}`;
    }

    if (ceiledMinutes === 0) {
        return `${flooredHours} ${hourLabel}`;
    }

    return `${flooredHours} ${hourLabel} ${ceiledMinutes} ${minsLabel}`;
};

const _formatDistance = (meters) => {
    const miles = meters / 1609.34;

    return `${Math.floor(miles)} mi`;
};

const _getGMapsParams = (stopSequence) => {
    const first = stopSequence[0];
    const origin = { lat: first.lat, lng: first.lng };

    const last = stopSequence[stopSequence.length - 1];
    const destination = { lat: last.lat, lng: last.lng };

    const waypoints = stopSequence.slice(1, -1).reduce((acc, stop) => {
        return [
            ...acc,
            {
                location: { lat: stop.lat, lng: stop.lng },
                stopover: true,
            },
        ];
    }, []);

    return {
        origin,
        destination,
        waypoints,
    };
};

const addOrUpdateFinalDropoffStop = (order, finalDropoffLocation, stops) => {
    const isContainer = order.freight_type === 'containers';
    const newStops = cloneDeep(stops);
    const finalDropoff = newStops.find(
        (stop) =>
            stop.type === 'DROPOFF' &&
            !stop.end &&
            isSameLocation(stop, finalDropoffLocation, { isWarehouse: true, isContainer })
    );

    // Add or update Final Dropoff stop
    if (finalDropoff) {
        finalDropoff.orders = finalDropoff.orders?.length ? [...finalDropoff.orders, order.order_id] : [order.order_id];
        finalDropoff.returns = finalDropoff.returns?.length
            ? [...finalDropoff.returns, order.order_id]
            : [order.order_id];
    } else {
        newStops.push({
            ...finalDropoffLocation,
            orders: [order.order_id],
            returns: [order.order_id],
            ordering: stops.length,
            type: 'DROPOFF',
        });
    }

    return newStops;
};

const moveStartEndStops = (stops) => {
    const newStops = cloneDeep(stops);

    // Move end stops to end of sequence
    const endStops = newStops.filter((s) => s.end);
    for (const endStop of endStops) {
        const ordering = endStop.ordering;

        newStops.forEach((stop) => {
            if (stop.ordering > ordering) {
                stop.ordering--;
            } else if (stop.ordering === ordering) {
                stop.ordering = stops.length - 1;
            }
        });
    }

    // Move start stops to start of sequence
    const startStops = newStops.filter((s) => s.start);
    for (const startStop of startStops) {
        const ordering = startStop.ordering;

        newStops.forEach((stop) => {
            if (stop.ordering < ordering) {
                stop.ordering++;
            } else if (stop.ordering === ordering) {
                stop.ordering = 0;
            }
        });
    }

    return newStops;
};

const moveFinalDropoffsToEnd = (stops) => {
    const newStops = cloneDeep(stops);

    const finalDropoffs = newStops.filter((stop) => isFinalDropoff(stop)).sort((a, b) => a.ordering - b.ordering);

    if (!finalDropoffs.length) return newStops;

    // move final dropoffs to end of sequence
    for (const finalDropoff of finalDropoffs) {
        let ordering = finalDropoff.ordering;

        newStops.forEach((stop) => {
            if (stop.ordering > ordering) {
                stop.ordering--;
            } else if (stop.ordering === ordering) {
                stop.ordering = newStops.length - 1;
            }
        });
    }

    return newStops;
};

/**
 * Add Return stops to a route.
 *
 * Add/Update pickup stop at customer address. Add order.order_id to orders and returns array
 * Add/Update dropoff stop shipper address. Add order.order_id to orders and returns array
 *
 * @param {} order Return Order
 * @param {*} stops Existing Stops for route
 * @returns { pickingUp, droppingOff } Updated Stops with Return order added
 */
const addReturnStops = ({ order, stops, pickup, dropoff }) => {
    let newStops = cloneDeep(stops);

    const pickupIsWarehouse = order.crossdock_leg === 'dropoff';
    const isContainer = order.freight_type === 'containers';

    // Find existing stops
    const returnPickup = newStops.find(
        (stop) =>
            stop.type === 'PICKUP' &&
            !stop.start &&
            isSameLocation(stop, pickup, { isWarehouse: pickupIsWarehouse, isContainer })
    );
    const finalDropoff = newStops.find(
        (stop) =>
            stop.type === 'DROPOFF' && !stop.end && isSameLocation(stop, dropoff, { isWarehouse: true, isContainer })
    );
    const combinedExchange = newStops.find(
        (stop) =>
            stop.type === 'DROPOFF' &&
            !stop.end &&
            isSameLocation(stop, pickup, { isWarehouse: pickupIsWarehouse, isContainer })
    );

    // Add or update Return Pickup stop
    if (combinedExchange) {
        // Adding return to delivery, combine into a single exchange stop
        combinedExchange.orders = combinedExchange.orders?.length
            ? [...combinedExchange.orders, order.order_id]
            : [order.order_id];
        combinedExchange.exchanges = [...combinedExchange.orders];
    } else if (returnPickup) {
        returnPickup.orders = returnPickup.orders?.length ? [...returnPickup.orders, order.order_id] : [order.order_id];
        returnPickup.returns = returnPickup.returns?.length
            ? [...returnPickup.returns, order.order_id]
            : [order.order_id];
    } else {
        let ordering = newStops.length;
        // If dropoff already exists but pickup does not, insert pickup before dropoff
        if (finalDropoff) {
            ordering = finalDropoff.ordering;
            newStops.forEach((stop) => {
                if (stop.ordering >= ordering) stop.ordering++;
            });
        }
        newStops.push({
            ...pickup,
            orders: [order.order_id],
            returns: [order.order_id],
            ordering,
            type: 'PICKUP',
        });
    }

    newStops = addOrUpdateFinalDropoffStop(order, dropoff, newStops);

    return newStops;
};

/**
 * Add Exchange stops to a route.
 *
 * Add/Update pickup stop at shipper address. Add order.order_id to orders array
 * Add/Update dropoff stop at customer address. Add order.order_id to orders and exchanges array
 * Add/Update final dropoff stop at shipper address. Add order.order_id to orders and returns array
 *
 * @param {} order Exchange Order
 * @param {*} stops Existing Stops for route
 * @returns { pickingUp, droppingOff } Updated Stops with Exchange order added
 */
const addExchangeStops = ({ order, stops, pickup, dropoff }) => {
    let newStops = cloneDeep(stops);

    const dropoffIsWarehouse = order.crossdock_leg === 'pickup';
    const addFinalReturn = order.crossdock_leg !== 'pickup';
    const isContainer = order.freight_type === 'containers';

    const pickupStop = newStops.find(
        (stop) =>
            stop.type === 'PICKUP' && !stop.start && isSameLocation(stop, pickup, { isWarehouse: true, isContainer })
    );
    const dropoffStop = newStops.find(
        (stop) =>
            stop.type === 'DROPOFF' &&
            !stop.end &&
            isSameLocation(stop, dropoff, { isWarehouse: dropoffIsWarehouse, isContainer })
    );

    // Add or update Pickup stop
    if (pickupStop) {
        pickupStop.orders = pickupStop.orders?.length ? [...pickupStop.orders, order.order_id] : [order.order_id];
    } else {
        // If dropoff already exists but pickup does not, insert pickup before dropoff
        let ordering = newStops.length;
        if (dropoffStop) {
            ordering = dropoffStop.ordering;
            newStops.forEach((stop) => {
                if (stop.ordering >= ordering) stop.ordering++;
            });
        }
        newStops.push({
            ...pickup,
            orders: [order.order_id],
            ordering,
            type: 'PICKUP',
        });
    }

    // Add or update Exchange stop
    if (dropoffStop) {
        dropoffStop.orders = dropoffStop.orders?.length ? [...dropoffStop.orders, order.order_id] : [order.order_id];
        dropoffStop.exchanges = dropoffStop.exchanges?.length
            ? [...dropoffStop.exchanges, order.order_id]
            : [order.order_id];
    } else {
        newStops.push({
            ...dropoff,
            orders: [order.order_id],
            exchanges: [order.order_id],
            ordering: newStops.length,
            type: 'DROPOFF',
        });
    }

    if (addFinalReturn) {
        newStops = addOrUpdateFinalDropoffStop(order, pickup, newStops);
    }

    return newStops;
};

const addExceptionReturnStop = ({ order, stops, pickup, dropoff }) => {
    const newStops = addOrUpdateFinalDropoffStop(order, pickup, stops);
    return newStops;
};

const addDeliveryStops = ({ order, stops, pickup, dropoff }) => {
    let newStops = cloneDeep(stops);

    const dropoffIsWarehouse = order.crossdock_leg === 'pickup';
    const isContainer = order.freight_type === 'containers';

    const pickupStop = newStops.find(
        (stop) =>
            stop.type === 'PICKUP' && !stop.start && isSameLocation(stop, pickup, { isWarehouse: true, isContainer })
    );
    const dropoffStop = newStops.find(
        (stop) =>
            stop.type === 'DROPOFF' &&
            !stop.end &&
            isSameLocation(stop, dropoff, { isWarehouse: dropoffIsWarehouse, isContainer })
    );
    const combinedExchangeStop = newStops.find(
        (stop) =>
            stop?.returns?.length && isSameLocation(stop, dropoff, { isWarehouse: dropoffIsWarehouse, isContainer })
    );

    // Add or update Pickup stop
    if (pickupStop) {
        pickupStop.orders = pickupStop.orders?.length ? [...pickupStop.orders, order.order_id] : [order.order_id];
    } else {
        let ordering = newStops.length;
        if (combinedExchangeStop) {
            ordering = combinedExchangeStop.ordering;
            newStops.forEach((stop) => {
                if (stop.ordering >= ordering) stop.ordering++;
            });
        } else if (dropoffStop) {
            ordering = dropoffStop.ordering;
            newStops.forEach((stop) => {
                if (stop.ordering >= ordering) stop.ordering++;
            });
        }
        newStops.push({
            ...pickup,
            orders: [order.order_id],
            ordering: ordering,
            type: 'PICKUP',
        });
    }

    // Add or update Dropoff stop
    if (combinedExchangeStop) {
        // Combine return pickup and delivery into single exchange dropoff
        newStops.splice(
            newStops.findIndex((stop) =>
                isSameLocation(stop, combinedExchangeStop, { isWarehouse: dropoffIsWarehouse, isContainer })
            ),
            1
        );

        const exchangeOrders = combinedExchangeStop?.orders?.length
            ? [...combinedExchangeStop.orders, order.order_id]
            : [order.order_id];

        newStops.push({
            address: combinedExchangeStop.address,
            lat: combinedExchangeStop.lat,
            lng: combinedExchangeStop.lng,
            orders: exchangeOrders,
            exchanges: exchangeOrders,
            ordering: combinedExchangeStop.ordering,
            type: 'DROPOFF',
        });
    } else if (dropoffStop) {
        dropoffStop.orders = dropoffStop.orders?.length ? [...dropoffStop.orders, order.order_id] : [order.order_id];
    } else {
        newStops.push({
            ...dropoff,
            orders: [order.order_id],
            ordering: newStops.length,
            type: 'DROPOFF',
        });
    }

    return newStops;
};

const findStopByAddress = (address, type, stops = {}) => {
    if (type !== 'PICKUP' && type !== 'DROPOFF') {
        throw new Error('type must be pickup or dropoff');
    }

    const { pickingUp = [], droppingOff = [] } = stops;

    if (type === 'PICKUP') {
        return pickingUp.find((stop) => stop.address === address);
    }

    return droppingOff.find((stop) => stop.address === address);
};

const findStopByOrdering = (ordering, stops) => {
    const { pickingUp = [], droppingOff = [] } = stops;

    const pickupMapper = (stop) => {
        const newStop = { ...stop, type: 'PICKUP' };

        if (stop.type === 'start') {
            newStop.start = true;
        }

        return newStop;
    };

    const dropoffMapper = (stop) => {
        const newStop = { ...stop, type: 'DROPOFF' };

        if (stop.type === 'end') {
            newStop.end = true;
        }

        return newStop;
    };

    return [...pickingUp.map(pickupMapper), ...droppingOff.map(dropoffMapper)].find(
        (stop) => stop.ordering === ordering
    );
};

const addStop = (order, route, opts = {}) => {
    let newStops = cloneDeep(route.stopsByRouteId || []);

    const warehouse = order.wh_events?.findLast((e) => e.action.endsWith(':ADD_CD'))?.location || {};
    const whFullAddr = [
        warehouse.business_address,
        warehouse.business_unit,
        warehouse.business_city,
        warehouse.business_state,
        warehouse.business_zip,
    ]
        .filter((x) => x)
        .join(', ');

    const pickup =
        order.crossdock_leg === 'dropoff'
            ? {
                  address: whFullAddr,
                  lat: warehouse.lat,
                  lng: warehouse.lng,
              }
            : {
                  address: order.pickup_full_address || order.pickup_address,
                  lat: order.pickup_lat,
                  lng: order.pickup_lng,
              };

    const dropoff =
        order.crossdock_leg === 'pickup'
            ? {
                  address: whFullAddr,
                  lat: warehouse.lat,
                  lng: warehouse.lng,
              }
            : {
                  address: order.dropoff_full_address || order.dropoff_address,
                  lat: order.dropoff_lat,
                  lng: order.dropoff_lng,
              };

    if (dropoff.address && pickup.address && dropoff.address === pickup.address) {
        throw new Error(
            `Cannot add order ${order.order_number}. Please verify pickup and dropoff addresses are different.`
        );
    }

    if (!dropoff.lat || !dropoff.lng) {
        throw new Error(`Cannot add order ${order.order_number}. Please verify dropoff address.`);
    }

    if (!pickup.lat || !pickup.lng) {
        throw new Error(`Cannot add order ${order.order_number}. Please verify pickup address.`);
    }

    if (dropoff.lat === pickup.lat && dropoff.lng === pickup.lng) {
        throw new Error(
            `Cannot add order ${order.order_number}. Please verify pickup and dropoff locations are different.`
        );
    }

    if (opts.addExceptionReturn) {
        newStops = addExceptionReturnStop({ order, stops: newStops, pickup, dropoff });
    } else if (order.order_type === 'return') {
        newStops = addReturnStops({ order, stops: newStops, pickup, dropoff });
    } else if (order.order_type === 'exchange') {
        newStops = addExchangeStops({ order, stops: newStops, pickup, dropoff });
    } else {
        newStops = addDeliveryStops({ order, stops: newStops, pickup, dropoff });
    }

    newStops = moveFinalDropoffsToEnd(newStops);
    newStops = moveStartEndStops(newStops);

    return newStops;
};

const addOrders = (route, orders) => {
    let newStops = cloneDeep(route.stopsByRouteId || []);

    for (const order of orders) {
        newStops = addStop(order, { ...route, stopsByRouteId: newStops });
    }

    // If a final return dropoff stop is added that has an address equal to the end location stop, remove the end location stop.
    let endStop = newStops.find((s) => s.end);
    const returnDropoffs = newStops.filter((s) => isFinalDropoff(s));
    if (endStop) {
        if (returnDropoffs.some((s) => s.address === endStop.address)) {
            newStops = newStops.filter((s) => !s.end);
        }
    }

    return newStops;
};

const removeStop = (order, route) => {
    const newStops = cloneDeep(route.stopsByRouteId || []);

    let modifiedNewStops = newStops
        // Remove stops where this is the only order
        .filter((stop) => !(stop.orders?.length === 1 && stop.orders[0] === order.order_id))
        // Sort by ordering after removing stops to fix gaps in ordering sequence
        .sort((a, b) => a.ordering - b.ordering)
        // Remove this order from stops that have multiple orders
        .map((stop, idx) => {
            const newStop = {
                ...stop,
                orders: stop.orders?.length ? stop.orders.filter((o) => o !== order.order_id) : [],
                ordering: idx,
            };
            if (stop.returns?.length) {
                newStop.returns = stop.returns.filter((o) => o !== order.order_id);
            }
            if (stop.exchanges?.length) {
                newStop.exchanges = stop.exchanges.filter((o) => o !== order.order_id);
            }
            return newStop;
        });

    return modifiedNewStops;
};

const removeCustomStop = (stops = [], type) => {
    let newStops = cloneDeep(stops);
    let stopToRemove = -1;
    if (type === 'start') {
        newStops.forEach((stop, i) => {
            if (stop.start) stopToRemove = i;
        });

        if (stopToRemove >= 0) {
            for (let stop of newStops) {
                stop.ordering--;
            }

            newStops.splice(stopToRemove, 1);
        }
    } else {
        newStops.forEach((stop, i) => {
            if (stop.end) stopToRemove = i;
        });

        if (stopToRemove >= 0) {
            if (newStops.length - 1 > stopToRemove) {
                for (let i = stopToRemove + 1; i < newStops.length; i++) {
                    newStops[i].ordering--;
                }
            }
            newStops.splice(stopToRemove, 1);
        }
    }
    return newStops;
};

const addStart = (route, addressInfo) => {
    const startStop = {
        address: addressInfo.fulladdress,
        lat: addressInfo.lat,
        lng: addressInfo.lng,
        ordering: 0,
        orders: [],
        start: true,
        type: 'PICKUP',
    };

    const newStops = cloneDeep(route.stopsByRouteId || []);

    if (!newStops.length) {
        // If no stops exist, return early with a new stop object - otherwise
        // it'll run into multiple undefined errors.
        return [startStop];
    }

    const currentStartIdx = newStops.findIndex((s) => s.start);

    if (currentStartIdx > -1) {
        newStops.splice(currentStartIdx, 1, startStop);
    } else {
        newStops.forEach((stop) => (stop.ordering += 1));
        newStops.unshift(startStop);
    }

    return newStops;
};

const addEnd = (route, addressInfo) => {
    const endStop = {
        address: `${addressInfo.street}, ${addressInfo.city}, ${addressInfo.state}, ${addressInfo.zip}`,
        lat: addressInfo.lat,
        lng: addressInfo.lng,
        orders: [],
        end: true,
        type: 'DROPOFF',
    };

    const newStops = cloneDeep(route.stopsByRouteId || []);
    const currentEndIdx = newStops.findIndex((s) => s.end);

    if (currentEndIdx > -1) {
        endStop.ordering = newStops[currentEndIdx].ordering;
        newStops.splice(currentEndIdx, 1, endStop);
    } else {
        endStop.ordering = newStops.length;
        newStops.push(endStop);
    }

    return newStops;
};

/* TODO: Migrate this
const resetStops = async (route, firebase) => {
    const orders = await firebase.fetchRouteOrders(route.orders, route.sourceForm, route.routeNumber);

    let stops = {
        pickingUp: [],
        droppingOff: [],
    };

    for (let i = 0; i < orders.length; i++) {
        stops = addStop(orders[i], { stops });
    }

    return stops;
};
*/

const getStopSequence = (stops) => {
    if (!stops) return [];
    return cloneDeep(stops)
        .sort((a, b) => a.ordering - b.ordering)
        .map((stop, index) => ({ ...stop, ordering: index }));
};

const addLatLngToStops = async (stops = []) => {
    for (const stop of stops) {
        let { lat, lng } = stop;
        if (!lat || !lng) {
            const { address } = stop;
            const results = await geocodeByAddress(address);
            ({ lat, lng } = await getLatLng(results[0]));
            stop.lat = lat;
            stop.lng = lng;
        }
    }
    return stops;
};

const fetchOptimizationRunResults = async (runId, attempt = 0) => {
    if (attempt >= 5) {
        return { error: 'Maximum number of retries reached' };
    } else {
        const res = await get(OPTIMIZATION_RESULT.replace(':runId', runId));

        if (res.status >= 400) {
            return { error: 'There was an error' };
        } else if (res.data?.route) {
            return res.data;
        } else {
            return fetchOptimizationRunResults(runId, attempt + 1);
        }
    }
};

const fetchOptimalStopsOrder = async (stops, options = {}) => {
    const response = await post(START_OPTIMIZATION, {
        stops,
        options,
    });

    const runId = response?.data?.runId;

    if (runId) {
        const { route } = await fetchOptimizationRunResults(runId);
        return route;
    }

    return null;
};

/**
 * Based on logic of addReturnStops and addExchangeStops, all "Final Dropoff" stops will be dropoffs with orders in the returns array.
 * @param {*} stop
 * @returns Boolean
 */
const isFinalDropoff = (stop) => {
    return stop.type === 'DROPOFF' && !!stop?.returns?.length;
};

const isFinalReturn = (stop) => {
    return stop.type === 'DROPOFF' && !stop.end && stop.returns?.length > 0;
};

const isWarehousePickup = (stop) => {
    return stop.type === 'PICKUP' && !!stop?.orders?.length && !stop?.returns?.length;
};

const isCustomerStop = (stop, route) => {
    const [orderPickups, orderDropoffs] = (route?.orders || []).reduce(
        ([acc_pu, acc_do], mapping) => {
            const order = mapping.order;
            return [
                { ...acc_pu, ...(mapping.type !== 'DROPOFF' ? { [order.order_id]: order } : {}) },
                { ...acc_do, ...(mapping.type !== 'PICKUP' ? { [order.order_id]: order } : {}) },
            ];
        },
        [{}, {}]
    );

    return (
        (stop.type === 'PICKUP' &&
            !stop.start &&
            !!stop.returns?.length &&
            Object.keys(orderPickups).some((order_id) => stop.orders?.includes(order_id))) ||
        (stop.type === 'DROPOFF' &&
            !stop.end &&
            !stop.returns?.length &&
            Object.keys(orderDropoffs).some((order_id) => stop.orders?.includes(order_id)))
    );
};

const isCrossdockStop = (stop, route) => {
    const orderPickups = Object.fromEntries(
        (route?.orders || [])
            .filter((mapping) => mapping.type === 'PICKUP')
            .map((mapping) => [mapping.order_id, mapping.order])
    );

    return (
        stop.type === 'DROPOFF' &&
        !stop.end &&
        Object.keys(orderPickups).some((order_id) => stop.orders?.includes(order_id))
    );
};

/**
 * Create request payload for NextMV Optimization algorithm
 * This method will create an id for each stop, consisting of a has of the stop's address, type, and list of order.order_ids
 * Precedence will then be added to each stop to ensure NextMV does not optimize into an invalid order
 *
 * Precedence Rules:
 * start - precedes all
 * end - succeeds all
 * pickup - precedes related dropoffs
 * return pickup - succeeds normal pickups
 * final return - succeeds normal dropoffs
 * @param {*} stops
 * @returns
 */
const createNextMvPayload = async (stops) => {
    // Create id and position for each stop
    const nextmvStops = [];
    for (const stop of getStopSequence(stops)) {
        const { address, type, start, end, orders, returns, exchanges } = stop;

        if (stop.end || stop.start) continue;

        let { lat, lng } = stop;
        if (!lat || !lng) {
            const results = await geocodeByAddress(address);
            ({ lat, lng } = await getLatLng(results[0]));
        }

        const id = hash({ address, type, orders });

        nextmvStops.push({
            id,
            position: { lat: lat, lon: lng },
            start,
            end,
            type,
            orders,
            returns,
            exchanges,
        });
    }

    // Add precedence to stops
    nextmvStops.forEach((stop) => {
        // Start Stop precedes all other stops
        if (stop.start) {
            stop.precedes = nextmvStops.filter((_stop) => _stop.id !== stop.id).map((_stop) => _stop.id);

            // End Stop succeeds all other stops
        } else if (stop.end) {
            stop.succeeds = nextmvStops.filter((_stop) => _stop.id !== stop.id).map((_stop) => _stop.id);

            // Pickup Stops precede dropoff stops with intersecting orders
        } else if (stop.type === 'PICKUP' && stop.orders?.length) {
            stop.precedes = nextmvStops
                .filter(
                    (_stop) =>
                        _stop.type === 'DROPOFF' &&
                        _stop.orders?.length &&
                        _stop.orders.some((order) => stop.orders.indexOf(order) !== -1)
                )
                .map((_stop) => _stop.id);

            // Return pickups succeed other nonReturn pickups
            if (stop.returns?.length) {
                stop.succeeds = nextmvStops
                    .filter((_stop) => _stop.type === 'PICKUP' && !_stop.returns?.length)
                    .map((_stop) => _stop.id);
            }

            // Return Dropoff stops succeed other nonReturn dropoff stops
        } else if (stop.type === 'DROPOFF' && stop.returns?.length) {
            stop.succeeds = nextmvStops
                .filter((_stop) => _stop.type === 'DROPOFF' && _stop.orders?.length && !_stop.returns?.length)
                .map((_stop) => _stop.id);
        }

        // nextMv errors if you pass empty array for succeeds or preceeds
        if (stop.precedes && !stop.precedes.length) {
            delete stop.precedes;
        }
        if (stop.succeeds && !stop.succeeds.length) {
            delete stop.succeeds;
        }
    });

    // Only return fields used by NextMV
    return nextmvStops.map((stop) => {
        return {
            id: stop.id,
            position: stop.position,
            precedes: stop.precedes,
            succeeds: stop.succeeds,
        };
    });
};

const getGmapsDirectionForSequence = async (stopSequence, routeStartTime) => {
    try {
        const directionsService = new global.google.maps.DirectionsService();

        const { origin, destination, waypoints } = _getGMapsParams(stopSequence);

        const routingRequest = {
            origin,
            destination,
            waypoints,
            travelMode: global.google.maps.TravelMode.DRIVING,
        };

        const useTrafficEstimates = routeStartTime && routeStartTime > Date.now();

        if (useTrafficEstimates) {
            routingRequest.drivingOptions = {
                trafficModel: 'bestguess',
            };

            if (routeStartTime) {
                routingRequest.drivingOptions.departureTime = new Date(routeStartTime);
            }
        }

        const result = await directionsService.route(routingRequest);
        return {
            ...result,
            useTrafficEstimates,
        };
    } catch (e) {
        console.error(e.message);

        return {};
    }
};

const getGMapsDirections = async (stopSequence, routeStartTime) => {
    if (!global.google) {
        console.warn('Google API has not loaded');
        return;
    }

    // Deep clone stop sequence
    // const stops = (stopSequence || []).map((stop) => ({
    //     ...stop,
    //     // address: stop.address.replaceAll(/, LOT#? \d+/gi, ''),
    // }));
    let stops = cloneDeep(stopSequence || []).filter(
        (s) => s.type !== 'LUNCH' && !(s.overnight && isWarehousePickup(s))
    );
    const slices = [];
    const routeLegs = [];
    let adjustedStartTime = routeStartTime;
    let status;

    let result = {};

    while (stops.length) {
        // Split stops into slices that fit into Google Map's limitation of 25 stops per request.
        const stopsSlice = stops.splice(0, 25);
        slices.push(stopsSlice);
        if (slices.length > 1) {
            // If we're not the first
            let prevSlice = slices[slices.length - 1];
            stopsSlice.unshift(prevSlice[prevSlice.length - 1]);
        }
        // Fetch Google Map's results for this slice of stops
        const sliceResult = await getGmapsDirectionForSequence(stopsSlice, adjustedStartTime);
        if (!sliceResult.routes) {
            return {};
        }

        const legs = sliceResult.routes[0].legs;
        // Add legs to the total array of legs for the route
        routeLegs.push(...legs);
        status = sliceResult.status;

        if (adjustedStartTime) {
            legs.forEach((leg) => {
                // Add the duration to the start time, so the next request will have its
                // routeStartTime adjusted by an appropriate amount.
                adjustedStartTime += leg.duration.value;
            });
        }

        result = {
            ...result,
            ...sliceResult,
        };
    }

    return {
        ...result,
        routes: [
            {
                legs: routeLegs,
            },
        ],
        status,
    };
};

const getCustomerDirections = async (truckLocation, customerStop) => {
    try {
        const directionsService = new global.google.maps.DirectionsService();

        const routingReq = {
            origin: { lat: truckLocation.lat, lng: truckLocation.lng },
            destination: { lat: customerStop.lat, lng: customerStop.lng },
            travelMode: global.google.maps.TravelMode.DRIVING,
        };

        const result = await directionsService.route(routingReq);
        return result;
    } catch (e) {
        console.error(e.message);
        return {};
    }
};
const getLoadDirections = async (load) => {
    try {
        const directionsService = new global.google.maps.DirectionsService();

        const routingReq = {
            origin: { lat: load.pickup_lat, lng: load.pickup_lng },
            destination: { lat: load.dropoff_lat, lng: load.dropoff_lng },
            travelMode: global.google.maps.TravelMode.DRIVING,
        };

        const result = await directionsService.route(routingReq);
        return result;
    } catch (e) {
        console.error(e.message);
        return {};
    }
};

const getTravelData = async (gMapsDirections) => {
    if (!gMapsDirections?.status) return { duration: {}, distance: {} };

    let metersToTravel = 0;
    let secondsToTravel = 0;

    const { legs } = gMapsDirections.routes[0];

    for (const leg of legs) {
        metersToTravel += leg.distance.value;
        secondsToTravel += leg.duration.value;
    }

    const formattedDuration = _formatDuration(secondsToTravel);
    const formattedDistance = _formatDistance(metersToTravel);

    return {
        duration: {
            text: formattedDuration,
            value: secondsToTravel,
        },
        distance: {
            text: formattedDistance,
            value: metersToTravel,
        },
    };
};

const fetchOptimalStopsOrderV2 = async (stops, opts = {}) => {
    let skippedStops = [];
    let nextDayFinalReturns = [];
    let filteredStops = [
        ...stops.filter((s) => {
            if ((s.overnight && isWarehousePickup(s)) || s.type === 'LUNCH') {
                skippedStops.push(s);
                return false;
            } else if (isFinalReturn(s) && opts.finish_returns_next_day) {
                nextDayFinalReturns.push(s);
                return false;
            } else {
                return true;
            }
        }),
    ];

    const sorted = getStopSequence(filteredStops);
    const offset = sorted.some((stop) => stop.start) ? 1 : 0;

    const nextMvStops = await createNextMvPayload(filteredStops);
    const optimizedNextMvStops = await fetchOptimalStopsOrder(nextMvStops, opts);

    if (!optimizedNextMvStops) {
        return [];
    }

    const orderingByHash = Object.fromEntries(
        optimizedNextMvStops.map((stop, idx) => {
            return [stop.id, idx];
        })
    );

    const optimizedStops = sorted
        .map((stop) => {
            const ordering = orderingByHash[hash({ address: stop.address, type: stop.type, orders: stop.orders })];
            return {
                ...stop,
                ordering: ordering || ordering === 0 ? ordering + offset : stop.ordering,
            };
        })
        .sort((l, r) => l.ordering - r.ordering);

    // Re-insert any skipped stops.
    const optimizedStopsReinserted = skippedStops.reduce((stops, stop) => {
        const stopIndex = stops.findIndex((i) => i.ordering === stop.ordering);

        if (stopIndex > 0) {
            const alteredStops = stops.map((s, i) => {
                if (i >= stopIndex) {
                    s.ordering += 1;
                }
                return s;
            });
            alteredStops.splice(stopIndex, 0, stop);
            return alteredStops;
        } else {
            return stops;
        }
    }, optimizedStops);

    // Insert any next day final return stops to the end of the route.
    const nextDayFinalReturnsReinserted = nextDayFinalReturns.reduce((stops, stop) => {
        const maxOrdering = stops[stops.length - 1].ordering;
        stops.push({
            ...stop,
            ordering: maxOrdering + 1,
        });
        return stops;
    }, optimizedStopsReinserted);

    return nextDayFinalReturnsReinserted;
};

const ordersFromStops = (stops = []) => {
    const attrs = ['orders', 'returns', 'exchanges'];
    const ids = new Set();

    stops.forEach((obj) => {
        attrs.forEach((attr) => {
            (obj[attr] || []).forEach((id) => ids.add(id));
        });
    });

    return ids;
};

// Given an order and a route, determines if the pickup stop for the order has been completed yet or not.
const hasOrderBeenPickedUp = (order, route) => {
    const associatedPickupStop = route?.stopsByRouteId?.find(
        (_stop) => _stop.type === 'PICKUP' && _stop.orders?.includes(order.order_id)
    );

    if (associatedPickupStop && associatedPickupStop.stop_completion_time) return true;
    return false;
};

// Given an order and a route, determines if a returns stop exists on the route that has an address that is equal to the order's pickupaddress.
// Also returns the index of this stop if one is found.
const cancelledReturnStopExists = (order, route) =>
    route?.stopsByRouteId?.find(
        (stop) =>
            stop?.returns?.length &&
            (stop.address === order.pickup_full_address || stop.address === order.pickup_address)
    );

// Takes in address and returns the lat/lng information.
const getLatLngFromAddress = async (address) => {
    let lat;
    let lng;
    const results = await geocodeByAddress(address);
    ({ lat, lng } = await getLatLng(results[0]));
    return { lat, lng };
};

const reverseStops = (stops) => {
    const clonedStops = cloneDeep(stops || []);
    const stopSequence = getStopSequence(clonedStops);

    let leftIdx = 0;
    let rightIdx = stopSequence.length - 1;

    while (leftIdx < rightIdx) {
        const leftStop = stopSequence[leftIdx];
        const rightStop = stopSequence[rightIdx];

        if (leftStop.start || isWarehousePickup(leftStop)) {
            leftIdx++;
            continue;
        }

        if (rightStop.end || isFinalDropoff(rightStop)) {
            rightIdx--;
            continue;
        }

        // swap
        const temp = leftStop.ordering;
        leftStop.ordering = rightStop.ordering;
        rightStop.ordering = temp;
        leftIdx++;
        rightIdx--;
    }

    return stopSequence;
};

const stopFieldsToUpdate = [
    'del_window_start',
    'del_window_end',
    'service_time',
    'stop_start_time',
    'stop_end_time',
    'del_window_buffer',
    'del_window_rounded',
    'driving_time',
    'driving_distance',
    'wait_time',
];

const gqlStopUpdates = (stops, route) => {
    let newStops = [],
        updatedStops = [];
    stops?.forEach((stop) => {
        if (!stop.stop_id) newStops.push(stop);
        else updatedStops.push(stop);
    });
    let removedStops =
        route?.stopsByRouteId?.filter(
            (routeStop) => !updatedStops.find((stop) => stop.stop_id === routeStop.stop_id)
        ) || [];

    return {
        inserts: newStops.map((newStop) => ({
            route_id: route.route_id,
            ordering: newStop.ordering,
            orders: newStop.orders?.length ? toArrayLiteral(newStop.orders) : null,
            returns: newStop.returns?.length ? toArrayLiteral(newStop.returns) : null,
            exchanges: newStop.exchanges?.length ? toArrayLiteral(newStop.exchanges) : null,
            type: newStop.type,
            address: newStop.address,
            lat: newStop.lat,
            lng: newStop.lng,
            start: !!newStop.start,
            end: !!newStop.end,
        })),
        updates: updatedStops.map((updatedStop) => ({
            where: { stop_id: { _eq: updatedStop.stop_id } },
            _set: {
                ordering: updatedStop.ordering,
                orders: updatedStop.orders?.length ? toArrayLiteral(updatedStop.orders) : null,
                returns: updatedStop.returns?.length ? toArrayLiteral(updatedStop.returns) : null,
                exchanges: updatedStop.exchanges?.length ? toArrayLiteral(updatedStop.exchanges) : null,
                ...Object.fromEntries(stopFieldsToUpdate.map((key) => [key, updatedStop[key] || null])),
            },
        })),
        deletes: removedStops.map((stop) => stop.stop_id),
    };
};

export default {
    findStopByAddress,
    findStopByOrdering,
    addStop,
    removeStop,
    removeCustomStop,
    addStart,
    addEnd,
    // TODO: Migrate
    // resetStops,
    getStopSequence,
    fetchOptimalStopsOrder,
    isFinalDropoff,
    isWarehousePickup,
    isCustomerStop,
    isCrossdockStop,
    createNextMvPayload,
    getGMapsDirections,
    getCustomerDirections,
    getTravelData,
    fetchOptimalStopsOrderV2,
    ordersFromStops,
    addLatLngToStops,
    getLoadDirections,
    cancelledReturnStopExists,
    hasOrderBeenPickedUp,
    reverseStops,
    gqlStopUpdates,
    addOrders,
    stopFieldsToUpdate,
};
