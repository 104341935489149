import React, { useCallback, useRef, useEffect, useState, useMemo } from 'react';
import { css } from '@emotion/react';
import { colors } from '@/styles';
import { useNavigate } from 'react-router-dom';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { useSticky } from 'react-table-sticky';
import InfiniteLoader from 'react-window-infinite-loader';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTable, useFlexLayout, useSortBy, useRowSelect, useExpanded } from 'react-table';
import { IndeterminateCheckbox } from '@/components/DispatchUnassigned/table';
import { TableContainer } from '@material-ui/core';
import HeaderCell from '@/components/CarrierAccountingOrders/table/HeaderCell';
import BodyCell from './BodyCell';
import BodyRow from '@/components/CarrierAccountingOrders/table/BodyRow';
import SubRowComponent from './SubrowComponent';
import { isEmpty } from 'lodash';

const Table = ({ state, loading, callbacks = {} }) => {
    const { itemQuantities, newManifestType, tab } = state;

    const itemsNotAlreadyInManifestType = useMemo(() => {
        return state.generatedData.map((order) => ({
            ...order,
            subRows: order.subRows.filter((item) => !item.manifests?.some((m) => m.manifest?.type === newManifestType)),
            itemsByOrderId: order.itemsByOrderId.filter(
                (item) => !item.manifests?.some((m) => m.manifest?.type === newManifestType)
            ),
        }));
    }, [state.generatedData, newManifestType]);

    const inf = useRef(null);
    const navigate = useNavigate();

    const stateReducer = useCallback((newState, action, prevState, tableData) => {
        // Implement custom toggle select behavior
        if (action.type === 'toggleRowSelected') {
            let newChecked = {};

            const orderRow = tableData.flatRows.find((r) => r.original.order_id === action.id && !r.original.item_id);
            const itemRow = tableData.flatRows.find((r) => r.original.item_id === action.id);

            // If toggling to true
            if (action.value) {
                if (itemRow) {
                    newChecked = {
                        [itemRow.original.order_id]: true,
                        [itemRow.original.item_id]: true,
                    };
                } else if (orderRow) {
                    // check if all of order's subrows are selected
                    if (orderRow.original.itemsByOrderId.every((i) => prevState.selectedRowIds[i.item_id])) {
                        newChecked = {
                            [orderRow.original.order_id]: false,
                            ...orderRow.original.itemsByOrderId.reduce((itemAcc, i) => {
                                return {
                                    ...itemAcc,
                                    [i.item_id]: false,
                                };
                            }, {}),
                        };
                    } else {
                        newChecked = {
                            [orderRow.original.order_id]: true,
                            ...orderRow.original.itemsByOrderId.reduce((itemAcc, i) => {
                                return {
                                    ...itemAcc,
                                    [i.item_id]: true,
                                };
                            }, {}),
                        };
                    }
                }

                // If toggling to false
            } else {
                if (itemRow) {
                    // if all of the items are off, turn the order off
                    if (
                        itemRow.original.order.itemsByOrderId.every(
                            (i) => !prevState.selectedRowIds[i.item_id] || i.item_id === itemRow.original.item_id
                        )
                    ) {
                        newChecked = {
                            [itemRow.original.order_id]: false,
                        };
                    }
                } else if (orderRow) {
                    // deselect all items
                    newChecked = {
                        [orderRow.original.order_id]: false,
                        ...orderRow.original.itemsByOrderId.reduce((itemAcc, i) => {
                            return {
                                ...itemAcc,
                                [i.item_id]: false,
                            };
                        }, {}),
                    };
                }
            }

            return {
                ...newState,
                selectedRowIds: {
                    ...newState.selectedRowIds,
                    ...newChecked,
                },
            };
        }

        return newState;
    }, []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
        state: tableState,
        setSortBy,
    } = useTable(
        {
            columns: state.CREATE_MANIFEST_COLUMNS,
            data: itemsNotAlreadyInManifestType,
            enableRowSelection: true,
            enableMultiRowSelection: true,
            expandSubRows: true,
            getRowCanExpand: (row) => row.depth === 0,
            stateReducer,
            selectSubRows: false,
            getRowId: (row) => {
                return row.item_id || row.order_id;
            },
            autoResetSelectedRows: false,
        },
        useSortBy,
        useSticky,
        useFlexLayout,
        useExpanded,
        useRowSelect,
        (hooks) => {
            hooks.visibleColumns.push((columns) => [
                {
                    id: 'selection',
                    sticky: 'left',
                    width: 50,
                    Header: ({ getToggleAllRowsSelectedProps }) => (
                        <IndeterminateCheckbox
                            {...getToggleAllRowsSelectedProps()}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        />
                    ),
                    Cell: ({ row }) => {
                        return (
                            <IndeterminateCheckbox
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                                {...row.getToggleRowSelectedProps()}
                            />
                        );
                    },
                },
                ...columns,
            ]);
        }
    );

    useEffect(() => {
        if (!isEmpty(state.ordersMap) && !isEmpty(state.itemsMap)) {
            callbacks.selectRows(
                Object.keys(tableState.selectedRowIds).filter(
                    (id) => !!tableState.selectedRowIds[id] && !state.ordersMap[id]
                )
            );
        }
    }, [tableState.selectedRowIds, state.ordersMap, state.itemsMap]);

    const Row = useCallback(
        ({ index, style }) => {
            //HEADERS
            if (index === 0) {
                const [group] = headerGroups;

                return (
                    <div {...group.getHeaderGroupProps({ style })}>
                        {group.headers.reduce(
                            (acc, subgroup) => [
                                ...acc,
                                subgroup.headers.map((col) => {
                                    return (
                                        <HeaderCell
                                            {...col.getHeaderProps()}
                                            key={col.id}
                                            cell={col}
                                            align={col.align}
                                            css={css`
                                                height: 77px !important;
                                            `}
                                        />
                                    );
                                }),
                            ],
                            []
                        )}
                    </div>
                );
            }

            //BODY
            const row = rows[index - 1];
            if (row) {
                prepareRow(row);

                if (row?.depth > 0) {
                    return (
                        <SubRowComponent
                            {...row.getRowProps({
                                style,
                            })}
                            row={row}
                            callbacks={{
                                setSplitCounts: callbacks.setSplitCounts,
                                setItemQuantities: callbacks.setItemQuantities,
                            }}
                            newManifest={state.newManifest}
                            itemQuantities={itemQuantities}
                            newManifestType={newManifestType}
                        />
                    );
                }

                return (
                    <>
                        <BodyRow
                            {...row.getRowProps({
                                style,
                            })}
                            row={row}
                        >
                            {row.cells.map((cell) => (
                                <BodyCell
                                    {...cell.getCellProps()}
                                    cell={cell}
                                    align={cell.column.align}
                                    span={cell.column.span}
                                    css={css`
                                        overflow: hidden;
                                        height: 77px !important;
                                    `}
                                    callbacks={{
                                        onEdit: callbacks.gotoAccessorial,
                                        gotoDetails: (order) => {
                                            navigate(`/order/${order.order_id}`);
                                        },
                                        toggleRow: () => row.toggleRowExpanded(),
                                    }}
                                    tab={tab}
                                />
                            ))}
                        </BodyRow>
                    </>
                );
            }

            return (
                <BodyRow
                    style={style}
                    css={css`
                        align-items: center;
                        display: flex;
                        justify-content: center;
                    `}
                >
                    <CircularProgress
                        size={24}
                        css={css`
                            color: ${colors.greys[4]};
                        `}
                    />
                </BodyRow>
            );
        },
        [prepareRow, rows, tableState.expanded, tableState.selectedRowIds, itemQuantities, newManifestType, tab]
    );

    return (
        <TableContainer
            css={css`
                height: 100%;
                overflow: hidden;
                background: #fff;
            `}
        >
            <div
                {...getTableProps()}
                css={css`
                    height: 100%;
                    display: flex;
                    flex-grow: 1;
                    flex-direction: column;
                `}
            >
                <div
                    {...getTableBodyProps()}
                    css={css`
                        display: flex;
                        flex-grow: 1;
                    `}
                >
                    <InfiniteLoader
                        ref={inf}
                        itemCount={state.hasMore ? rows.length + 2 : rows.length + 1}
                        threshold={50}
                        isItemLoaded={(idx) => {
                            return idx < state.orders.length;
                        }}
                        loadMoreItems={(start, stop) => {
                            if (!loading.init && state.hasMore) {
                                callbacks.loadMore();
                            }
                        }}
                    >
                        {({ onItemsRendered, ref }) => {
                            return (
                                <AutoSizer>
                                    {({ height, width }) => (
                                        <FixedSizeList
                                            ref={ref}
                                            onItemsRendered={onItemsRendered}
                                            height={height}
                                            itemCount={state.hasMore ? rows.length + 2 : rows.length + 1}
                                            itemSize={77}
                                            width={width}
                                        >
                                            {Row}
                                        </FixedSizeList>
                                    )}
                                </AutoSizer>
                            );
                        }}
                    </InfiniteLoader>
                </div>
            </div>
        </TableContainer>
    );
};

export default Table;
