import { WMS } from '@/constants/featureFlags';
import { useClientUser } from '@/hooks';
import { enrichManifest } from '@/utilities/enrichManifest';
import { useMutation, useQuery } from '@apollo/client';
import { EXCEPTION_TYPES } from '@onward-delivery/core';
import { captureException } from '@sentry/react';
import React, { createContext, useEffect, useMemo, useState, useRef } from 'react';
import { DAYS_30, MANIFEST_SEARCHABLE, ORDER_SEARCHABLE, TABS } from './constants';
import {
    INSERT_MANIFEST,
    GET_MANIFESTS,
    GET_ORDERS,
    UPSERT_ITEMS,
    GET_EXTERNAL_MANIFESTS,
    INSERT_MANIFEST_AND_UPSERT_ITEMS,
} from './graphql';
import { isEmpty, omit } from 'lodash';
import { ITEM_READONLY_FIELDS } from '@/constants/readonlyFields';
import { useTableColumns } from './modals/columns';

export const Context = createContext();

const ContextProvider = ({ children }) => {
    const { user_id: client_id, locations: warehouses, circles, shipping_partners } = useClientUser();
    const [tab, setTab] = useState(TABS.INBOUND);
    const [newManifest, setNewManifest] = useState(null);
    const [showCreateManifestModal, setShowCreateManifestModal] = useState(false);
    const [searchManifests, setSearchManifests] = useState('');
    const [searchOrders, setSearchOrders] = useState('');
    const [filters, setFilters] = useState(tab?.defaultFilters || {});
    const [notification, setNotification] = useState({});
    const [hasExternal, setHasExternal] = useState(false);
    const [selectedRows, selectRows] = useState([]);
    const [itemQuantities, setItemQuantities] = useState({});
    const [newManifestType, setNewManifestType] = useState();

    const [manifestCutoff, orderCutoff] = useMemo(() => {
        return [new Date(Date.now() - DAYS_30).toISOString(), new Date(Date.now() - DAYS_30 * 3).toISOString()];
    }, []);

    const ordersMapRef = useRef({});

    const manifestFilters = useMemo(() => {
        const combinedFilters = [];

        const baseFilter = { type: { _in: filters.type ? [filters.type] : tab.typeOptions } };
        combinedFilters.push(baseFilter);

        if (!searchManifests) {
            return combinedFilters;
        }

        const searchFilter = {
            _or: MANIFEST_SEARCHABLE.map((attr) => {
                if (attr.includes('.')) {
                    const parts = attr.split('.');
                    return parts.reduceRight((acc, part, index) => {
                        if (index === parts.length - 1) {
                            return { [part]: { _ilike: `%${searchManifests.trim()}%` } };
                        }
                        // if (part === 'items') {
                        //     return { [part]: { _some: acc } };
                        // }
                        return { [part]: acc };
                    }, {});
                }
                return { [attr]: { _ilike: `%${searchManifests.trim()}%` } };
            }),
        };
        combinedFilters.push(searchFilter);

        return combinedFilters;
    }, [tab, searchManifests, filters.type, filters.shipperSelect]);

    const orderFilters = useMemo(() => {
        if (!newManifest?.type) return null;

        let orderFilters = [];

        if (!circles?.[WMS]) {
            orderFilters.push(...[{ oms: { _eq: false } }, { carrier_id: { _eq: client_id } }]);
        }

        if (searchOrders) {
            orderFilters.push({
                _or: [
                    ...ORDER_SEARCHABLE.map((attr) => {
                        if (attr.includes('.')) {
                            const parts = attr.split('.');
                            return parts.reduceRight((acc, part, index) => {
                                if (index === parts.length - 1) {
                                    return { [part]: { _ilike: `%${searchOrders.trim()}%` } };
                                }
                                return { [part]: acc };
                            }, {});
                        }
                        return { [attr]: { _ilike: `%${searchOrders.trim()}%` } };
                    }),
                ],
            });
        }

        switch (newManifest.type) {
            case 'INBOUND':
                orderFilters.push(
                    ...[
                        {
                            _or: [
                                {
                                    itemsByOrderId: {
                                        _not: { manifests: { manifest: { type: { _in: ['INBOUND', 'CROSS_DOCK'] } } } },
                                    },
                                },
                                {
                                    itemsByOrderId: {
                                        exceptions: {
                                            exception: {
                                                reported_at: { _eq: 'PICKUP' },
                                                type: {
                                                    _in: [
                                                        EXCEPTION_TYPES.OVERAGE,
                                                        EXCEPTION_TYPES.SHORTAGE,
                                                        EXCEPTION_TYPES.DAMAGED,
                                                    ],
                                                },
                                            },
                                        },
                                    },
                                },
                            ],
                        },
                        {
                            _not: { routes: { type: { _eq: 'PICKUP' } } },
                        },
                    ]
                );
                break;
            case 'CROSS_DOCK':
                orderFilters.push(
                    ...[
                        {
                            itemsByOrderId: {
                                _not: { manifests: { manifest: { type: { _in: ['INBOUND', 'CROSS_DOCK'] } } } },
                            },
                        },
                        { _not: { routes: { type: { _eq: 'PICKUP' } } } },
                        { wh_events: { action: { _ilike: `%:ADD_CD` } } },
                    ]
                );
                break;
            case 'OUTBOUND':
                orderFilters.push(
                    ...[
                        {
                            _not: { routes: {} },
                        },
                        {
                            itemsByOrderId: { _not: { manifests: { manifest: { type: { _eq: 'OUTBOUND' } } } } },
                        },
                        {
                            job_type: { _eq: 'SHIPMENT' },
                        },
                    ]
                );
                break;
            case 'RETURN_TO_SENDER':
                orderFilters.push(
                    ...[
                        {
                            itemsByOrderId: {
                                _not: { manifests: { manifest: { type: { _eq: 'RETURN_TO_SENDER' } } } },
                            },
                        },
                        {
                            itemsByOrderId: {
                                exceptions: {
                                    exception: {
                                        reported_at: { _eq: 'PICKUP' },
                                        type: {
                                            _in: [
                                                EXCEPTION_TYPES.OVERAGE,
                                                EXCEPTION_TYPES.SHORTAGE,
                                                EXCEPTION_TYPES.DAMAGED,
                                            ],
                                        },
                                    },
                                },
                            },
                        },
                    ]
                );
                break;
            case 'WILL_CALL':
                orderFilters.push(
                    ...[
                        { itemsByOrderId: { _not: { manifests: { manifest: { type: { _eq: 'WILL_CALL' } } } } } },
                        { job_type: { _in: ['WILL_CALL', 'PICKUP_AND_WILL_CALL'] } },
                        ...(newManifest?.isDraft ? [{ shipper_id: { _eq: newManifest.partner_client_id } }] : []),
                    ]
                );
                break;
        }

        return orderFilters;
    }, [circles, client_id, searchOrders, newManifest]);

    const { data, loading: queryLoading } = useQuery(GET_MANIFESTS, {
        variables: {
            client_id,
            cutoff: searchManifests ? '2020-01-01T12:00:00+00:00' : manifestCutoff,
            filters: manifestFilters,
        },
        onError: (err) => {
            captureException(err);
            console.log(err);
        },
    });

    const { data: draftData, loading: draftLoading } = useQuery(GET_MANIFESTS, {
        variables: {
            client_id,
            cutoff: searchManifests ? '2020-01-01T12:00:00+00:00' : manifestCutoff,
            filters: [{ type: { _in: 'WILL_CALL' } }, ...manifestFilters.filter((f) => !f.type)],
        },
        onError: (err) => {
            captureException(err);
            console.log(err);
        },
    });

    const { data: externalData, loading: externalQueryLoading } = useQuery(GET_EXTERNAL_MANIFESTS, {
        variables: {
            client_id,
            cutoff: manifestCutoff,
            filters: manifestFilters,
        },
        onCompleted: (data) => {
            setHasExternal(data.manifests.length > 0);
        },
    });

    const manifests = useMemo(() => {
        const allManifests = (data?.manifests || [])
            .map((m) => enrichManifest(m))
            .filter((manifest) => isEmpty(manifest.draft_items));
        if (filters.status === 'ACTIVE') {
            return allManifests.filter((manifest) => tab.activeStatuses?.includes(manifest.status));
        }
        return allManifests.filter((manifest) => !filters.status || manifest.status === filters.status);
    }, [data, filters.status]);

    const externalManifests = useMemo(() => {
        const allManifests = (externalData?.manifests || [])
            .map((m) => enrichManifest(m))
            .filter((manifest) => isEmpty(manifest.draft_items));
        return allManifests.filter((manifest) => !filters.status || manifest.status === filters.status);
    }, [externalData, filters.status]);

    const draftManifests = useMemo(() => {
        const allManifests = (draftData?.manifests || [])
            .map((m) => {
                return { ...enrichManifest(m), isDraft: true };
            })
            .filter((manifest) => !isEmpty(manifest.draft_items));
        return allManifests;
    }, [draftData]);

    const {
        data: ordersData,
        loading: ordersLoading,
        refetch: refetchOrders,
    } = useQuery(GET_ORDERS, {
        variables: {
            client_id,
            cutoff: searchOrders ? '2020-01-01T12:00:00+00:00' : orderCutoff,
            filters: orderFilters,
        },
        skip: !orderFilters,
    });

    const orders = useMemo(() => {
        return ordersData?.orders || [];
    }, [ordersData]);

    const shipperOptions = useMemo(() => {
        if (tab.value === 'OUTBOUND') {
            return [
                ...new Set(
                    manifests.reduce((acc, m) => {
                        const orderShippers = (m?.route?.orders || []).reduce((orderAcc, o) => {
                            const shipperName = o?.order?.order_shipper?.business_name;
                            if (shipperName) {
                                orderAcc.push(shipperName);
                            }
                            return orderAcc;
                        }, []);
                        return [...acc, ...orderShippers];
                    }, [])
                ),
            ];
        }

        return [
            ...new Set(
                manifests.reduce((acc, m) => {
                    const itemShippers = (m?.items || []).reduce((itemAcc, i) => {
                        const shipperName = i?.item?.order?.order_shipper?.business_name;
                        if (shipperName) {
                            itemAcc.push(shipperName);
                        }
                        return itemAcc;
                    }, []);
                    return [...acc, ...itemShippers];
                }, [])
            ),
        ];
    }, [manifests, tab]);

    const [insertManifestAndUpsertItems, { loading: createLoading }] = useMutation(INSERT_MANIFEST_AND_UPSERT_ITEMS, {
        update: (cache, { data: { created } }) => {
            cache.updateQuery(
                {
                    query: GET_MANIFESTS,
                    variables: {
                        client_id,
                        cutoff: manifestCutoff,
                        filters: manifestFilters,
                    },
                },
                (data) => {
                    return {
                        ...data,
                        manifests: [created.returning[0], ...data.manifests],
                    };
                }
            );
        },
        onError: (error) => {
            console.error(error);
            captureException(error);
            setNotification({ severity: 'error', message: 'Error creating manifest' });
            setItemQuantities({});
        },
        onCompleted: ({ created }) => {
            setNotification({
                severity: 'success',
                message: `Manifest created - ${created.returning[0].manifest_number}`,
            });
            setItemQuantities({});
            refetchOrders();
        },
    });

    const [upsertItems, { loading: splitLoading }] = useMutation(UPSERT_ITEMS, {
        onError: (error) => {
            console.error(error);
            captureException(error);
            setNotification({ severity: 'error', message: 'Error splitting items' });
        },
        onCompleted: ({ created }) => {
            refetchOrders();
        },
    });

    const createManifest = () => {
        const {
            items: selectedItems,
            includedShippers,
            share_manifest,
            isDraft,
            status,
            route,
            ...rest
        } = newManifest || {};

        const items = Object.entries(selectedItems || {})
            .filter(([_, selected]) => selected)
            .map(([item_id]) => ({
                item_id,
            }));

        const shareManifest =
            share_manifest ||
            (includedShippers || []).some((shipperId) =>
                shipping_partners.some((partner) => partner.shipper_id === shipperId && partner.share_manifests)
            );

        const itemsToSplit = items.filter((i) => {
            return itemQuantities?.[i.item_id] && itemQuantities[i.item_id] < itemsMap?.[i.item_id]?.quantity;
        });

        const splitItemInserts = [];
        let existingWhId = rest.warehouse_id;

        itemsToSplit.forEach((i) => {
            const splitAmount = itemQuantities[i.item_id];
            const itemObject = itemsMap[i.item_id];
            const existingInboundManifestId = itemObject.manifests[0]?.manifest_id;
            const palletInfoToCopy = omit(itemObject.pallet, [
                'pallet_id',
                'pallet_number',
                '__typename',
                'warehouse_location',
                'logs',
            ]);

            if (isDraft) {
                existingWhId = itemObject.manifests[0]?.manifest?.warehouse_id;
            }

            const { item_id, quantity, pallet_id, order, order_number, ...rest } = omit(
                itemObject,
                ITEM_READONLY_FIELDS
            );
            const item1 = { ...rest, quantity: splitAmount, item_id: item_id, pallet_id: pallet_id };
            const item2 = {
                ...rest,
                quantity: quantity - splitAmount,
                //If creating will-call manifest split onto new pallet for tracking picking/staging separate from original.
                ...(newManifest.type === 'WILL_CALL'
                    ? {
                          pallet: itemObject.pallet
                              ? {
                                    data: palletInfoToCopy,
                                    on_conflict: {
                                        constraint: 'pallet_items_pkey',
                                        update_columns: [
                                            'pallet_name',
                                            'type',
                                            'warehouse_id',
                                            'warehouse_status',
                                            'is_pool',
                                        ],
                                    },
                                }
                              : {},
                          manifests: {
                              data: { manifest_id: existingInboundManifestId },
                              on_conflict: {
                                  constraint: 'manifests_inbound_items_pkey',
                                  update_columns: ['item_id', 'manifest_id'],
                              },
                          },
                      }
                    : {}),
            };
            splitItemInserts.push(item1);
            splitItemInserts.push(item2);
        });

        return insertManifestAndUpsertItems({
            variables: {
                manifests: [
                    {
                        ...rest,
                        client_id,
                        type: isDraft ? 'WILL_CALL' : tab.value,
                        source: 'MANUAL',
                        items: {
                            data: items,
                        },
                        share_manifest: shareManifest,
                        draft_items: [],
                        warehouse_id: existingWhId,
                    },
                ],
                items: splitItemInserts,
            },
        });
    };

    const CREATE_MANIFEST_COLUMNS = useTableColumns(tab);

    const generatedData = useMemo(() => {
        return orders.map((o) => {
            return {
                ...o,
                subRows: o?.itemsByOrderId.map((i) => {
                    return {
                        ...i,
                        order: {
                            ...o,
                        },
                    };
                }),
            };
        });
    }, [orders]);

    useEffect(() => {
        const newOrdersMap = orders.reduce(
            (accum, order) => {
                if (!accum[order.order_id]) {
                    accum[order.order_id] = order;
                }
                return accum;
            },
            { ...ordersMapRef.current }
        );

        ordersMapRef.current = newOrdersMap;
    }, [orders]);

    const itemsMap = useMemo(() => {
        return orders.reduce((itemMapAccum, order) => {
            return {
                ...itemMapAccum,
                ...order.itemsByOrderId.reduce((itemMap, item) => {
                    return {
                        ...itemMap,
                        [item.item_id]: {
                            ...item,
                            order: order,
                        },
                    };
                }, {}),
            };
        }, {});
    }, [orders]);

    const matchedDraftItems = useMemo(() => {
        if (!newManifest?.draft_items || !newManifest?.items) {
            return {};
        }

        const selectedItemIds = Object.entries(newManifest.items)
            .filter(([_, selected]) => selected)
            .map(([itemId]) => itemId);

        const newMatchedItems = {};

        newManifest?.draft_items?.forEach((draftItem, index) => {
            const draftSku = draftItem.sku;

            const hasMatch = selectedItemIds.some((itemId) => {
                const item = itemsMap[itemId];
                if (!itemQuantities[itemId])
                    setItemQuantities((prev) => {
                        return {
                            ...prev,
                            [itemId]: draftItem.quantity,
                        };
                    });
                return item && item.sku === draftSku;
            });

            newMatchedItems[index] = hasMatch;
        });

        return newMatchedItems;
    }, [newManifest, itemsMap]);

    useEffect(() => {
        setNewManifest((prev) => {
            const newManifestObject = {
                ...prev,
                items: { ...(prev?.items || {}) },
            };

            // First, set all existing items to false that are no longer in selectedRows
            if (newManifestObject.items) {
                Object.keys(newManifestObject.items).forEach((itemId) => {
                    if (!selectedRows.includes(itemId)) {
                        delete newManifestObject.items[itemId];
                    }
                });
            }

            // Then set all selected rows to true
            selectedRows.forEach((item_id) => {
                newManifestObject.items[item_id] = true;

                const itemOrder = itemsMap[item_id]?.order;
                if (itemOrder?.itemsByOrderId.every((i) => selectedRows.includes(i.item_id))) {
                    const shipperId = itemOrder?.shipper_id;
                    if (
                        !newManifestObject.includedShippers ||
                        !newManifestObject.includedShippers.includes(shipperId)
                    ) {
                        newManifestObject.includedShippers = [
                            ...(newManifestObject?.includedShippers || []),
                            shipperId,
                        ];
                    }
                }
            });

            // Clean up includedShippers if all items from a shipper are deselected
            if (newManifestObject.includedShippers && newManifestObject.includedShippers.length > 0) {
                newManifestObject.includedShippers = newManifestObject.includedShippers.filter((shipperId) => {
                    const shipperItems = Object.keys(itemsMap).filter(
                        (itemId) => itemsMap[itemId]?.order?.shipper_id === shipperId
                    );
                    return shipperItems.some((itemId) => newManifestObject.items[itemId]);
                });
            }

            return newManifestObject;
        });
    }, [selectedRows, itemsMap]);

    return (
        <Context.Provider
            value={{
                state: {
                    tab,
                    newManifest,
                    manifests,
                    externalManifests,
                    draftManifests,
                    searchManifests,
                    orders,
                    searchOrders,
                    notification,
                    filters,
                    warehouses,
                    hasExternal,
                    shipperOptions,
                    CREATE_MANIFEST_COLUMNS,
                    generatedData,
                    itemsMap,
                    ordersMap: ordersMapRef.current,
                    itemQuantities,
                    newManifestType,
                    showCreateManifestModal,
                    matchedDraftItems,
                },
                loading: {
                    queryLoading,
                    createLoading,
                    ordersLoading,
                    externalQueryLoading,
                    draftLoading,
                },
                callbacks: {
                    setTab,
                    setNewManifest,
                    setShowCreateManifestModal,
                    createManifest,
                    setSearchManifests,
                    setSearchOrders,
                    clearNotification: () => setNotification({}),
                    setFilters,
                    selectRows,
                    setItemQuantities,
                    setNewManifestType,
                },
            }}
        >
            {children}
        </Context.Provider>
    );
};

export const withContext = (Component) => (props) =>
    (
        <ContextProvider>
            <Component {...props} />
        </ContextProvider>
    );
