import { gql } from '@apollo/client';

export const MANIFEST_FIELDS = gql`
    fragment ManifestFields on manifests {
        manifest_id
        manifest_number
        client_id
        type
        source
        origin
        destination
        route_id
        warehouse_id
        receiving_date
        created_at
        last_modified_at
        share_manifest
        draft_items
        reference_id
        partner_client_id
        staging_zone
    }
`;
