import React, { useState, useEffect, useContext, useMemo, useCallback } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
    INSERT_EVENTS,
    QUERY_ORDERS_BY_OD_OR_PO,
    QUERY_ORDERS_WITH_DRIVERS_BY_CARRIER_ID_OR_SHIPPER_ID,
    QUERY_ORDER_SHIPPERS,
    SEND_MESSAGES,
    UPDATE_ORDERS_MANY,
} from './queries';
import {
    QUERY_DROPOFF_LOCATIONS_WITH_DRIVERS_BY_CARRIER_ID_OR_SHIPPER_ID,
    QUERY_ACTIONABLE_ORDER_AGGREGATES,
} from './queries';
import {
    createTheme,
    Box,
    Grid,
    ThemeProvider,
    TextField,
    InputAdornment,
    LinearProgress,
    Button,
    CircularProgress,
} from '@material-ui/core';
import { useSearchParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { Search } from '@material-ui/icons';
import FilterPopover from './FilterPopover';
import OrdersTable from './OrdersTable';
import { OnwardTabContainer, OnwardTab } from '../Tabs';
import { UserContext } from '../App';
import { useClientUser } from '@/hooks';
import './styles.css';
import _, { zipObject } from 'lodash';
import dateFns from '../../utilities/dateFns';
import add from 'date-fns/add';
import { subDays } from 'date-fns';
import formatISO from 'date-fns/formatISO';
import { captureException } from '@sentry/react';
import { Pagination } from '@material-ui/lab';
import { colors } from '@/styles';
import { css } from '@emotion/react';
import { PrimaryHeaderContainer, PrimaryHeaderLabel } from './blocks';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import MessageIcon from '@mui/icons-material/Message';
import { FILTER_ATTRS, CSV_BULK_UPDATE_COLUMNS, CSV_EXPORT_COLUMNS } from './constants';

import { PrimaryButton } from '@/styles/blocks';
import Papa from 'papaparse';
import Snackbar from '../Snackbar';
import { useTabs } from '../admin/AdminOrders/constants';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import TabScrollButton from '@material-ui/core/TabScrollButton';
import { calcOrderPricing, calcOrderOwner } from '@/utilities/calcOrderPricing';
import useAction from '@/utilities/useQuery';
import SendMessageModal from './SendMessageModal';
import ExportCsvTemplateModal from '../ExportCsvTemplateModal';
import { ORDER_EXPORT_COLUMNS } from '../ExportCsvTemplateModal/constants';
import { INSERT_CSV_EXPORT_TEMPLATE } from '@/graphql/mutations/csv_export_templates';
import csvDownload from 'json-to-csv-export';
import { GET_PRICING_OVERRIDES } from '@/graphql/queries/pricing_overrides';
import { genAccessorials } from '../Account/Tariffs/utils';
import { MILESTONE_OPTIONS } from '@onward-delivery/core';

const theme = createTheme({
    typography: {
        fontFamily: ['Montserrat', 'Roboto', 'Arial'].join(','),
        h2: {
            fontWeight: 800,
            fontSize: '16px',
            lineHeight: 1.25,
            color: '#2B2B2B',
        },
        h3: {
            fontWeight: 700,
            fontSize: '16px',
            lineHeight: 1.25,
            color: '#2B2B2B',
        },
    },
    palette: {
        text: {
            secondary: '#4c4c4c',
        },
        primary: {
            main: '#59b863',
            contrastText: '#fff',
        },
    },
});

const searchable = [
    'order_number',
    'po_number',
    'pickup_city',
    'pickup_state',
    'pickup_zip',
    'dropoff_name',
    'dropoff_city',
    'dropoff_state',
    'dropoff_zip',
    'reference_id',
    'dropoff_phone',
    'manufacturer',
    'middle_mile_integration_meta.tracking_number',
];

const addOneDay = (date) => {
    const alteredDate = add(new Date(date), { days: 2 });
    return formatISO(alteredDate, { representation: 'date' });
};

const MyOrders = () => {
    let [searchParams, setSearchParams] = useSearchParams();
    const [searchOverride, setSearch] = useState('');
    const [tabIndex, setTabIndex] = useState(0);
    const [debounceTimer, setDebounceTimer] = useState(null);
    const [orderLocations, setOrderLocations] = useState([]);
    const [sortBy, setSortBy] = useState({ id: 'created_at', desc: true });
    const [totalCount, setTotalCount] = useState(0);
    const [actionableAggregates, setActionableAggregates] = useState({});
    const [openSendMessage, setOpenSendMessage] = useState(false);
    const [openExportModal, setOpenExportModal] = useState();

    const [notification, setNotification] = useState({});

    const [selectedOrderIds, setSelectedOrderIds] = useState({});

    const { user, sidebarCollapsed } = useContext(UserContext);
    const { user_id, accountType, circles, isImposter } = useClientUser();

    const TABS = useTabs({ page: 'oms' });
    const NOW = new Date();

    // Reflects the "useRowHighlight" hook's filters for highlighting rows in the table.
    const highlightFilterMap = {
        CLAIMED: [{ claimed_date: { _lte: subDays(NOW, 7) } }],
        RECEIVED: [
            {
                wh_events: {
                    action: { _eq: 'START:RECEIVING' },
                    created_at: { _lte: subDays(NOW, 1) },
                },
            },
        ],
        DNF: [],
        DELIVERED: [{ delivery_date: { _lte: subDays(NOW, 2) } }],
        EXCEPTION_REPORTED: [
            {
                _or: [
                    {
                        exceptions: {
                            exception: {
                                status: { _neq: 'RESOLVED' },
                            },
                            created_at: { _lte: subDays(NOW, 1) },
                        },
                    },
                    {
                        itemsByOrderId: {
                            exceptions: {
                                exception: {
                                    status: { _neq: 'RESOLVED' },
                                },
                                created_at: { _lte: subDays(NOW, 1) },
                            },
                        },
                    },
                ],
            },
        ],
    };

    useEffect(() => {
        const tabValue = searchParams.get('tab') || null;
        if (tabValue) {
            let tabIndex = TABS.findIndex((tab) => tab.value === tabValue)
            setTabIndex(Math.max(tabIndex, 0));
        } else {
            setTabIndex(
                Math.max(
                    TABS.findIndex((tab) => tab.value === 'ALL'),
                    0
                )
            );
        }
    }, [TABS, searchParams]);

    const [getters] = useMemo(() => {
        const tuples = FILTER_ATTRS.map((attr) => [
            searchParams.get(attr),
            (val) => {
                setSearchParams((prev) => {
                    if (val) {
                        prev.set(attr, val);
                    } else {
                        prev.delete(attr);
                    }

                    return prev;
                });
            },
        ]);

        return [tuples.map(([getters]) => getters), tuples.map(([, setters]) => setters)];
    }, [searchParams]);

    const search = useMemo(() => {
        return searchOverride || searchParams.get('searchTerm') || '';
    }, [searchParams, searchOverride]);

    const getWhereFilters = useCallback(
        (tabIdx, extraFilters = null) => {
            const [
                startDate,
                endDate,
                schedDelStart,
                schedDelEnd,
                estShipStart,
                estShipEnd,
                actualReceivedStart,
                actualReceivedEnd,
                warehouseStartDate,
                warehouseEndDate,
                warehouseStatus,
                manufacturer,
                orderType,
                ownerChip,
                pickup,
                dropoff,
                volume,
                distance,
                scheduledStatus,
                shipper,
                middleMileStatus,
                serviceType,
                locationType,
                milestone,
            ] = getters;

            const searchTerm = searchParams.get('searchTerm');

            const whereFilters = [
                {
                    _or: [{ shipper_id: { _eq: user_id } }, { carrier_id: { _eq: user_id } }],
                },
                ...(TABS?.[tabIdx]?.filters || []),
            ];

            // Only show marketplace incompletes to the shipper
            if (TABS?.[tabIdx]?.value === 'INCOMPLETE') {
                whereFilters.push({
                    _or: [{ shipper_id: { _eq: user_id } }, { oms: { _eq: true } }],
                });
            }

            if (milestone) {
                const milestoneFilter = MILESTONE_OPTIONS.find((o) => o.value === milestone)?.filter;
                if (milestoneFilter) {
                    whereFilters.push(milestoneFilter);
                }
            }

            if (serviceType) {
                whereFilters.push({ service_level_id: { _eq: serviceType } });
            }

            if (locationType) {
                whereFilters.push({
                    _or: [
                        {
                            _and: [
                                { order_type: { _neq: 'return' } },
                                { dropoff_location_type: { _eq: locationType } },
                            ],
                        },
                        {
                            _and: [{ order_type: { _eq: 'return' } }, { pickup_location_type: { _eq: locationType } }],
                        },
                    ],
                });
            }

            if (startDate) {
                whereFilters.push({ created_at: { _gte: startDate } });
            }

            if (endDate) {
                whereFilters.push({ created_at: { _lt: addOneDay(endDate) } });
            }
            if (schedDelStart) {
                whereFilters.push({ delivery_date: { _gte: schedDelStart } });
            }

            if (schedDelEnd) {
                whereFilters.push({ delivery_date: { _lt: addOneDay(schedDelEnd) } });
            }

            if (estShipStart) {
                whereFilters.push({
                    wh_events: {
                        _and: [{ action: { _eq: 'START:RECEIVING' } }, { est_ship_date: { _gte: estShipStart } }],
                    },
                });
            }

            if (estShipEnd) {
                whereFilters.push({
                    wh_events: {
                        _and: [
                            { action: { _eq: 'START:RECEIVING' } },
                            { est_ship_date: { _lte: addOneDay(estShipEnd) } },
                        ],
                    },
                });
            }

            if (actualReceivedStart) {
                whereFilters.push({
                    wh_events: {
                        _and: [
                            { action: { _eq: 'START:RECEIVING' } },
                            { received_date: { _gte: actualReceivedStart } },
                        ],
                    },
                });
            }

            if (actualReceivedEnd) {
                whereFilters.push({
                    wh_events: {
                        _and: [{ action: { _eq: 'START:RECEIVING' } }, { received_date: { _gte: actualReceivedEnd } }],
                    },
                });
            }

            if (warehouseStartDate) {
                whereFilters.push({
                    wh_events: {
                        _and: [
                            { action: { _eq: 'START:RECEIVING' } },
                            { est_received_date: { _gte: warehouseStartDate } },
                        ],
                    },
                });
            }

            if (warehouseEndDate) {
                whereFilters.push({
                    wh_events: {
                        _and: [
                            { action: { _eq: 'START:RECEIVING' } },
                            { est_received_date: { _lte: addOneDay(warehouseEndDate) } },
                        ],
                    },
                });
            }

            if (warehouseStatus && warehouseStatus === 'NOT_DELIVERED') {
                whereFilters.push({
                    _not: {
                        wh_events: {
                            action: { _eq: 'START:RECEIVING' },
                            status: { _eq: 'RECEIVED' },
                        },
                    },
                });
            } else if (warehouseStatus) {
                whereFilters.push({
                    wh_events: { _and: [{ action: { _eq: 'START:RECEIVING' } }, { status: { _eq: warehouseStatus } }] },
                });
            }

            if (manufacturer) {
                whereFilters.push({ manufacturer: { _eq: manufacturer } });
            }

            if (orderType) {
                whereFilters.push({ order_type: { _eq: orderType } });
            }

            if (searchTerm) {
                whereFilters.push({
                    _or: searchable.map((searchField) => {
                        const [col, nested] = searchField.split('.');
                        if (!!nested) {
                            return { [col]: { _contains: { [nested]: searchTerm } } };
                        }
                        return { [searchField]: { _ilike: `%${searchTerm}%` } };
                    }),
                });
            }

            if (ownerChip) {
                switch (ownerChip) {
                    case 'Internal':
                        // INTERNAL ORDERS
                        whereFilters.push({
                            _and: [
                                { oms: { _eq: true } },
                                { shipper_id: { _eq: user_id } },
                                { _or: [{ carrier_id: { _is_null: true } }, { carrier_id: { _eq: user_id } }] },
                            ],
                        });
                        break;
                    case 'Onward':
                        // ORDERS SENT TO MARKETPLACE
                        whereFilters.push({
                            _or: [
                                {
                                    _and: [
                                        { oms: { _eq: false } },
                                        { shipper_id: { _eq: user_id } },
                                        {
                                            _or: [
                                                { carrier_id: { _is_null: true } },
                                                { carrier_id: { _neq: user_id } },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    _and: [
                                        { shipper_id: { _eq: user_id } },
                                        { carrier_id: { _is_null: false } },
                                        { carrier_id: { _neq: user_id } },
                                    ],
                                },
                            ],
                        });
                        break;
                    case 'Claimed':
                        //ORDERS CLAIMED FROM MARKETPLACE
                        whereFilters.push({
                            _or: [
                                { _and: [{ oms: { _eq: false } }, { carrier_id: { _eq: user_id } }] },
                                { _and: [{ shipper_id: { _neq: user_id } }, { carrier_id: { _eq: user_id } }] },
                            ],
                        });
                        break;
                }
            }
            if (shipper) {
                whereFilters.push({ shipper_id: { _eq: shipper } });
            }

            if (pickup) {
                const [city, state, zip] = pickup.split('|');
                whereFilters.push(
                    { pickup_city: { _eq: city.trim() } },
                    { pickup_state: { _eq: state.trim() } },
                    { pickup_zip: { _eq: zip.trim() } }
                );
            }

            if (dropoff) {
                const [city, state, zip] = dropoff.split('|');
                whereFilters.push(
                    { dropoff_city: { _eq: city.trim() } },
                    { dropoff_state: { _eq: state.trim() } },
                    { dropoff_zip: { _eq: zip.trim() } }
                );
            }

            if (volume) {
                const [minVolume, maxVolume] = volume.split('|');
                whereFilters.push({
                    _or: [
                        {
                            _and: [
                                { order_type: { _eq: 'return' } },
                                { returns_total_cubes: { _lte: parseInt(maxVolume) } },
                                { returns_total_cubes: { _gte: parseInt(minVolume) } },
                            ],
                        },
                        {
                            _and: [
                                { order_type: { _eq: 'delivery' } },
                                { items_total_cubes: { _lte: parseInt(maxVolume) } },
                                { items_total_cubes: { _gte: parseInt(minVolume) } },
                            ],
                        },
                        {
                            _and: [
                                { order_type: { _eq: 'exchange' } },
                                {
                                    _or: [
                                        {
                                            _and: [
                                                { returns_total_cubes: { _lte: parseInt(maxVolume) } },
                                                { returns_total_cubes: { _gte: parseInt(minVolume) } },
                                            ],
                                        },
                                        {
                                            _and: [
                                                { items_total_cubes: { _lte: parseInt(maxVolume) } },
                                                { items_total_cubes: { _gte: parseInt(minVolume) } },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                });
            }

            if (distance) {
                const [minDistance, maxDistance] = distance.split('|');
                whereFilters.push(
                    { miles: { _lte: parseInt(maxDistance) } },
                    { miles: { _gte: parseInt(minDistance) } }
                );
            }

            if (scheduledStatus) {
                whereFilters.push({ delivery_date: { _is_null: scheduledStatus === 'Unscheduled' } });
            }

            if (middleMileStatus) {
                whereFilters.push({
                    middle_mile_integration_status:
                        middleMileStatus === 'NONE' ? { _is_null: true } : { _eq: middleMileStatus },
                });
            }

            if (extraFilters?.length) {
                whereFilters.push({ _and: extraFilters });
            }

            return { _and: whereFilters };
        },
        [getters, searchParams, TABS]
    );

    const where = useMemo(() => {
        return getWhereFilters(tabIndex);
    }, [getWhereFilters, tabIndex]);

    const order_by = useMemo(() => {
        const ascOrDesc = sortBy?.desc ? 'desc_nulls_last' : 'asc_nulls_last';
        switch (sortBy?.id) {
            case 'deldate':
                return [{ delivery_date: ascOrDesc }, { order_number: 'desc_nulls_last' }];
            case 'est_ship_date':
                return [{ warehouse_estimated_ship_date: ascOrDesc }, { order_number: 'desc_nulls_last' }];
            case 'est_received_date':
                return [{ warehouse_estimated_delivery_date: ascOrDesc }, { order_number: 'desc_nulls_last' }];
            case 'received_date':
                return [{ warehouse_actual_received_date: ascOrDesc }, { order_number: 'desc_nulls_last' }];
            case 'po':
                return [{ po_number: ascOrDesc }];
            case 'ordernum':
                return [{ created_at: ascOrDesc }];
            case 'created_at':
                return [{ created_at: ascOrDesc }];
            case 'dropoff':
                return [
                    { dropoff_state: ascOrDesc },
                    { dropoff_city: ascOrDesc },
                    { dropoff_zip: ascOrDesc },
                    { order_number: 'desc_nulls_last' },
                ];
            case 'warehousestatus':
                return [{ warehouse_delivery_status: ascOrDesc }];
            default:
                return [{ created_at: 'desc_nulls_last' }, { order_number: 'desc_nulls_last' }];
        }
    }, [sortBy]);

    const PAGE_SIZE = 100;
    const [page, setPage] = useState(0);

    const { loading: loadingOrdersByUserId, data } = useQuery(QUERY_ORDERS_WITH_DRIVERS_BY_CARRIER_ID_OR_SHIPPER_ID, {
        variables: {
            limit: PAGE_SIZE,
            offset: page * PAGE_SIZE,
            where,
            order_by,
        },
        onCompleted: (data) => {
            setTotalCount(data.orders_aggregate.aggregate.totalCount);
        },
        onError: (error) => {
            captureException(error);
            console.log(error);
        },
    });

    const { data: shipperData } = useQuery(QUERY_ORDER_SHIPPERS, { variables: { user_id } });
    const shipperOptions = useMemo(() => {
        return (shipperData?.orders || []).map((o) => ({
            value: o.shipper_id,
            label: o.order_shipper.business_name,
        }));
    }, [shipperData]);

    const [getActionableOrderCounts] = useLazyQuery(QUERY_ACTIONABLE_ORDER_AGGREGATES);
    useEffect(() => {
        const fetchAggregates = async () => {
            let result = {};
            for (const [idx, tab] of TABS.entries()) {
                if (!tab.red) continue;

                const extraFilters = highlightFilterMap?.[tab?.value] || [];
                const whereFilters = getWhereFilters(idx, extraFilters);

                try {
                    let { data } = await getActionableOrderCounts({
                        variables: {
                            where: whereFilters,
                        },
                    });
                    let count = data.orders_aggregate.aggregate.totalCount;
                    result[tab.label] = count;
                } catch (err) {
                    console.log(err);
                    captureException(err);
                }
            }
            setActionableAggregates(result);
        };

        fetchAggregates();
    }, [TABS, getWhereFilters]);

    const [updateOrders, { loading: updateLoading }] = useMutation(UPDATE_ORDERS_MANY, {
        onError: (error) => {
            captureException(error);
            console.error(error);
            setNotification({ severity: 'error', message: 'Error updating orders' });
        },
    });

    const [getOrdersByNumber, { loading: preupdateLoading }] = useLazyQuery(QUERY_ORDERS_BY_OD_OR_PO, {
        onError: (error) => {
            captureException(error);
            console.error(error);
            setNotification({ severity: 'error', message: 'Error finding orders' });
        },
    });

    const [insertEvents, { loading: insertEventsLoading }] = useMutation(INSERT_EVENTS, {
        refetchQueries: [QUERY_ORDERS_WITH_DRIVERS_BY_CARRIER_ID_OR_SHIPPER_ID],
        awaitRefetchQueries: true,
        onError: (error) => {
            captureException(error);
            console.error(error);
            setNotification({ severity: 'error', message: 'Error receiving orders' });
        },
    });

    const { loading: loadingLocationsByUserId, data: dropoffLocations } = useQuery(
        QUERY_DROPOFF_LOCATIONS_WITH_DRIVERS_BY_CARRIER_ID_OR_SHIPPER_ID,
        {
            variables: {
                user_id,
            },
            onCompleted: (dropoffLocations) => {
                getOrderLocations(dropoffLocations.orders);
            },
            onError: (error) => {
                captureException(error);
                console.log(error);
            },
        }
    );

    const [fetchOverrides, { loading: exportInflight }] = useLazyQuery(GET_PRICING_OVERRIDES);

    const getOrderLocations = async (orders) => {
        let pulocationMap = {};
        let dolocationMap = {};

        orders.forEach((order) => {
            const pickupLocationKey = `${order.pickup_city}|${order.pickup_state}|${order.pickup_zip}`;
            if (!pulocationMap[pickupLocationKey]) {
                pulocationMap[pickupLocationKey] = {
                    value: pickupLocationKey,
                    label: `${order.pickup_city}, ${order.pickup_state}  ${order.pickup_zip}`,
                };
            }

            const dropoffLocationKey = `${order.dropoff_city}|${order.dropoff_state}|${order.dropoff_zip}`;
            if (!dolocationMap[dropoffLocationKey]) {
                dolocationMap[dropoffLocationKey] = {
                    value: dropoffLocationKey,
                    label: `${order.dropoff_city}, ${order.dropoff_state}  ${order.dropoff_zip}`,
                };
            }
        });
        setOrderLocations({
            pickupKeys: Object.keys(pulocationMap)
                .sort()
                .map((key) => pulocationMap[key]),
            dropoffKeys: Object.keys(dolocationMap)
                .sort()
                .map((key) => dolocationMap[key]),
        });
    };

    const allOrders = useMemo(() => {
        return data?.orders || [];
    }, [data]);

    const exportOrderRevenue = (order) => {
        return calcOrderPricing(order, user_id, circles);
    };

    const exportOrderOwner = (order) => {
        return calcOrderOwner(order, user_id);
    };

    const csvRowData = useMemo(() => {
        const ordersById = Object.fromEntries((allOrders || []).map((order) => [order.order_id, order]));
        const orderData = Object.entries(selectedOrderIds)
            .filter(([_, selected]) => selected)
            .reduce((acc, [order_id]) => {
                const order = ordersById[order_id];
                if (!order) return acc;
                let revenue = exportOrderRevenue(order);
                let owner = exportOrderOwner(order);
                let routeLabel =
                    order.routes && order.routes.length > 0
                        ? order.routes
                              .map((routeMapping) => routeMapping.route.route_alias || routeMapping.route.route_number)
                              .join(', ')
                        : '';

                const rows = order.itemsByOrderId.map((item) => {
                    return { order, item, revenue, owner, routeLabel, accountType };
                });
                return [...acc, ...rows];
            }, []);
        return { orderData: orderData };
    }, [selectedOrderIds, allOrders, accountType]);

    const manufacturersList = useMemo(() => {
        let manufacturerSet = new Set();
        allOrders.forEach((order) => {
            if (order.manufacturer) {
                manufacturerSet.add({
                    value: order.manufacturer,
                    label: order.manufacturer,
                });
            }
        });

        return [...manufacturerSet];
    }, [allOrders]);

    const handleSearch = (e) => {
        const searchText = e.target.value;
        setSearch(searchText);
        if (debounceTimer) {
            clearTimeout(debounceTimer);
        }
        setDebounceTimer(
            setTimeout(() => {
                setSearchParams((prev) => {
                    if (searchText) {
                        prev.set('searchTerm', searchText);
                    } else {
                        prev.delete('searchTerm');
                    }

                    return prev;
                });
            }, 300)
        );
    };

    const handleTabChange = (e, tab) => {
        const tabValue = TABS[tab].value;
        setTabIndex(tab);
        setSearchParams((prev) => {
            prev.set('tab', tabValue);
            return prev;
        });
    };


    const handleSortBy = (newSortBy) => {
        if (newSortBy?.id !== sortBy.id || newSortBy.desc !== sortBy.desc) {
            setSortBy(newSortBy);
            setPage(0);
        }
    };

    const handlePage = (_, p) => {
        const pageIdx = p - 1;
        setPage(pageIdx);
    };

    const receiveSelectedOrders = () => {
        const NOW = new Date();
        const events = Object.entries(selectedOrderIds)
            .filter(([_, selected]) => !!selected)
            .map(([order_id]) => ({
                action: 'START:RECEIVING',
                status: 'RECEIVED',
                order_id,
                received_date: NOW.toISOString(),
            }));

        insertEvents({
            variables: { events },
            onCompleted: ({ insert_order_wh_events }) => {
                setNotification({
                    severity: 'success',
                    message: `Received ${insert_order_wh_events.returning.length} orders`,
                });
            },
        });
    };

    const [sendMessage, { loading: sendingMessage }] = useAction(SEND_MESSAGES, {
        onComplete: () => {
            setNotification({
                severity: 'success',
                message: 'Messages Sent!',
            });
        },
        onError: (err) => {
            console.error(err);
            setNotification({
                severity: 'error',
                message: 'Failed to send messages. Please try again.',
            });
        },
    });

    const exportSelectedOrders = () => {
        setOpenExportModal(true);
    };

    const csvExportColumns = {
        Order: ORDER_EXPORT_COLUMNS,
    };

    const [insertTemplate, { loading: insertTemplateLoading }] = useMutation(INSERT_CSV_EXPORT_TEMPLATE, {
        onError: (error) => {
            captureException(error);
            console.log(error.message);
        },
    });

    const bulkUpdateOrders = (e) => {
        try {
            const file = e.target.files?.[0];
            Papa.parse(file, {
                complete: ({ data }) => {
                    try {
                        const [headers, ...body] = data;

                        const updates = body.map((row, i) => {
                            const raw = zipObject(headers, row);
                            const [formatted, errors] = CSV_BULK_UPDATE_COLUMNS.reduce(
                                ([formattedAcc, errorsAcc], column) => {
                                    const cell = raw[column.header];
                                    const next = {
                                        ...formattedAcc,
                                        ...(cell
                                            ? {
                                                  [column.key]: column.value(cell, formattedAcc),
                                              }
                                            : {}),
                                    };
                                    return [next, [...errorsAcc, ...(column.valid(next) ? [] : [column.errorMsg])]];
                                },
                                [{}, []]
                            );

                            if (errors.length > 0) {
                                throw new Error(`Errors in CSV row ${i + 2}: ${errors.join(', ')}`);
                            }

                            return {
                                ...formatted,
                                ...(formatted.completion_time
                                    ? {
                                          order_status: 'complete',
                                          completion_source: `WEB_${isImposter ? 'ONWARD_ADMIN' : 'CARRIER'}`,
                                      }
                                    : {}),
                            };
                        });

                        getOrdersByNumber({
                            variables: {
                                user_id,
                                po_numbers: updates.reduce((acc, update) => {
                                    return update.po_number ? [...acc, update.po_number] : acc;
                                }, []),
                                order_numbers: updates.reduce((acc, update) => {
                                    return update.order_number ? [...acc, update.order_number] : acc;
                                }, []),
                            },
                        }).then(({ data }) => {
                            const [odMap, poMap] = (data?.orders || []).reduce(
                                ([odAcc, poAcc], order) => {
                                    return [
                                        { ...odAcc, [order.order_number]: order },
                                        { ...poAcc, [order.po_number]: order },
                                    ];
                                },
                                [{}, {}]
                            );

                            const missing = updates.filter(
                                (update) => !odMap[update.order_number] && !poMap[update.po_number]
                            );
                            if (missing.length > 0) {
                                setNotification({
                                    severity: 'error',
                                    message: `Cannot find orders: ${missing
                                        .map((update) => update.order_number || update.po_number)
                                        .join(', ')}`,
                                });
                                return;
                            }

                            updateOrders({
                                variables: {
                                    updates: updates.map(({ received_date, po_number, order_number, ...update }) => ({
                                        where: {
                                            order_id: {
                                                _eq: odMap[order_number]?.order_id || poMap[po_number]?.order_id,
                                            },
                                        },
                                        _set: update,
                                    })),
                                    events: updates
                                        .filter((update) => !!update.received_date)
                                        .map((update) => ({
                                            action: 'START:RECEIVING',
                                            status: 'RECEIVED',
                                            received_date: update.received_date,
                                            order_id:
                                                odMap[update.order_number]?.order_id ||
                                                poMap[update.po_number]?.order_id,
                                        })),
                                },
                            });
                        });
                    } catch (error) {
                        console.error(error);
                        setNotification({ severity: 'error', message: error.message || 'Error processing CSV file' });
                    }
                },
                error: (error) => {
                    throw error;
                },
            });
        } catch (error) {
            console.error(error);
            setNotification({ severity: 'error', message: error.message || 'Error processing CSV file' });
        }
    };

    const tabScrollComponent = ({ style, onClick, ...props }, b) => {
        const getAlertDisplay = (direction) => {
            let display = false;

            const alertDisplayCheck = Object.entries(actionableAggregates).some(([label, count]) => {
                if (count <= 0) return false;
                const tabIdx = TABS.findIndex((t) => t.label === label);
                return direction === 'right' ? tabIdx > tabIndex : tabIdx < tabIndex;
            });

            if (alertDisplayCheck) {
                display = true;
            }
            return display;
        };

        let displayRightAlert = getAlertDisplay('right');
        let displayLeftAlert = getAlertDisplay('left');

        return (
            <>
                <div
                    css={css`
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        ${props.direction === 'right' &&
                        `
                        padding-left: 12px;
                        border-left: 2px solid lightgray;
                    `}
                        ${props.direction === 'left' &&
                        `
                        padding-right: 12px;
                        border-right: 2px solid lightgray;
                    `}
                    ${props.disabled && 'opacity: 0;'}
                    `}
                    onClick={onClick}
                >
                    {displayRightAlert && props.direction === 'right' && (
                        <NotificationImportantIcon
                            css={css`
                                color: red;
                            `}
                        />
                    )}
                    <TabScrollButton
                        sx={{
                            color: 'red',
                            fontSize: 'large',
                        }}
                        style={{
                            fontSize: 'large',
                        }}
                        {...props}
                        css={css`
                            & svg {
                                font-size: 2.5rem;
                                color: black;
                            }
                        `}
                    />
                    {displayLeftAlert && props.direction === 'left' && (
                        <NotificationImportantIcon
                            css={css`
                                color: red;
                            `}
                        />
                    )}
                </div>
            </>
        );
    };

    return (
        <ThemeProvider theme={theme}>
            <Box
                className={`${sidebarCollapsed && 'collapse-margin'}  table-height`}
                sx={{ flexGrow: 1, padding: 0, height: 'calc(100vh - 50px)' }}
            >
                <Snackbar
                    open={!!notification?.message}
                    {...(notification || {})}
                    handleClose={() => setNotification({})}
                />
                <Grid container direction="column" wrap="nowrap" className="h-100">
                    <Grid container justifyContent="center" className="bg-white border-bottom">
                        <OnwardTabContainer
                            value={tabIndex}
                            onChange={handleTabChange}
                            textColor="primary"
                            indicatorColor="primary"
                            css={css`
                                background-color: white;
                            `}
                            ScrollButtonComponent={tabScrollComponent}
                        >
                            {TABS.map((tab, idx) => (
                                <OnwardTab
                                    label={tab.label}
                                    value={idx}
                                    style={{ outline: 0 }}
                                    key={`tab-${idx}`}
                                    error={tab.red && actionableAggregates?.[tab?.label] > 0}
                                    {...(tab.red && actionableAggregates?.[tab?.label] > 0
                                        ? {
                                              icon: <NotificationImportantIcon fontSize="small" />,
                                          }
                                        : {})}
                                />
                            ))}
                        </OnwardTabContainer>
                    </Grid>
                    <PrimaryHeaderContainer item>
                        <Grid container justifyContent="space-between">
                            <Grid item>
                                <PrimaryHeaderLabel>My Orders</PrimaryHeaderLabel>
                            </Grid>
                            <Grid item>
                                <TextField
                                    value={search}
                                    placeholder="Search orders"
                                    onChange={handleSearch}
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    className="me-3"
                                    InputProps={{
                                        style: { backgroundColor: 'white' },
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Search />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <FilterPopover
                                    applyFilters={(values) => {
                                        setSearchParams((prev) => {
                                            Object.entries(values).forEach(([attr, val]) => {
                                                if (val) {
                                                    prev.set(attr, val);
                                                } else {
                                                    prev.delete(attr);
                                                }
                                            });

                                            return prev;
                                        });
                                    }}
                                    email={user?.email}
                                    userType={accountType}
                                    locations={orderLocations}
                                    shippers={shipperOptions}
                                    manufacturersList={manufacturersList}
                                    searchParams={searchParams}
                                    user_id={user_id}
                                />
                                <PrimaryButton
                                    variant="contained"
                                    color="primary"
                                    onClick={receiveSelectedOrders}
                                    css={css`
                                        margin-left: 1rem;
                                        height: 40px;
                                    `}
                                    endIcon={<WarehouseIcon />}
                                    disabled={insertEventsLoading}
                                >
                                    Receive
                                </PrimaryButton>
                                <PrimaryButton
                                    variant="contained"
                                    color="primary"
                                    onClick={() => setOpenSendMessage(true)}
                                    css={css`
                                        margin-left: 1rem;
                                        height: 40px;
                                    `}
                                    endIcon={<MessageIcon />}
                                    disabled={sendingMessage}
                                >
                                    Message
                                </PrimaryButton>
                                <SendMessageModal
                                    open={openSendMessage}
                                    callbacks={{
                                        onClose: () => setOpenSendMessage(false),
                                        sendMessage: (message) => {
                                            const order_ids = Object.entries(selectedOrderIds)
                                                .filter(([_, selected]) => selected)
                                                .map(([order_id]) => order_id);
                                            return sendMessage({ message, order_ids });
                                        },
                                    }}
                                />
                                <PrimaryButton
                                    variant="contained"
                                    color="primary"
                                    disabled={updateLoading || preupdateLoading}
                                    component="label"
                                    css={css`
                                        margin-left: 1rem;
                                        height: 40px;
                                    `}
                                    endIcon={
                                        updateLoading || preupdateLoading ? (
                                            <CircularProgress size="24px" color="white" />
                                        ) : (
                                            <FileUploadIcon />
                                        )
                                    }
                                >
                                    Update
                                    <input
                                        disabled={updateLoading}
                                        type="file"
                                        hidden
                                        accept=".csv"
                                        onChange={bulkUpdateOrders}
                                    />
                                </PrimaryButton>
                                <PrimaryButton
                                    variant="contained"
                                    color="primary"
                                    disabled={!Object.keys(selectedOrderIds).length}
                                    onClick={exportSelectedOrders}
                                    css={css`
                                        margin-left: 1rem;
                                        height: 40px;
                                    `}
                                    endIcon={<FileDownloadIcon />}
                                >
                                    Export
                                </PrimaryButton>
                            </Grid>
                        </Grid>
                    </PrimaryHeaderContainer>
                    {loadingOrdersByUserId && (
                        <Grid item className="ms-5 me-5">
                            <LinearProgress
                                color="primary"
                                css={css`
                                    width: 100%;
                                `}
                            />
                        </Grid>
                    )}
                    <Grid item className="ms-5 me-5 bg-white" style={{ overflow: 'hidden', flexGrow: 1 }}>
                        <OrdersTable
                            orders={allOrders}
                            selectedOrderIds={selectedOrderIds}
                            sortBy={[sortBy]}
                            callbacks={{
                                handleSortBy,
                                setSelectedOrderIds,
                            }}
                            tab={TABS[tabIndex]}
                        />
                    </Grid>
                    <Grid
                        container
                        className="m-5"
                        justifyContent="center"
                        css={css`
                            width: auto;
                        `}
                    >
                        <Pagination
                            variant="outlined"
                            shape="rounded"
                            css={css`
                                .Mui-selected {
                                    background-color: ${colors.greens.primary};
                                    color: white;
                                }
                            `}
                            count={Math.ceil(totalCount / PAGE_SIZE)}
                            page={page + 1}
                            onChange={handlePage}
                        />
                    </Grid>
                </Grid>
            </Box>

            <ExportCsvTemplateModal
                open={openExportModal}
                setOpen={setOpenExportModal}
                exportType={'ORDER'}
                csvExportColumns={csvExportColumns}
                callbacks={{
                    onExport: async (selectedColumns, templateName, exportType, includeAccessorials) => {
                        if (templateName) {
                            insertTemplate({
                                variables: {
                                    object: {
                                        name: templateName,
                                        user_id: user_id,
                                        export_type: exportType,
                                        template: {
                                            ...selectedColumns,
                                            Order: {
                                                ...selectedColumns.Order,
                                                ...(includeAccessorials && { Accessorials: true }),
                                            },
                                        },
                                    },
                                },
                            });
                        }

                        if (includeAccessorials) {
                            let potentialAccessorials;
                            await fetchOverrides({
                                variables: {
                                    shipper_ids: csvRowData.orderData.map((row) => row.order.shipper_id),
                                    carrier_ids: csvRowData.orderData.map((row) => row.order.carrier_id),
                                    client_ids: [user_id],
                                    partner_client_ids: [],
                                    retailer_ids: [],
                                },
                            }).then(({ data }) => {
                                const {
                                    carrier_defaults = [],
                                    carrier = [],
                                    internal = [],
                                    shipper_defaults = [],
                                    shipper = [],
                                } = data || {};

                                let types = [
                                    ...carrier_defaults,
                                    ...carrier,
                                    ...shipper,
                                    ...shipper_defaults,
                                    ...internal,
                                ]
                                    .map((override) => override.algo_type)
                                    .filter((type) => type);

                                if (types.length === 0) {
                                    types = ['DEFAULT'];
                                }

                                potentialAccessorials = types.reduce((acc, type) => {
                                    return [...acc, ...genAccessorials(type)];
                                }, []);
                            });

                            const getBreakdown = (order) => {
                                return order.oms
                                    ? order?.price_breakdown?.internalBreakdown
                                    : user_id === order.shipper_id
                                    ? order?.price_breakdown?.shipperBreakdown
                                    : order?.price_breakdown?.carrierBreakdown;
                            };

                            let maxAccessorialCount = csvRowData.orderData.reduce((acc, row) => {
                                const priceBreakdown = getBreakdown(row.order);

                                const accessorialCount = priceBreakdown?.accessorials?.length || 0;
                                return Math.max(acc, accessorialCount);
                            }, 1);

                            const accCols = [
                                {
                                    header: 'Base Rate',
                                    value: ({ order }) => {
                                        const priceBreakdown = getBreakdown(order);
                                        return priceBreakdown?.base_charge || '-';
                                    },
                                },
                                ...Array(maxAccessorialCount)
                                    .fill(0)
                                    .flatMap((_, idx) => [
                                        {
                                            header: `Accessorial ${idx + 1} Type`,
                                            value: ({ order }) => {
                                                const priceBreakdown = getBreakdown(order);
                                                const accessorialType = priceBreakdown?.accessorials[idx]?.type;
                                                if (!accessorialType) return '-';
                                                const accessorial = potentialAccessorials.find(
                                                    (a) => a.type === accessorialType
                                                );
                                                return accessorial?.label || accessorialType;
                                            },
                                        },
                                        {
                                            header: `Accessorial ${idx + 1} Subtotal`,
                                            value: ({ order }) => {
                                                const priceBreakdown = getBreakdown(order);
                                                return priceBreakdown?.accessorials[idx]?.quantity
                                                    ? `$${Number(priceBreakdown?.accessorials[idx]?.quantity).toFixed(
                                                          2
                                                      )}`
                                                    : '-';
                                            },
                                        },
                                    ]),
                            ];

                            csvExportColumns['Order'] = [...csvExportColumns['Order'], ...accCols];
                        }

                        // Filter out duplicate orders if they don't want item info
                        let filteredOrderData = csvRowData.orderData;
                        if (!selectedColumns.Order.Item) {
                            const uniqueOrders = new Set();
                            filteredOrderData = csvRowData.orderData.filter(({ order }) => {
                                if (uniqueOrders.has(order.order_id)) {
                                    return false;
                                }
                                uniqueOrders.add(order.order_id);
                                return true;
                            });
                        }

                        Object.keys(csvExportColumns).forEach((colType) => {
                            const finalExportCols = csvExportColumns[colType].filter(
                                (col) =>
                                    col.header === 'Base Rate' ||
                                    col.header.toLowerCase().includes('accessorial') ||
                                    selectedColumns[colType][col.header]
                            );
                            if (finalExportCols.length > 0) {
                                csvDownload({
                                    headers: finalExportCols.map((col) => col.header),
                                    data: filteredOrderData.map((row) => finalExportCols.map((col) => col.value(row))),
                                    filename: `onward-${colType}Export-${new Date().toISOString()}.csv`,
                                    delimiter: ',',
                                });
                            }
                        });
                    },
                }}
            />
        </ThemeProvider>
    );
};

export default MyOrders;
