import { PALLET_STATUSES, RECEIVED_STATUSES } from '@/constants/manifestStatuses';
import { toArrayLiteral } from '@/utilities/toArrayLiteral';
import { restoreDashPattern } from 'pdf-lib';
import { useCallback } from 'react';

export const useManifestCallbacks = (state, callbacks) => {
    const receiveItem = useCallback((item, manifest) => {
        const RECEIVE_EVENTS = ['START:RECEIVING', 'CD_PENDING_PO:PICKING_UP'];
        let event;
        if (
            (item.order?.itemsByOrderId || []).every(
                (otherItem) =>
                    otherItem.item_id === item.item_id || RECEIVED_STATUSES.includes(otherItem.pallet?.warehouse_status)
            )
        ) {
            event = (item.order?.wh_events || []).reduce(
                (acc, { __typename, exception, transition, ...rest }) => {
                    if (RECEIVE_EVENTS.includes(rest.action)) {
                        return {
                            ...rest,
                            received_date: new Date().toISOString(),
                            status: 'RECEIVED',
                        };
                    }
                    return acc;
                },
                {
                    action: 'START:RECEIVING',
                    order_id: item.order_id,
                    received_date: new Date().toISOString(),
                    status: 'RECEIVED',
                }
            );
        }
        callbacks.insertPallet({
            variables: {
                item_id: item.item_id,
                pallet: {
                    type: 'SINGLE',
                    warehouse_id: manifest.warehouse_id,
                    warehouse_location: null,
                    warehouse_status: 'RECEIVED',
                },
                ...(event ? { events: [event] } : {}),
            },
        });
    }, []);

    const receivePallet = useCallback((item, pallet, manifest) => {
        const RECEIVE_EVENTS = ['START:RECEIVING', 'CD_PENDING_PO:PICKING_UP'];
        let event;
        if (
            (item.order?.itemsByOrderId || []).every(
                (otherItem) =>
                    otherItem.pallet_id === pallet.pallet_id ||
                    RECEIVED_STATUSES.includes(otherItem.pallet?.warehouse_status)
            )
        ) {
            event = (item.order?.wh_events || []).reduce(
                (acc, { __typename, exception, transition, ...rest }) => {
                    if (RECEIVE_EVENTS.includes(rest.action)) {
                        return {
                            ...rest,
                            received_date: new Date().toISOString(),
                            status: 'RECEIVED',
                        };
                    }
                    return acc;
                },
                {
                    action: 'START:RECEIVING',
                    order_id: item.order_id,
                    received_date: new Date().toISOString(),
                    status: 'RECEIVED',
                }
            );
        }
        callbacks.updatePallet({
            variables: {
                pallet_id: pallet.pallet_id,
                update: {
                    warehouse_id: manifest.warehouse_id,
                    warehouse_location: null,
                    warehouse_status: PALLET_STATUSES.RECEIVED,
                },
                ...(event ? { events: [event] } : {}),
            },
        });
    }, []);

    const preconsolidateItems = useCallback((items, manifest) => {
        callbacks.insertEmptyPallet({
            variables: {
                object: {
                    type: 'CONSOLIDATED',
                    warehouse_id: manifest.warehouse_id,
                    warehouse_location: null,
                    warehouse_status: 'NOT_RECEIVED',
                    pallet_name: `${items[0].order.order_number} consolidated label`,
                },
            },
            onCompleted: ({ insert_pallet_items_one }) => {
                const itemUpdates = items.map((item) => ({
                    where: { item_id: { _eq: item.item_id } },
                    _set: { pallet_id: insert_pallet_items_one.pallet_id },
                }));

                callbacks.updateItemPallets({
                    variables: {
                        itemUpdates: itemUpdates,
                        palletUpdates: [],
                        palletsToDelete: [],
                    },
                });
            },
        });
    }, []);

    const renamePallet = useCallback((pallets, newName) => {
        return callbacks.updatePallets({
            variables: {
                updates: pallets.map((pallet) => ({
                    where: { pallet_id: { _eq: pallet.pallet_id } },
                    _set: {
                        pallet_name: newName,
                    },
                })),
            },
            onError: (error) => {
                callbacks.onError(`Failed to rename pallet`)(error);
            },
        });
    }, []);

    const storePallets = useCallback((pallets) => {
        return callbacks.updatePallets({
            variables: {
                updates: pallets.map((pallet) => ({
                    where: { pallet_id: { _eq: pallet.pallet_id } },
                    _set: {
                        warehouse_location: pallet.warehouse_location,
                        warehouse_status: PALLET_STATUSES.STORED,
                        is_pool: pallet.is_pool,
                    },
                })),
            },
            onError: (error) => {
                if (error.message.includes('pallet_locations_unique')) {
                    callbacks.setNotification({ severity: 'error', message: 'Bin location is already occupied' });
                } else {
                    callbacks.onError(
                        `Failed to store ${pallets.some((pallet) => pallet.type === 'SINGLE') ? 'item' : 'pallet'}`
                    )(error);
                }
            },
        });
    }, []);

    const saveStagingZone = useCallback((manifest, stagingZone) => {
        return callbacks.updateManifest({
            variables: {
                manifest_id: manifest.manifest_id,
                update: {
                    staging_zone: stagingZone,
                },
            },
            onError: (error) => {
                callbacks.onError(`Failed to set staging zone`)(error);
            },
        });
    }, []);

    const consolidatePallets = useCallback(
        (newPallets) => {
            const itemUpdates = [];
            const palletUpdates = [];
            const palletsToDelete = [];
            newPallets.forEach((pallet) => {
                if (pallet.items.length === 0) {
                    palletsToDelete.push(pallet.pallet_id);
                } else {
                    pallet.items.forEach((item) =>
                        itemUpdates.push({
                            where: { item_id: { _eq: item.item_id } },
                            _set: { pallet_id: pallet.pallet_id },
                        })
                    );
                    if (pallet.items.length === 1) {
                        palletUpdates.push({
                            where: { pallet_id: { _eq: pallet.pallet_id } },
                            _set: { type: 'SINGLE', warehouse_status: 'RECEIVED', warehouse_location: null },
                        });
                    } else {
                        palletUpdates.push({
                            where: { pallet_id: { _eq: pallet.pallet_id } },
                            _set: { type: 'CONSOLIDATED', warehouse_status: 'RECEIVED', warehouse_location: null },
                        });
                    }
                }
            });

            return callbacks.updateItemPallets({
                variables: {
                    itemUpdates: itemUpdates,
                    palletUpdates: palletUpdates,
                    palletsToDelete: palletsToDelete,
                },
            });
        },
        [state.manifest]
    );

    const stagePallets = useCallback((pallets) => {
        return callbacks.updatePallets({
            variables: {
                updates: [
                    {
                        where: { pallet_id: { _in: pallets.map((pallet) => pallet.pallet_id) } },
                        _set: {
                            warehouse_location: null,
                            warehouse_status: PALLET_STATUSES.STAGED,
                        },
                    },
                ],
            },
            onError: callbacks.onError('Failed to stage pallets'),
        });
    }, []);

    const pickupPallets = useCallback((pallets) => {
        return callbacks.updatePallets({
            variables: {
                updates: [
                    {
                        where: { pallet_id: { _in: pallets.map((pallet) => pallet.pallet_id) } },
                        _set: {
                            warehouse_location: null,
                            warehouse_status: PALLET_STATUSES.PICKED_UP,
                        },
                    },
                ],
            },
            onError: callbacks.onError('Failed to pick up pallets'),
        });
    }, []);

    const reportException = useCallback(({ exception, items }) => {
        const { __typename, items: existingItems, order, images, ...rest } = exception;

        if (exception.exception_id) {
            callbacks.updateException({
                variables: {
                    exception_id: exception.exception_id,
                    exception: {
                        ...rest,
                        images: toArrayLiteral(images || []),
                    },
                },
            });
        } else {
            callbacks.insertException({
                variables: {
                    exceptions: items.map((item) => ({
                        order_id: item.order_id,
                        item_id: item.item_id,
                        exception: {
                            data: { ...rest, images: toArrayLiteral(images || []) },
                        },
                    })),
                },
            });
        }
    }, []);

    const completeOrders = useCallback((orders) => {
        return callbacks.updateOrders({
            variables: {
                order_ids: orders.map((o) => o.order_id),
                update: {
                    completion_time: new Date().toISOString(),
                    order_status: 'complete',
                    completion_source: 'WMS_ADMIN',
                },
            },
            onError: callbacks.onError('Failed to complete orders'),
        });
    }, []);

    return {
        receiveItem,
        preconsolidateItems,
        receivePallet,
        storePallets,
        saveStagingZone,
        consolidatePallets,
        stagePallets,
        pickupPallets,
        reportException,
        completeOrders,
        renamePallet,
    };
};
