import { createContext, useMemo, useEffect, useState, useCallback } from 'react';
import debounce from 'lodash/debounce';

import { useMutation } from '@apollo/client';
import { addMonths } from 'date-fns';
import * as Sentry from '@sentry/react';
import useQuery from '@/utilities/useQuery';
import { createOrderJob } from '@/components/ShipmentForm/queries/createOrderJob';
import { useLazyQuery } from '@apollo/client';
import { captureException } from '@sentry/react';

import { useAdminExceptionHooks } from './hooks';
import { GET_EXCEPTIONS, RESOLVE_EXCEPTION, GET_CARRIERS, GET_SHIPPERS, GET_JOB_INFO } from './graphql';
import { exportCsv } from './queries/exportCsv';
import { COLUMNS } from './columns';
import { UPDATE_ORDER_BY_ID } from '@/graphql/mutations/orders';
import { INSERT_MANIFEST_AND_UPSERT_ITEMS } from '@/components/Manifests/graphql';

export const Context = createContext();

export const ContextProvider = ({ children }) => {
    const [toResolve, setToResolve] = useState({});
    const [selected, setSelected] = useState(null);
    const [hasMore, setHasMore] = useState(false);
    const [filter, setFilter] = useState({
        status: 'OPEN',
        shippers: [],
        carriers: [],
    });

    const [getExceptions, { data, loading: initInflight, fetchMore }] = useLazyQuery(GET_EXCEPTIONS, {
        onError: (err) => {
            console.error(err);
            captureException(err);
        },
    });

    const [getShippers, { data: shipperData }] = useLazyQuery(GET_SHIPPERS, {
        onError: (err) => {
            captureException(err);
        },
    });

    const [getCarriers, { data: carrierData }] = useLazyQuery(GET_CARRIERS, {
        onError: (err) => {
            captureException(err);
        },
    });

    const [getJobInfo] = useLazyQuery(GET_JOB_INFO, {
        onError: (err) => {
            captureException(err);
        },
    });

    const [updateOrder] = useMutation(UPDATE_ORDER_BY_ID, {
        onError: (err) => {
            captureException(err);
        },
    });

    const [insertManifestAndUpsertItems, { loading: createLoading }] = useMutation(INSERT_MANIFEST_AND_UPSERT_ITEMS, {
        onError: (error) => {
            console.log(error);
            Sentry.captureException(error);
        },
    });

    const exceptions = useMemo(() => {
        return data?.results || [];
    }, [data]);

    const shippers = useMemo(() => {
        return (
            (shipperData?.results || [])
                .filter((order) => !!order.shipper_id)
                .map((order) => {
                    return {
                        label: order?.order_shipper?.business_name,
                        value: order.shipper_id,
                    };
                })
                .sort((l, r) => l.label.localeCompare(r.label)) || []
        );
    }, [shipperData]);

    const carriers = useMemo(() => {
        return (
            (carrierData?.results || [])
                .filter((order) => !!order.carrier_id)
                .map((order) => {
                    return {
                        label: order?.order_carrier?.business_name,
                        value: order.carrier_id,
                    };
                })
                .sort((l, r) => l.label.localeCompare(r.label)) || []
        );
    }, [carrierData]);

    const where = useMemo(() => {
        return [
            ...(filter.start ? [{ created_at: { _gte: filter.start.toISOString() } }] : []),
            ...(filter.end ? [{ created_at: { _lt: filter.end.toISOString() } }] : []),
            ...(filter.occurred_at ? [{ exception: { reported_at: { _eq: filter.occurred_at } } }] : []),
            ...(filter.type ? [{ exception: { type: { _eq: filter.type } } }] : []),

            ...(filter?.shippers?.length > 0 ? [{ order: { shipper_id: { _in: filter?.shippers } } }] : []),
            ...(filter?.carriers?.length > 0 ? [{ order: { carrier_id: { _in: filter?.carriers } } }] : []),

            { order: { oms: { _eq: false } } },
        ];
    }, [filter]);

    const filtered = useMemo(() => {
        return Object.values(
            exceptions
                .filter((exception) => {
                    const resolved = exception.exception.status === 'RESOLVED';

                    return (!filter.type || exception.exception.type === filter.type) &&
                        (!filter.occurred_at || exception.exception.reported_at === filter.occurred_at) &&
                        filter.status === 'OPEN'
                        ? !resolved
                        : resolved &&
                              (filter.shippers.length === 0 || filter.shippers.includes(exception.order.shipper_id)) &&
                              (filter.carriers.length === 0 || filter.carriers.includes(exception.order.carrier_id));
                })
                .reduce((acc, exception) => {
                    acc[exception.exception.exception_id] = {
                        ...exception,
                        ...(exception.item
                            ? { item: [...((acc[exception.exception.exception_id] || {}).item || []), exception.item] }
                            : {}),
                    };

                    return acc;
                }, {})
        );
    }, [exceptions, filter]);

    const [retrieveCsv, { loading: csvInflight }] = useQuery(exportCsv, {
        onError: (err) => {
            Sentry.captureException(err);
        },
    });

    const { exportCSV, loadMore } = useAdminExceptionHooks(
        { filtered, selected, where, exceptions },
        { fetchMore, setHasMore, retrieveCsv }
    );

    const getExceptionsDebounced = useMemo(
        () =>
            debounce((payload) => {
                return getExceptions(payload);
            }, 500),
        []
    );

    useEffect(() => {
        getExceptions({
            variables: {
                where: {
                    _and: where,
                },
            },
        });

        getShippers({
            variables: {
                where: {
                    _and: [
                        ...(filter.start ? [{ created_at: { _gte: filter.start.toISOString() } }] : []),
                        ...(filter.end ? [{ created_at: { _lt: filter.end.toISOString() } }] : []),
                    ],
                },
            },
        });

        getCarriers({
            variables: {
                where: {
                    _and: [
                        ...(filter.start ? [{ created_at: { _gte: filter.start.toISOString() } }] : []),
                        ...(filter.end ? [{ created_at: { _lt: filter.end.toISOString() } }] : []),
                    ],
                },
            },
        });
    }, []);

    useEffect(() => {
        if (!initInflight) {
            getExceptionsDebounced({
                variables: {
                    where: {
                        _and: where,
                    },
                },
            });

            setHasMore(true);
        }
    }, [where, getExceptionsDebounced, initInflight]);

    const [submitOrders, { loading: submitInflight }] = useQuery(createOrderJob, {
        onError: (err) => {
            Sentry.captureException(err);
        },
    });

    const [resolve, { loading: resolveInflight }] = useMutation(RESOLVE_EXCEPTION, {
        update(cache, { data: { order, exception, items_removed } }) {
            cache.updateQuery(
                {
                    query: GET_EXCEPTIONS,
                    variables: {
                        where: {
                            _and: where,
                        },
                    },
                },
                (data) => {
                    const removed = Object.fromEntries(items_removed.returning.map((item) => [item.item_id, true]));
                    const [resolved] = exception.returning;
                    const [updated] = order.returning;

                    const clone = [...data.results].map((mapping) => {
                        return {
                            ...mapping,
                            ...(mapping.exception.exception_id === resolved.exception_id
                                ? {
                                      order: updated ? updated : mapping.order,
                                      exception: {
                                          ...mapping.exception,
                                          status: resolved.status,
                                      },
                                  }
                                : {}),
                        };
                    });

                    return { results: clone.filter((item) => !removed[item.item_id]) };
                }
            );
        },
        onError: (err) => {
            console.log(err);
            Sentry.captureException(err);
        },
    });

    const saveAssociatedReturnOrder = async (order_id, job_id, isWhReturn) => {
        await getJobInfo({
            variables: {
                job_id: job_id,
            },
            onCompleted: (jobData) => {
                const newOrder = jobData.jobs_by_pk.orders[0];
                updateOrder({
                    variables: {
                        order_id: order_id,
                        update: {
                            return_order_id: newOrder.order_id,
                        },
                    },
                });

                if (isWhReturn) {
                    insertManifestAndUpsertItems({
                        variables: {
                            manifest: {
                                client_id: newOrder.carrier_id,
                                type: 'INBOUND',
                                source: 'EXCEPTION',
                                items: {
                                    data: newOrder.itemsByOrderId.map((item) => ({ item_id: item.item_id })),
                                },
                                share_manifest: true,
                            },
                            items: [],
                        },
                    });
                }
            },
        });
    };

    const callbacks = {
        resolveException: (row) => {
            const mapping = row.original;
            setToResolve({ exception: mapping.exception, items: mapping.item, order: mapping.order });
        },
        closeModal: () => {
            setToResolve({});
        },
        selectExceptions: setSelected,
        exportCSV,
        setFilter,
        loadMore,
        submitOrders,
        resolve,
        saveAssociatedReturnOrder,
    };

    return (
        <Context.Provider
            value={{
                state: {
                    toResolve,
                    filter,
                    hasMore,
                    exceptions: filtered,
                    selected,
                    columns: COLUMNS,
                    carriers,
                    shippers,
                },
                loading: {
                    init: initInflight,
                    resolve: resolveInflight || submitInflight,
                    export: csvInflight,
                },
                callbacks,
            }}
        >
            {children}
        </Context.Provider>
    );
};
